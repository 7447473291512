import { Link } from "react-router-dom";

import "./CitySelection.scss";
import "./Adaptations.scss";

import HalfScreen from "../../../layouts/half-screen/HalfScreen";
import { WhiteLogo } from "../../logo/Logo";

import SeparatorImage from "../../../static/images/start_separator.svg";

function CitySelectionButton({ text, link, backgroundImage }) {
    return (
        <Link
            to={link}
            style={{ backgroundImage: `url(${backgroundImage})` }}
            className="start-city-selection-button"
        >
            <div className="start-city-selection-button-name">{text}</div>
        </Link>
    );
}

export default function ({
    leftName,
    leftLink,
    leftImage,
    rightName,
    rightLink,
    rightImage,
}) {
    return (
        <div className="start-city-selection">
            <WhiteLogo />
            <div className="start-city-selection-buttons">
                <div className="start-city-selection-separator">
                    <img src={SeparatorImage} alt="separator" />
                </div>
                <HalfScreen
                    left={
                        <CitySelectionButton
                            text={leftName}
                            link={leftLink}
                            backgroundImage={leftImage}
                        />
                    }
                    right={
                        <CitySelectionButton
                            text={rightName}
                            link={rightLink}
                            backgroundImage={rightImage}
                        />
                    }
                />
            </div>
        </div>
    );
}
