import React from "react";
import { Link } from "react-router-dom";

import styled from "styled-components";

import "./SmallCard.scss";

import ExitCross from "../../../static/images/card-exit-cross-black-2.svg";
import ButtonArrow from "../../../static/images/card-arrow.svg";
import DeyaarCircle from "../../../static/images/circle-of-possibilities-4.svg";
import DefaultBgImage from "../../../static/images/default-bg-card.png";
import img from "../../../static/images/default-bg-card.png";

import { WindowCardGallery } from "../window-card/WindowCard";

function SmallCardText({ text, className }) {
    return (
        <div className={className}>
            <span className={className + "-text"}>{text}</span>
        </div>
    );
}

function SmallCardImg({ src, className }) {
    return (
        <div className={className}>
            <img src={src} alt={className + "-image"} />
        </div>
    );
}

function SmallCardButton({ text, link }) {
    return (
        <Link
            to={link}
            target={link && "_blank"}
            className="small-card-elements-button"
        >
            <div className="small-card-elements-button-inner">
                <SmallCardText
                    className={"small-card-elements-button-label"}
                    text={text}
                />
                <div className="small-card-elements-button-arrow">
                    <img src={ButtonArrow} alt="small-card-arrow-image" />
                </div>
            </div>
        </Link>
    );
}

const SmallCardDiv = styled.div`
    opacity: ${({ status }) => (status ? "1" : "0")};
    pointer-events: ${({ status }) => (status ? "all" : "none")};

    height: ${({ isThereIsLink }) => (isThereIsLink ? "168px" : "136px")};
`;

const SmallCardPreviewDiv = styled.div`
    background-image: url(${({ img }) => img});
`;

export default function SmallCard({
    activeSmallCard,
    smallCardContent,
    setActiveSmallCard,
}) {
    const isMobile =
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
            navigator.userAgent
        );

    return (
        <div className="small-card">
            <SmallCardDiv
                className="small-card-container"
                status={activeSmallCard}
                isThereIsLink={smallCardContent.link}
                style={{
                    left: smallCardContent.x,
                    top: smallCardContent.y,
                    transform: !isMobile && "translate(-300px, -50%)",
                }}
            >
                <img
                    className="small-card-img"
                    src={smallCardContent.preview}
                    alt="saas-tower-img"
                />
                <div
                    className="small-card-exit"
                    onClick={() => setActiveSmallCard(false)}
                >
                    <img src={ExitCross} alt="small-card-exit" />
                </div>
                <div className="small-card-elements">
                    <SmallCardText
                        className="small-card-elements-title"
                        text={smallCardContent.title}
                    />
                    {smallCardContent.desc && (
                        <SmallCardText
                            className="small-card-elements-desc"
                            text={smallCardContent.desc}
                        />
                    )}
                    {smallCardContent.link && (
                        <SmallCardButton
                            text="DISCOVER"
                            link={smallCardContent.link}
                        />
                    )}
                </div>
            </SmallCardDiv>
        </div>
    );
}
