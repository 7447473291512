import React, { createContext } from "react";
import {
    BrowserRouter as Router,
    Routes,
    Route,
    useLocation,
} from "react-router-dom";
import { AnimatePresence } from "framer-motion";

import Start from "./pages/start/Start";
import Dubai from "./pages/dubai/Dubai";
import AbuDhabi from "./pages/abu-dhabi/AbuDhabi";

import PageTransition from "./animations/PageTransition";

import "./App.scss";

export const MainContext = createContext(null);

// Wrapper to handle route transitions
// That would be great to find better solution in future
function AnimatedRoutes() {
    const location = useLocation();

    return (
        <AnimatePresence mode="wait">
            <Routes location={location} key={location.pathname}>
                <Route
                    path="/"
                    element={
                        <PageTransition>
                            <Start />
                        </PageTransition>
                    }
                />
                <Route
                    path="/dubai"
                    element={
                        <PageTransition>
                            <Dubai />
                        </PageTransition>
                    }
                />
                <Route
                    path="/abu-dhabi"
                    element={
                        <PageTransition>
                            <AbuDhabi />
                        </PageTransition>
                    }
                />
            </Routes>
        </AnimatePresence>
    );
}

function App() {
    return (
        <div className="App">
            <Router>
                <AnimatedRoutes />
            </Router>
        </div>
    );
}

export default App;
