import "./Start.scss";

import CitySelection from "../../components/start/city-selection/CitySelection";

import AbuDhabiMapPreview from "../../static/images/start_abu_dhabi_map.png";
import DubaiMapPreview from "../../static/images/start_dubai_map.png";

export default function Start() {
    return (
        <div className="start">
            <CitySelection
                leftName="Abu Dhabi"
                leftLink="/abu-dhabi"
                leftImage={AbuDhabiMapPreview}
                rightName="Dubai"
                rightLink="/dubai"
                rightImage={DubaiMapPreview}
            />
        </div>
    );
}
