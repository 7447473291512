import "./Logo.scss";

import WhiteLogoImage from "../../static/images/saas_logo_white.png";
import BlackLogoImage from "../../static/images/saas_logo_black.png";

export function WhiteLogo({ style }) {
    return (
        <div className="logo" style={style}>
            <img src={WhiteLogoImage} alt="white-logo" />
        </div>
    );
}

export function BlackLogo({ style }) {
    return (
        <div className="logo" style={style}>
            <img src={BlackLogoImage} alt="black-logo" />
        </div>
    );
}
