import React, { useEffect, useState } from "react";

import styled from "styled-components";
import Switch from "react-switch";

import "./Header.scss";
import "./Adaptations.scss"

import MainLogoImg from "../../../static/images/main-logo.svg";
import DropdownArrow from "../../../static/images/dropdown-arrow.svg";
import MasterCommunities from "../../../static/images/master-communities.svg";

import TipImage from "../../../static/images/header/tip.svg";
import TipCrossImage from "../../../static/images/header/tip-cross.svg";

import {
    WindowCardOutsideButton,
    WindowCardVideo,
} from "../window-card/WindowCard";

import FingerImage from "../../../static/images/guide/finger.svg";
import ZoomImage from "../../../static/images/guide/zoom.svg";
import ZoomInOutImage from "../../../static/images/guide/zoom_in_out.svg";
import MoveImage from "../../../static/images/guide/move.svg";
import RotateImage from "../../../static/images/guide/rotate.svg";

import TutorialDesktopVideo from "../../../static/videos/tutorial_desktop.mp4";

import LandmarksImg from "../../../static/images/header/header-filter-landmarks.svg";
import RetailsImg from "../../../static/images/header/header-filter-retails.svg";
import EducationImg from "../../../static/images/header/header-filter-education.svg";
import HealthImg from "../../../static/images/header/header-filter-health.svg";
import RoadsImg from "../../../static/images/header/header-filter-roads.svg";
import DistancesImg from "../../../static/images/header/header-filter-distances.svg";
import MetroImg from "../../../static/images/header/header-filter-metro.svg";
import ButtonArrow from "../../../static/images/header/Arrow 1.svg";
import HideImg from "../../../static/images/hide_button.svg";
import ShowImg from "../../../static/images/show_button.svg";
import HideImg2 from "../../../static/images/hide_button2.svg";
import ShowImg2 from "../../../static/images/show_button2.svg";
import { Link } from "react-router-dom";
import HeaderShadowImg from "../../../static/images/header_shadow.svg";
import HeaderShadowPortImg from "../../../static/images/header_shadow_portrait.svg";
import HeaderShadowLandImg from "../../../static/images/header_shadow_landscape.svg";

// Header mapping component
const HeaderFilterComponent = ({
    data,
    setDirectionSwitchChecked,
    setRadiusSwitchChecked,
}) => {
    const imagesMap = {
        landmarks: LandmarksImg,
        retail_or_shopping: RetailsImg,
        education: EducationImg,
        health: HealthImg,
        transport: MetroImg,
        roads: RoadsImg,
    };

    const handleCheckboxChange = (element, stateObject, editState) => {
        if (stateObject) {
            stateObject.setState(editState(element.id));
        }
    };

    const renderFilterButtons = (dropdown) => {
        return dropdown.elements.map((element) => {
            const activeStatus = dropdown.stateObject
                ? dropdown.stateObject.state.includes(element.id)
                : element.stateObject.state;

            const editState = (elementId) => {
                let stateTemp = [...dropdown.stateObject.state];
                if (dropdown.stateObject.state.includes(elementId)) {
                    stateTemp = stateTemp.filter((id) => id !== elementId);
                } else {
                    stateTemp.push(elementId);
                }
                return stateTemp;
            };

            return (
                <div
                    key={element.id}
                    className="header-filter-button"
                    style={{
                        backgroundColor: activeStatus
                            ? "#3c373a"
                            : "transparent",
                        borderRadius: "8px",
                    }}
                >
                    <img
                        src={imagesMap[element.id]}
                        alt={element.text}
                        onClick={() =>
                            handleCheckboxChange(
                                element,
                                dropdown.stateObject,
                                editState
                            )
                        }
                        style={{
                            cursor: "pointer",
                        }}
                    />
                    <input
                        type="checkbox"
                        checked={activeStatus}
                        onChange={() =>
                            handleCheckboxChange(
                                element,
                                dropdown.stateObject,
                                editState
                            )
                        }
                        style={{ display: "none" }}
                    />
                </div>
            );
        });
    };

    return (
        <div className="header-filter-elements">
            {data
                .filter((dropdown) => dropdown.title === "Infrastructure")
                .map((dropdown) => (
                    <div key={dropdown.id} className="header-filter-section">
                        {dropdown.type === "radio" ? (
                            <HeaderDropdownListRadio
                                elements={dropdown.elements}
                                stateObject={dropdown.stateObject}
                            />
                        ) : (
                            renderFilterButtons(dropdown)
                        )}
                    </div>
                ))}
        </div>
    );
};

// Dropdown Landmarks Header Select
// const HeaderFilter = ({
//     imgS,
//     imgH,
//     data,
//     setDirectionSwitchChecked,
//     setRadiusSwitchChecked,
// }) => {
//     const [isCollapsed, setIsCollapsed] = useState(false);

//     const toggleMenu = () => {
//         setIsCollapsed((prev) => !prev);
//     };

//     return (
//         <div
//             className={`header-filter-container ${
//                 isCollapsed ? "collapsed" : "expanded"
//             }`}
//         >
//             <div className="header-filter-elements">
//                 {!isCollapsed && (
//                     <>
//                         <HeaderFilterComponent
//                             data={data}
//                             setDirectionSwitchChecked={
//                                 setDirectionSwitchChecked
//                             }
//                             setRadiusSwitchChecked={setRadiusSwitchChecked}
//                         />
//                     </>
//                 )}
//             </div>
//             <div
//                 className="header-filter-button close-button"
//                 onClick={toggleMenu}
//             >
//                 <img
//                     src={isCollapsed ? imgS : imgH}
//                     alt={isCollapsed ? "show button" : "hide button"}
//                 />
//             </div>
//         </div>
//     );
// };

// Header Elements

function HeaderLogo() {
    return (
        <div onClick={() => window.location.reload()} className="header-logo">
            <div className="header-logo-top">
                <img src={MainLogoImg} alt="nakheel-logo" />
            </div>
        </div>
    );
}

// Dropdown Elements

const HeaderDropdownButtonDiv = styled.div`
    // border: 1px solid ${({ isOpened }) =>
        isOpened ? "#e6e2e2e5" : "#e6e2e2a8"};
`;

const HeaderDropdownArrowImg = styled.img`
    transform: ${({ isOpened }) => (isOpened ? "rotate(180deg)" : "rotate(0)")};
`;

function HeaderDropdownButton({
    onClick,
    title,
    currentActive,
    type,
    isOpened,
}) {
    const removeExtraChars = () => {
        let extraCharsAmount = title.length + currentActive.length + 2 - 27;
        if (extraCharsAmount > 0) {
            return currentActive.slice(0, -extraCharsAmount - 2) + "..";
        } else {
            return currentActive;
        }
    };

    return (
        <HeaderDropdownButtonDiv
            className="header-dropdown-button"
            onClick={onClick}
            isOpened={isOpened}
        >
            <span className="header-dropdown-button-text">
                {type === "radio" ? (
                    <>
                        {title}: <b>{removeExtraChars()}</b>
                    </>
                ) : (
                    title
                )}
            </span>
            <HeaderDropdownArrowImg
                isOpened={isOpened}
                src={DropdownArrow}
                alt="dropdown-arrow"
            />
        </HeaderDropdownButtonDiv>
    );
}

function HeaderDropdownListTitle({ title }) {
    return (
        <div className="header-dropdown-list-title">
            <span className="header-dropdown-title-text">{title}</span>
        </div>
    );
}

// Dropdown Radio-List

const RadioInner = styled.div`
    opacity: ${({ activeStatus }) => (activeStatus ? "1" : "0")};
`;

function HeaderRadioButton({ activeStatus }) {
    return (
        <div className="header-dropdown-list-radio-button">
            <RadioInner
                activeStatus={activeStatus}
                className="header-dropdown-list-radio-button-inner"
            />
        </div>
    );
}

const HeaderElement = styled.div`
    // background-color: ${({ activeStatus }) =>
        activeStatus ? "rgb(245, 245, 245)" : "#FFFFFF"};
    // background-color: ${({ activeStatus }) =>
        activeStatus ? "rgba(226, 226, 226, 0.25)" : "rgba(0, 0, 0, 0)"};
`;

function HeaderDropdownListRadio({ elements, stateObject }) {
    return (
        <>
            {elements.map((element) => {
                let activeStatus =
                    element.id === stateObject ? stateObject.state.id : null;
                return (
                    <HeaderElement
                        onClick={() => stateObject.setState(element)}
                        className="header-dropdown-list-element"
                        activeStatus={activeStatus}
                    >
                        <div className="header-dropdown-list-element-inner">
                            <HeaderRadioButton activeStatus={activeStatus} />
                            <span className="header-dropdown-list-element-text">
                                {element.text}
                            </span>
                        </div>
                    </HeaderElement>
                );
            })}
        </>
    );
}

// Dropdown Checkbox-List

const ChechboxInner = styled.div`
    opacity: ${({ activeStatus }) => (activeStatus ? "1" : "0")};
`;

function HeaderCheckboxButton({ activeStatus }) {
    return (
        <div className="header-dropdown-list-checkbox-button">
            <ChechboxInner
                activeStatus={activeStatus}
                className="header-dropdown-list-checkbox-button-inner"
            />
        </div>
    );
}

function HeaderDropdownListCheckbox({
    elements,
    stateObject,
    setDirectionSwitchChecked,
    setRadiusSwitchChecked,
}) {
    // const editState = (elementId) => {
    //     let stateTemp = [...stateObject.state]

    //     if (stateObject.state.includes(elementId)) {
    //         stateTemp.splice(stateTemp.indexOf(elementId), 1)
    //     } else {
    //         stateTemp.push(elementId)
    //     }
    //     return stateTemp
    // }

    const editState = (elementId) => {
        let stateTemp = [...stateObject.state];

        if (stateObject.state.includes(elementId)) {
            stateTemp = stateTemp.filter((id) => id !== elementId);
        } else {
            stateTemp.push(elementId);
        }

        return stateTemp;
    };

    // const editSeparatedState = (stateObject) => {
    //     const newState = !stateObject.state
    //     stateObject.setState(newState)

    //     // !!! CODE NEEDS TO BE REPLACED !!!
    //     if (newState === true) {
    //         if (stateObject.indicator === 'directions') {
    //             setRadiusSwitchChecked(false)
    //         } else if (stateObject.indicator === 'radius') {
    //             setDirectionSwitchChecked(false)
    //         }
    //     }
    // }

    const editSeparatedState = (stateObject) => {
        const newState = !stateObject.state;
        stateObject.setState(newState);

        if (newState === true) {
            if (stateObject.indicator === "directions") {
                setRadiusSwitchChecked(false);
            } else if (stateObject.indicator === "radius") {
                setDirectionSwitchChecked(false);
            }
        }
    };

    return (
        // <>{
        //     elements.map((element) => {
        //         let activeStatus = stateObject ? stateObject.state.includes(element.id) : element.stateObject.state

        //         const handleCheckboxChange = () => {
        //             if (stateObject) {
        //                 stateObject.setState(editState(element.id));
        //             } else {
        //                 editSeparatedState(element.stateObject);
        //             }
        //         };

        //         return (
        //             <HeaderElement
        //                 // onClick={() => (stateObject ? stateObject.setState(editState(element.id)) : editSeparatedState(element.stateObject))}
        //                 onClick={handleCheckboxChange}
        //                 className="header-dropdown-list-element"
        //                 activeStatus={activeStatus}
        //             >
        //                 <div className="header-dropdown-list-element-inner">
        //                     <label className="header-dropdown-list-element-label">
        //                         <input
        //                          className="header-dropdown-list-element-input-checkbox"
        //                          type="checkbox"
        //                          checked={activeStatus}
        //                          onChange={handleCheckboxChange}
        //                         />
        //                         <span className="custom-checkbox"></span>
        //                         <span className="header-dropdown-list-element-text">{element.text}</span>
        //                         {/* <Switch
        //                             className="header-switch-element"

        //                             onChange={() => null}
        //                             checked={activeStatus}
        //                             uncheckedIcon={false}
        //                             checkedIcon={false}

        //                             onColor="#E8E8E9"
        //                             offColor="#E8E8E9"

        //                             onHandleColor="#00B087"
        //                             offHandleColor="#505050"

        //                             handleDiameter={12}
        //                             height={20}
        //                             width={36}
        //                         /> */}
        //                     </label>
        //                 </div>
        //             </HeaderElement>
        //         )
        //     })
        // }</>
        <>
            {elements.map((element) => {
                let activeStatus = stateObject
                    ? stateObject.state.includes(element.id)
                    : element.stateObject.state;

                const handleCheckboxChange = () => {
                    if (stateObject) {
                        stateObject.setState(editState(element.id));
                    } else {
                        editSeparatedState(element.stateObject);
                    }
                };

                return (
                    <HeaderElement
                        key={element.id}
                        className="header-dropdown-list-element"
                        activeStatus={activeStatus}
                    >
                        <div className="header-dropdown-list-element-inner">
                            <label className="header-dropdown-list-element-label">
                                <input
                                    className="header-dropdown-list-element-input-checkbox"
                                    type="checkbox"
                                    checked={activeStatus}
                                    onChange={handleCheckboxChange}
                                />
                                <span className="custom-checkbox"></span>
                                <span className="header-dropdown-list-element-text">
                                    {element.text}
                                </span>
                            </label>
                        </div>
                    </HeaderElement>
                );
            })}
        </>
    );
}

// Dropdown Element

const HeaderDropdownDiv = styled.div`
    @media screen and (max-width: 1300px) {
        margin-bottom: ${({ isOpened }) => (isOpened ? "10px" : "10px")};
        height: ${({ isOpened, elementsLen }) =>
            isOpened ? elementsLen * 43 + 15 + 43 + "px" : "43px"};
    }
`;

const HeaderDropdownListDiv = styled.div`
    margin-top: ${({ isOpened }) => (isOpened ? "15px" : "10px")};
    transform: ${({ isOpened, elementsLen }) =>
        isOpened ? "scaleY(1)" : "scaleY(0)"};
    height: ${({ isOpened, elementsLen }) => elementsLen * 54 + "px"};
    // opacity: ${({ isOpened }) => (isOpened ? "1" : "0")};

    .header-dropdown-list-element {
        transform: ${({ isOpened, elementsLen }) =>
            isOpened ? "scaleY(1)" : "scaleY(0)"};
    }
`;

function HeaderDropdown({
    type,
    title,
    id,
    elements,
    stateObject,
    currentActiveDropdown,
    setCurrentActiveDropdown,
    setActiveCard,
}) {
    const [isOpened, setIsOpened] = useState(false);
    let elementsLen = elements.length;

    useEffect(() => {
        if (currentActiveDropdown === id) {
            setIsOpened(true);
        } else {
            setIsOpened(false);
        }
    }, [currentActiveDropdown]);

    return (
        <HeaderDropdownDiv
            className="header-dropdown"
            isOpened={isOpened}
            elementsLen={elementsLen}
        >
            <HeaderDropdownButton
                onClick={() => {
                    if (currentActiveDropdown !== id) {
                        setCurrentActiveDropdown(id);
                    } else {
                        setCurrentActiveDropdown("");
                    }
                    setActiveCard(null);
                }}
                title={title}
                currentActive={stateObject ? stateObject.state.text : null}
                type={type}
                isOpened={isOpened}
            />
            <HeaderDropdownListDiv
                elementsLen={elementsLen}
                isOpened={isOpened}
                className="header-dropdown-list"
            >
                {/* <HeaderDropdownListTitle title={title} /> */}

                <div className="header-dropdown-list-elements">
                    {type === "radio" ? (
                        <HeaderDropdownListRadio
                            elements={elements}
                            stateObject={stateObject}
                        />
                    ) : (
                        <HeaderDropdownListCheckbox
                            elements={elements}
                            stateObject={stateObject}
                        />
                    )}
                </div>
            </HeaderDropdownListDiv>
        </HeaderDropdownDiv>
    );
}

// Switch Element
function HeaderSwitch({
    switchChecked,
    setSwitchChecked,
    setActiveCard,
    labelText,
}) {
    const switchChange = () => {
        setSwitchChecked(!switchChecked);
        setActiveCard(null);
    };

    return (
        <div className="header-switch">
            <div className="header-switch-inner" onClick={switchChange}>
                <Switch
                    className="header-switch-element"
                    onChange={() => null}
                    checked={switchChecked}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    onColor="#4DAC89"
                    offColor="#A4A5A9"
                    handleDiameter={15}
                    height={17}
                    width={31}
                />
                <div className="header-switch-label">
                    <span className="header-switch-text">{labelText}</span>
                </div>
            </div>
        </div>
    );
}

// Button Element

const HeaderButtonDiv = styled.div`
    background-color: ${({ isActive }) =>
        isActive ? "#F88350" : "rgba(197, 197, 197, 0.153)"};
    // font-weight: ${({ isActive }) => (isActive ? "500" : "400")};
    // font-size: ${({ isActive }) => (isActive ? "12.25px" : "12px")};

    &:hover {
        background-color: ${({ isActive }) =>
            isActive ? "#DF7949" : "rgba(226, 226, 226, 0.153)"};
    }
`;

const HeaderButtonImage = styled.img`
    transform: ${({ isActive }) => (isActive ? "rotate(-0.5turn)" : "0")};
`;

function HeaderButton({ text, buttonChecked, setButtonCheched }) {
    return (
        <HeaderButtonDiv
            className="header-button"
            isActive={buttonChecked}
            onClick={() => setButtonCheched(!buttonChecked)}
        >
            <div className="header-button-inner">
                <span className="header-button-text">{text}</span>
                <HeaderButtonImage
                    src={MasterCommunities}
                    alt="master-communities-logo"
                    isActive={buttonChecked}
                />
            </div>
        </HeaderButtonDiv>
    );
}

// Guide

function SmallGuideElement({ image, text }) {
    return (
        <div className="header-guide-element">
            <div className="header-guide-element-img">
                <img src={image} alt="header-guide-element-icon" />
            </div>
            <div className="header-guide-element-label">
                <span className="header-guide-element-label-text">{text}</span>
            </div>
        </div>
    );
}

const SmallGuideDiv = styled.div`
    margin-top: ${({ activeSmallGuide }) =>
        activeSmallGuide ? "53px" : "10px"};
    transform: ${({ activeSmallGuide }) =>
        activeSmallGuide ? "scaleY(1)" : "scaleY(0)"};
    // opacity: ${({ activeSmallGuide }) => (activeSmallGuide ? "1" : "0")};
`;

function SmallGuide({
    activeSmallGuide,
    isMobile,
    setActiveWindow,
    setWindowContent,
}) {
    return (
        <SmallGuideDiv
            activeSmallGuide={activeSmallGuide}
            className="header-guide"
        >
            {isMobile ? (
                <>
                    <SmallGuideElement
                        image={FingerImage}
                        text="Use two fingers to move the map"
                    />
                    <SmallGuideElement
                        image={ZoomImage}
                        text="Pinch to zoom in and zoom out"
                    />
                </>
            ) : (
                <>
                    <SmallGuideElement
                        image={ZoomInOutImage}
                        text={["Scroll or pinch to ", <b>zoom in and out</b>]}
                    />
                    <SmallGuideElement
                        image={MoveImage}
                        text={[
                            "Hold down ",
                            <b>to move</b>,
                            " the map left and right",
                        ]}
                    />
                    <SmallGuideElement
                        image={RotateImage}
                        text={[
                            "Hold down Shift + touchpad/left mouse button ",
                            <b>to rotate</b>,
                        ]}
                    />
                </>
            )}
            <div
                className="header-guide-button"
                onClick={() => {
                    setActiveWindow(true);
                    setWindowContent(
                        <>
                            <WindowCardVideo video={TutorialDesktopVideo} />
                            {!isMobile && (
                                <WindowCardOutsideButton
                                    text={"SKIP"}
                                    onClick={() => setActiveWindow(false)}
                                />
                            )}
                        </>
                    );
                }}
            >
                <span className="header-guide-button-text">VIDEO TUTORIAL</span>
            </div>
        </SmallGuideDiv>
    );
}

const HeaderDiv = styled.div`
    opacity: ${({ isHeaderActive }) => (isHeaderActive ? "100%" : "0%")};

    * {
        pointer-events: ${({ isHeaderActive }) =>
            isHeaderActive ? "all" : "none"} !important;
    }
`;

export default function Header({
    data,

    currentActiveDropdown,
    setCurrentActiveDropdown,

    directionSwitchChecked,
    setDirectionSwitchChecked,
    radiusSwitchChecked,
    setRadiusSwitchChecked,
    newProjectsSwitchChecked,
    setNewProjectsSwitchChecked,
    masterCommunitiesChecked,
    setMasterCommunitiesChecked,

    setActiveCard,

    activeWindow,
    setActiveWindow,
    setWindowContent,

    activeGuide,
    setActiveSmallGuide,
    activeSmallGuide,

    isHeaderActive,
    setIsHeaderActive,

    isMobile,
}) {
    useEffect(() => {
        activeGuide || activeWindow
            ? setIsHeaderActive(false)
            : setIsHeaderActive(true);
    }, [activeGuide, activeWindow]);

    return (
        <HeaderDiv className="header" isHeaderActive={isHeaderActive}>
            <div className="header-logo-button">
                <HeaderLogo />
                <Link to="/" className="arrow-back-button">
                    <img src={ButtonArrow} alt="arrow img" />
                    <p>BACK</p>
                </Link>
                {/* <HeaderFilter imgS={ShowImg} imgH={HideImg} data={data} /> */}
                <img
                    className="header-container-img"
                    src={HeaderShadowPortImg}
                    alt="shadow"
                />
            </div>
            {/* <HeaderFilter imgS={ShowImg} imgH={HideImg} data={data} /> */}
            <HeaderFilterComponent
                data={data}
                setDirectionSwitchChecked={setDirectionSwitchChecked}
                setRadiusSwitchChecked={setRadiusSwitchChecked}
            />
            <div className="header-container">
                <div className="header-panel">
                    <HeaderFilterComponent
                        data={data}
                        setDirectionSwitchChecked={setDirectionSwitchChecked}
                        setRadiusSwitchChecked={setRadiusSwitchChecked}
                    />
                    <img
                        className="header-container-img"
                        src={HeaderShadowImg}
                        alt="shadow"
                    />
                    <div className="header-select">
                        <div className="header-project-type-select">
                            {data
                                .filter(
                                    (dropdown) =>
                                        dropdown.title === "PROJECT TYPE"
                                )
                                .map((dropdown) => (
                                    <HeaderDropdownButton
                                        onClick={() => {
                                            if (
                                                currentActiveDropdown !==
                                                dropdown.id
                                            ) {
                                                setCurrentActiveDropdown(
                                                    dropdown.id
                                                );
                                                setActiveSmallGuide(false);
                                            } else {
                                                setCurrentActiveDropdown(null);
                                            }
                                            setActiveCard(null);
                                        }}
                                        title={dropdown.title}
                                        currentActive={
                                            dropdown.stateObject?.state?.text
                                        }
                                        type={dropdown.type}
                                        isOpened={
                                            currentActiveDropdown ===
                                            dropdown.id
                                        }
                                    />
                                ))}
                        </div>
                        <div className="header-panel-bottom">
                            {data.map((dropdown) => (
                                <HeaderDropdownListDiv
                                    elementsLen={dropdown.elementsLen}
                                    isOpened={
                                        currentActiveDropdown === dropdown.id
                                    }
                                    className="header-dropdown-list"
                                >
                                    {/* <HeaderDropdownListTitle title={title} /> */}

                                    <div className="header-dropdown-list-elements">
                                        {dropdown.type === "radio" ? (
                                            <HeaderDropdownListRadio
                                                elements={dropdown.elements}
                                                stateObject={
                                                    dropdown.stateObject
                                                }
                                            />
                                        ) : (
                                            <HeaderDropdownListCheckbox
                                                elements={dropdown.elements}
                                                stateObject={
                                                    dropdown.stateObject
                                                }
                                                // Temporary
                                                setDirectionSwitchChecked={
                                                    setDirectionSwitchChecked
                                                }
                                                setRadiusSwitchChecked={
                                                    setRadiusSwitchChecked
                                                }
                                            />
                                        )}
                                    </div>
                                </HeaderDropdownListDiv>
                            ))}
                        </div>
                    </div>
                    <div className="show-options">
                        {data
                            .filter((dropdown) => dropdown.title === "Show")
                            .flatMap((dropdown) => dropdown.elements)
                            .map((element, index, elementsArray) => (
                                <div key={element.id} className="show-option">
                                    <label className="show-option-label">
                                        <Switch
                                            className="header-switch-element"
                                            onChange={() => {
                                                elementsArray.forEach((el) => {
                                                    if (el.id !== element.id) {
                                                        el.stateObject.setState(
                                                            false
                                                        );
                                                    }
                                                });
                                                element.stateObject.setState(
                                                    !element.stateObject.state
                                                );
                                            }}
                                            checked={element.stateObject.state}
                                            uncheckedIcon={false}
                                            checkedIcon={false}
                                            onColor="#000"
                                            offColor="#575155"
                                            onHandleColor="#fff"
                                            offHandleColor="#FFF"
                                            handleDiameter={14}
                                            height={16}
                                            width={30}
                                        />
                                        <span>{element.text}</span>
                                    </label>
                                </div>
                            ))}
                    </div>
                </div>
                <div className="header-panel-mobile">
                    <div className="header-select">
                        <div className="header-project-type-select">
                            {data
                                .filter(
                                    (dropdown) =>
                                        dropdown.title === "PROJECT TYPE"
                                )
                                .map((dropdown) => (
                                    <HeaderDropdownButton
                                        onClick={() => {
                                            if (
                                                currentActiveDropdown !==
                                                dropdown.id
                                            ) {
                                                setCurrentActiveDropdown(
                                                    dropdown.id
                                                );
                                                setActiveSmallGuide(false);
                                            } else {
                                                setCurrentActiveDropdown(null);
                                            }
                                            setActiveCard(null);
                                        }}
                                        title={dropdown.title}
                                        currentActive={
                                            dropdown.stateObject?.state?.text
                                        }
                                        type={dropdown.type}
                                        isOpened={
                                            currentActiveDropdown ===
                                            dropdown.id
                                        }
                                    />
                                ))}
                        </div>
                        <div className="header-panel-bottom">
                            {data.map((dropdown) => (
                                <HeaderDropdownListDiv
                                    elementsLen={dropdown.elementsLen}
                                    isOpened={
                                        currentActiveDropdown === dropdown.id
                                    }
                                    className="header-dropdown-list"
                                >
                                    <div className="header-dropdown-list-elements">
                                        {dropdown.type === "radio" ? (
                                            <HeaderDropdownListRadio
                                                elements={dropdown.elements}
                                                stateObject={
                                                    dropdown.stateObject
                                                }
                                            />
                                        ) : (
                                            <HeaderDropdownListCheckbox
                                                elements={dropdown.elements}
                                                stateObject={
                                                    dropdown.stateObject
                                                }
                                                // Temporary
                                                setDirectionSwitchChecked={
                                                    setDirectionSwitchChecked
                                                }
                                                setRadiusSwitchChecked={
                                                    setRadiusSwitchChecked
                                                }
                                            />
                                        )}
                                    </div>
                                </HeaderDropdownListDiv>
                            ))}
                        </div>
                    </div>
                    <div className="show-options">
                        {data
                            .filter((dropdown) => dropdown.title === "Show")
                            .flatMap((dropdown) => dropdown.elements)
                            .map((element, index, elementsArray) => (
                                <div key={element.id} className="show-option">
                                    <label className="show-option-label">
                                        <Switch
                                            className="header-switch-element"
                                            onChange={() => {
                                                elementsArray.forEach((el) => {
                                                    if (el.id !== element.id) {
                                                        el.stateObject.setState(
                                                            false
                                                        );
                                                    }
                                                });
                                                element.stateObject.setState(
                                                    !element.stateObject.state
                                                );
                                            }}
                                            checked={element.stateObject.state}
                                            uncheckedIcon={false}
                                            checkedIcon={false}
                                            onColor="#3C373A"
                                            offColor="#3C373A"
                                            onHandleColor="#fff"
                                            offHandleColor="#FFF"
                                            handleDiameter={14}
                                            height={16}
                                            width={30}
                                        />
                                        <span>{element.text}</span>
                                    </label>
                                </div>
                            ))}
                    </div>
                </div>
                <div className="header-panel-mobile-rotated">
                    {/* <HeaderFilter imgS={ShowImg2} imgH={HideImg2} data={data} /> */}
                    <HeaderFilterComponent
                        data={data}
                        setDirectionSwitchChecked={setDirectionSwitchChecked}
                        setRadiusSwitchChecked={setRadiusSwitchChecked}
                    />
                    <img
                        className="header-container-img"
                        src={HeaderShadowLandImg}
                        alt="shadow"
                    />
                    <div className="header-select">
                        <div className="header-project-type-select">
                            {data
                                .filter(
                                    (dropdown) =>
                                        dropdown.title === "PROJECT TYPE"
                                )
                                .map((dropdown) => (
                                    <HeaderDropdownButton
                                        onClick={() => {
                                            if (
                                                currentActiveDropdown !==
                                                dropdown.id
                                            ) {
                                                setCurrentActiveDropdown(
                                                    dropdown.id
                                                );
                                                setActiveSmallGuide(false);
                                            } else {
                                                setCurrentActiveDropdown(null);
                                            }
                                            setActiveCard(null);
                                        }}
                                        title={dropdown.title}
                                        currentActive={
                                            dropdown.stateObject?.state?.text
                                        }
                                        type={dropdown.type}
                                        isOpened={
                                            currentActiveDropdown ===
                                            dropdown.id
                                        }
                                    />
                                ))}
                        </div>
                        <div className="header-panel-bottom">
                            {data.map((dropdown) => (
                                <HeaderDropdownListDiv
                                    elementsLen={dropdown.elementsLen}
                                    isOpened={
                                        currentActiveDropdown === dropdown.id
                                    }
                                    className="header-dropdown-list"
                                >
                                    <div className="header-dropdown-list-elements">
                                        {dropdown.type === "radio" ? (
                                            <HeaderDropdownListRadio
                                                elements={dropdown.elements}
                                                stateObject={
                                                    dropdown.stateObject
                                                }
                                            />
                                        ) : (
                                            <HeaderDropdownListCheckbox
                                                elements={dropdown.elements}
                                                stateObject={
                                                    dropdown.stateObject
                                                }
                                                // Temporary
                                                setDirectionSwitchChecked={
                                                    setDirectionSwitchChecked
                                                }
                                                setRadiusSwitchChecked={
                                                    setRadiusSwitchChecked
                                                }
                                            />
                                        )}
                                    </div>
                                </HeaderDropdownListDiv>
                            ))}
                        </div>
                    </div>
                    <div className="header-bottom">
                        <div className="show-options">
                            {data
                                .filter((dropdown) => dropdown.title === "Show")
                                .flatMap((dropdown) => dropdown.elements)
                                .map((element, index, elementsArray) => (
                                    <div
                                        key={element.id}
                                        className="show-option"
                                    >
                                        <label className="show-option-label">
                                            <Switch
                                                className="header-switch-element"
                                                onChange={() => {
                                                    elementsArray.forEach(
                                                        (el) => {
                                                            if (
                                                                el.id !==
                                                                element.id
                                                            ) {
                                                                el.stateObject.setState(
                                                                    false
                                                                );
                                                            }
                                                        }
                                                    );
                                                    element.stateObject.setState(
                                                        !element.stateObject
                                                            .state
                                                    );
                                                }}
                                                checked={
                                                    element.stateObject.state
                                                }
                                                uncheckedIcon={false}
                                                checkedIcon={false}
                                                onColor="#3C373A"
                                                offColor="#3C373A"
                                                onHandleColor="#fff"
                                                offHandleColor="#FFF"
                                                handleDiameter={14}
                                                height={16}
                                                width={30}
                                            />
                                            <span>{element.text}</span>
                                        </label>
                                    </div>
                                ))}
                        </div>
                        <Link to="/" className="arrow-back-button">
                            <img src={ButtonArrow} alt="arrow img" />
                            <p>BACK</p>
                        </Link>
                    </div>
                </div>
            </div>
        </HeaderDiv>
    );
}
