import React from "react";
import styled from "styled-components";

import "./Guide.scss";

import FingerImage from "../../../static/images/guide/finger.svg";
import ZoomImage from "../../../static/images/guide/zoom.svg";
import ZoomInOutImage from "../../../static/images/guide/zoom_in_out.svg";
import MoveImage from "../../../static/images/guide/move.svg";
import RotateImage from "../../../static/images/guide/rotate.svg";
import Arrow from "../../../static/images/guide/arrow.svg";

import TutorialDesktopVideo from "../../../static/videos/tutorial_desktop.mp4";

import {
    WindowCardOutsideButton,
    WindowCardVideo,
} from "../window-card/WindowCard";

function GuideTip({ image, text, imageScale = "1" }) {
    return (
        <div className="guide-tip">
            <div className="guide-tip-image">
                <img style={{ scale: imageScale }} src={image} alt={text} />
            </div>
            <div className="guide-tip-label">
                <span className="guide-tip-label-text">{text}</span>
            </div>
        </div>
    );
}

function GuideButton({ text, onClick, className, img }) {
    return (
        <div className={className} onClick={onClick}>
            <span className="guide-button-text">{text}</span>
            {img && <img src={img} alt="" />}
        </div>
    );
}

const GuideDiv = styled.div`
    opacity: ${({ activeGuide }) => (activeGuide ? "100%" : "0%")};
    pointer-events: ${({ activeGuide }) => (activeGuide ? "all" : "none")};
`;

export default function Guide({
    activeGuide,
    setActiveGuide,
    isMobile,
    setActiveWindow,
    setWindowContent,
}) {
    return (
        <>
            {isMobile ? (
                <GuideDiv activeGuide={activeGuide} className="guide-mobile">
                    <div
                        className="guide-background"
                        onClick={() => setActiveGuide(false)}
                    />
                    <div className="guide-container">
                        <div className="guide-container-inner">
                            <div className="guide-title">
                                <span className="guide-title-text">
                                    Saas Interactive Map
                                </span>
                            </div>
                            <div className="guide-desc">
                                <span className="guide-desc-text">
                                    Find your premium living in the UAE with
                                    SAAS Properties!
                                </span>
                            </div>
                            <div className="guide-tips">
                                <GuideTip
                                    image={FingerImage}
                                    text="Use two fingers to move the map"
                                />
                                <GuideTip
                                    image={ZoomImage}
                                    text="Pinch to zoom in and zoom out"
                                />
                            </div>
                            <div className="guide-buttons">
                                <GuideButton
                                    className="guide-button"
                                    text="Tap to start"
                                    onClick={() => setActiveGuide(false)}
                                />
                                <GuideButton
                                    className="guide-button guide-button-transparent"
                                    text="VIDEO TUTORIAL"
                                    onClick={() => {
                                        setActiveGuide(false);
                                        setActiveWindow(true);
                                        setWindowContent(
                                            <>
                                                <WindowCardVideo
                                                    video={TutorialDesktopVideo}
                                                />
                                            </>
                                        );
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </GuideDiv>
            ) : (
                <GuideDiv activeGuide={activeGuide} className="guide">
                    <div
                        className="guide-background"
                        onClick={() => setActiveGuide(false)}
                    />
                    <div className="guide-container">
                        <div className="guide-title">
                            <span className="guide-title-text">
                                SAAS Interactive Map
                            </span>
                        </div>
                        <div className="guide-desc">
                            <span className="guide-desc-text">
                                Find your premium living in the UAE with SAAS
                                Properties!
                            </span>
                        </div>
                        <div className="guide-tips">
                            <GuideTip
                                image={ZoomInOutImage}
                                text={"Scroll or pinch to zoom in and out"}
                                imageScale="1.6"
                            />
                            <GuideTip
                                image={MoveImage}
                                text={
                                    "Hold down to move the map left and right"
                                }
                                imageScale="1.6"
                            />
                            <GuideTip
                                image={RotateImage}
                                text={
                                    "Hold down Shift + touchpad/left mouse button to rotate"
                                }
                                imageScale="1.6"
                            />
                        </div>
                        <div className="guide-buttons">
                            <GuideButton
                                className="guide-button guide-button-transparent"
                                text="VIDEO TUTORIAL"
                                onClick={() => {
                                    setActiveGuide(false);
                                    setActiveWindow(true);
                                    setWindowContent(
                                        <>
                                            <WindowCardVideo
                                                video={TutorialDesktopVideo}
                                            />
                                            <WindowCardOutsideButton
                                                text={"SKIP"}
                                                onClick={() =>
                                                    setActiveWindow(false)
                                                }
                                            />
                                        </>
                                    );
                                }}
                            />
                            <GuideButton
                                className="guide-button"
                                text="START"
                                onClick={() => setActiveGuide(false)}
                                img={Arrow}
                            />
                        </div>
                    </div>
                </GuideDiv>
            )}
        </>
    );
}
