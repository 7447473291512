import "./HalfScreen.scss";

export default function ({ left, right }) {
    return (
        <div className="half-screen">
            <div className="half-screen-element">{left}</div>
            <div className="half-screen-element">{right}</div>
        </div>
    );
}
