import React from "react";
import { useState, useEffect, useRef } from "react";
import { useSwipeable } from "react-swipeable";

import { styled } from "styled-components";

import "./Card.scss";

import ExitCross from "../../../static/images/card-exit-cross.svg";
import ButtonArrow from "../../../static/images/card-arrow.svg";
import CardDeyaarCircle from "../../../static/images/deyaar-half-circle.png";
import DefaultBgImage from "../../../static/images/default-bg-card.png";

import AbuDhabiBirdView from "../../../static/images/cam_01.jpg";
import DubaiBirdView from "../../../static/images/cam_02.jpg";

import {
    WindowCardBirdView,
    WindowCardGallery,
} from "../window-card/WindowCard";
import { Link } from "react-router-dom";

const CircleDiv = styled.div`
    background-color: ${({ status }) =>
        status ? "rgba(255, 255, 255, 1)" : "rgba(255, 255, 255, 0.5)"};
`;

function CardTopCircles({
    previews,
    setImageIndex,
    activeCircle,
    setActiveCircle,
}) {
    return (
        <div className="card-top-circles">
            {previews.map((preview) => (
                <CircleDiv
                    status={activeCircle === preview}
                    className="card-top-circle"
                    onClick={() => {
                        setActiveCircle(preview);
                        setImageIndex(previews.indexOf(preview));
                    }}
                ></CircleDiv>
            ))}
        </div>
    );
}

function CardText({ text, id }) {
    return (
        <div className={"card-" + id}>
            <span className={"card-" + id + "-text"}>{text}</span>
        </div>
    );
}

function CardTextImage({ text, id, image }) {
    return (
        <div className={"card-" + id}>
            <span className={"card-" + id + "-text"}>{text}</span>
            <img
                src={image}
                alt={"card-" + id + "-logo"}
                className={"card-" + id + "-image"}
            />
        </div>
    );
}

const CardButtonDiv = styled.div`
    max-width: ${({ width }) => (width ? width : "48%")};
`;

function CardBottomButton({ text, width, onClick }) {
    return (
        <CardButtonDiv
            className="card-bottom-button"
            width={width}
            onClick={onClick}
        >
            <div width={width} className="card-bottom-button-inner">
                <span className="card-bottom-button-text">{text}</span>
                <img src={ButtonArrow} alt="button-arrow" />
            </div>
        </CardButtonDiv>
    );
}

const CardPDFMessageDiv = styled.div`
    max-width: 90%;

    margin: 0 auto;
    margin-top: 65px;

    color: #000000;
    line-height: 145%;
`;

function CardPDFMessage({ PDFLink }) {
    return (
        <CardPDFMessageDiv>
            <p>This browser does not support inline PDFs.</p>
            <p>
                Please download the PDF to view it:{" "}
                <a href={PDFLink} target="_blank" rel="noopener noreferrer">
                    Download PDF
                </a>
            </p>
        </CardPDFMessageDiv>
    );
}

const CardDiv = styled.div`
    opacity: ${({ status }) => (status ? "1" : "0")};
    pointer-events: ${({ status }) => (status ? "all" : "none")};
    transform: ${({ status }) =>
        status ? "translateY(0)" : "translateY(50px)"};
`;

const CardTopDiv = styled.div`
    background-image: url(${({ image }) => image});
`;

export default function Card({
    activeCard,
    setActiveCard,
    cardContent,
    activeWindow,
    setActiveWindow,
    setWindowContent,
    activeSmallCard,
    setActiveSmallCard,
    setSmallCardContent,
}) {
    // Videos
    const videoRef = useRef(null);

    // PDF's
    function checkForMobile(PDFLink) {
        return !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
            navigator.userAgent
        ) ? (
            <iframe
                src={PDFLink}
                width="100%"
                height="100%"
                title="PDF Viewer"
            />
        ) : (
            <CardPDFMessage PDFLink={PDFLink} />
        );
    }

    function onClickButton(buttonData) {
        switch (buttonData.action_type) {
            case "open_link":
                window.open(buttonData.link, "_blank");
                break;
            case "open_bird":
                setWindowContent(null);
                setActiveWindow(true);
                setActiveCard(false);

                const birdData = cardContent.markers.find(
                    (marker) => marker.id === buttonData.bird_id
                ).content;
                setTimeout(() => {
                    setWindowContent(
                        <WindowCardBirdView
                            background={birdData.background}
                            projects={birdData.projects}
                            activeSmallCard={activeSmallCard}
                            setActiveSmallCard={setActiveSmallCard}
                            setSmallCardContent={setSmallCardContent}
                        />
                    );
                }, 10);

                break;
            default:
                break;
        }
    }

    // Images
    const [activeImage, setActiveImage] = useState();
    const [imageIndex, setImageIndex] = useState(0);
    const [activeCircle, setActiveCircle] = useState();
    useEffect(() => {
        if (cardContent.previews.length === 0) {
            setActiveImage(DefaultBgImage);
        } else {
            setActiveImage(cardContent.previews[0]);
        }
        setActiveCircle(cardContent.previews[0]);
    }, [cardContent]);

    useEffect(() => {
        let image = cardContent.previews[imageIndex];
        setActiveImage(image);
        setActiveCircle(image);
    }, [imageIndex]);

    const changeImage = (action) => {
        if (!(cardContent.previews.length === 0)) {
            let nextIndex = imageIndex + action;
            if (nextIndex > cardContent.previews.length - 1) {
                setImageIndex(0);
            } else if (nextIndex < 0) {
                setImageIndex(cardContent.previews.length - 1);
            } else {
                setImageIndex(nextIndex);
            }
        }
    };

    const openWindow = (content) => {
        if (content) {
            setActiveCard(false);
            setWindowContent(content);
            setActiveWindow(true);
        }
    };

    useEffect(() => {
        try {
            videoRef.current.pause();
        } catch {}
    }, [activeWindow]);

    // Swipe handlers
    const handlers = useSwipeable({
        onSwipedLeft: () => changeImage(1),
        onSwipedRight: () => changeImage(-1),
        swipeDuration: 500,
        preventScrollOnSwipe: true,
        preventDefaultTouchmoveEvent: true,
        trackMouse: false,
    });

    return (
        <div className="card">
            <CardDiv status={activeCard} className="card-container">
                <div className="card-exit" onClick={() => setActiveCard(null)}>
                    <img src={ExitCross} alt="exit-cross" />
                </div>
                <CardTopDiv
                    className="card-top"
                    image={activeImage}
                    {...handlers}
                >
                    <CardTopCircles
                        previews={cardContent.previews}
                        setImageIndex={setImageIndex}
                        activeCircle={activeCircle}
                        setActiveCircle={setActiveCircle}
                    />
                    <div
                        className="card-top-back"
                        onClick={() =>
                            openWindow(
                                !(cardContent.previews.length === 0) ? (
                                    <WindowCardGallery
                                        cardActiveImageIndex={imageIndex}
                                        images={cardContent.previews}
                                        setActiveWindow={setActiveWindow}
                                        setActiveCard={setActiveCard}
                                        setCardImageIndex={setImageIndex}
                                    />
                                ) : (
                                    false
                                )
                            )
                        }
                    />
                </CardTopDiv>
                <div className="card-bottom">
                    <CardText text={cardContent.title} id="title" />
                    <CardText text={cardContent.desc} id="desc" />
                    {cardContent.buttons?.map((button) =>
                        button.type === "double" ? (
                            <div className="card-bottom-buttons">
                                <CardBottomButton
                                    text={button.data[0].text}
                                    onClick={() =>
                                        onClickButton(button.data[0])
                                    }
                                />
                                <CardBottomButton
                                    text={button.data[1].text}
                                    onClick={() =>
                                        onClickButton(button.data[1])
                                    }
                                />
                            </div>
                        ) : (
                            button.type === "single" && (
                                <CardBottomButton
                                    text={button.data[0].text}
                                    onClick={() =>
                                        onClickButton(button.data[0])
                                    }
                                    width="48%"
                                />
                            )
                        )
                    )}
                </div>
            </CardDiv>
        </div>
    );
}
