import e311 from "../../static/images/e311.svg";
import e102 from "../../static/images/e102.svg";
import e44 from "../../static/images/e44.svg";
import e11 from "../../static/images/e11.svg";

export const hospitals = [
    {
        coords: [25.01890639781721, 55.087501796314776],
        name: "Aster Cedars Hospital, Jebel Ali",
    },
    {
        coords: [25.10671761042713, 55.18060680251059],
        name: "Al Zahra Hospital Dubai",
    },
    {
        coords: [25.098284555951924, 55.18343921538387],
        name: "Saudi German Hospital | SGH Dubai",
    },
    {
        coords: [25.14687411107842, 55.22359834029489],
        name: "Burjeel hospital Dubai",
    },
    {
        coords: [25.18350919349171, 55.242638296723925],
        name: "Medcare Hospital Al Safa",
    },
    {
        coords: [25.19709579579002, 55.23687110668274],
        name: "Emirates Hospital Jumeirah",
    },
    {
        coords: [25.165714829879906, 55.212349220977394],
        name: "Zia Medical Center L.L.C",
    },
    {
        coords: [25.230472090613993, 55.26921212344572],
        name: "Iranian Hospital - Dubai",
    },
    {
        coords: [25.252286472046436, 55.288673590980416],
        name: "Aster Hospital - Mankhool",
    },
    {
        coords: [25.25750077912526, 55.30659554259554],
        name: "Medeor 24x7 Hospital, Dubai",
    },
    {
        coords: [25.24546670684888, 55.319110576780616],
        name: "Rashid Hospital",
    },
    {
        coords: [25.23282884499218, 55.3208973158906],
        name: "Dr. Sulaiman Al Habib Hospital",
    },
    {
        coords: [25.23218037222047, 55.3173894176884],
        name: "Emirates Specialty Hospital",
    },
    {
        coords: [25.225949161092824, 55.318232590356416],
        name: "Al Jalila Children's Speciality Hospital",
    },
    {
        coords: [25.22365247699969, 55.32776497315396],
        name: "HMS FIFA Medical Centre of Excellence Dubai",
    },
    {
        coords: [25.25005241763689, 55.34556255892693],
        name: "PRIME Hospital - Multi Specialty Private Hospital Dubai",
    },
    {
        coords: [25.29052383344065, 55.369281595203795],
        name: "NMC Specialty Hospital Al Nahda",
    },
    {
        coords: [25.291225088137914, 55.38474091386225],
        name: "Dr.Bharadwaj Ponnada",
    },
    {
        coords: [25.285508262892492, 55.32164048084987],
        name: "Dubai Hospital",
    },
    {
        coords: [25.28298555094324, 55.318064315550814],
        name: "Al Kuwait Hospital Dubai",
    },
    {
        coords: [25.025305419127776, 55.1527033],
        name: "Medicentres Al Furjan",
    },
    {
        coords: [25.11059412985889, 55.37755146931763],
        name: "Health Hub Clinic",
    },
    {
        coords: [25.079914194300297, 55.14374431534118],
        name: "Medcare Medical Centre, Marina",
    },
    {
        coords: [25.190289024135076, 55.2650042],
        name: "Emirates Hospital Clinics - Business Bay",
    },
    {
        coords: [25.04147652987433, 55.19583586136474],
        name: "Mediclinic Me'aisem",
    },
];

export const schools = [
    {
        coords: [25.100087823614306, 55.18293506848452],
        name: "American School of Dubai",
    },
    {
        coords: [25.079079563751375, 55.17925788668553],
        name: "Dubai International Academy - Emirates Hills",
    },
    {
        coords: [25.107322930980377, 55.21120543520273],
        name: "Smart Vision School Dubai",
    },
    {
        coords: [25.081555226576874, 55.22796782882875],
        name: "GEMS Founders School - Al Barsha",
    },
    {
        coords: [25.062456511575668, 55.22685649797154],
        name: "Nord Anglia International School Dubai",
    },
    {
        coords: [25.13679582558181, 55.1947585285348],
        name: "Raffles International School",
    },
    {
        coords: [25.133036784488095, 55.20488485487785],
        name: "Horizon International School",
    },
    {
        coords: [25.12520540623814, 55.20899106288544],
        name: "Improve ME Institute",
    },
    {
        coords: [25.152823109125855, 55.24872912007342],
        name: "Credence High School | Best CBSE - Indian schools in Dubai",
    },
    {
        coords: [25.16648452969215, 55.25931518008034],
        name: "SIMBR Sports @Clarion School",
    },
    {
        coords: [25.173171083642846, 55.25850862308761],
        name: "Global Indian International School Dubai - Best CBSE School in Dubai",
    },
    {
        coords: [25.17862363613545, 55.30339646555844],
        name: "Hartland International School Dubai",
    },
    {
        coords: [25.1466942095673, 55.377761900339976],
        name: "Repton School Dubai",
    },
    {
        coords: [25.208532333722864, 55.33257121349918],
        name: "Swiss International Scientific School in Dubai",
    },
    {
        coords: [25.21264262652576, 55.37328829348427],
        name: "Deira International School",
    },
    {
        coords: [25.282342809274944, 55.40186015805475],
        name: "The Indian Academy Dubai",
    },
    {
        coords: [25.28006301875451, 55.36411817267484],
        name: "Dubai Scholars Private School",
    },
    {
        coords: [25.236092955799275, 55.31606377188723],
        name: "Dubai Gem Private School",
    },
    {
        coords: [25.246237787523523, 55.28819051996542],
        name: "Ambassador School",
    },
    {
        coords: [25.04273979068158, 55.24326233863526],
        name: "Gems Metropole School",
    },
    {
        coords: [25.11905156533154, 55.38810699999999],
        name: "GEMS Wellington Academy - Silicon Oasis",
    },
    {
        coords: [25.13667813078154, 55.21018573068237],
        name: "Emirates International School-Meadows",
    },
    {
        coords: [25.186477492555913, 55.280174915341185],
        name: "City School Holdings International",
    },
    {
        coords: [25.041385412104116, 55.19336549204711],
        name: "Kids Kingdom Nursery School IMPZ",
    },




    {
        coords: [25.276686121640804, 55.368378774746965],
        name: "Adab Iranian Private School"
    },
    {
        coords: [25.108892832760166, 55.18908395515979],
        name: "Al Arqam Private School"
    },
    {
        coords: [25.230684983713825, 55.389212037969514],
        name: "Al Eman School Dubai"
    },
    {
        coords: [25.30125806757945, 55.344840297494024],
        name: "Al Ittihad Private School, Al Mamzar"
    },
    {
        coords: [25.180990727922822, 55.24067396495314],
        name: "Al Ittihad Private School, Jumeirah"
    },
    {
        coords: [25.287140985520715, 55.36660179564288],
        name: "Al Maaref Private School"
    },
    {
        coords: [25.236093689708834, 55.43939685146221],
        name: "Al Mawakeb Al Khawaneej"
    },
    {
        coords: [25.235957821574807, 55.43940758029804],
        name: "Al Mawakeb School, Al Barsha"
    },
    {
        coords: [25.23714301176778, 55.31733938029801],
        name: "Al Rashid Al Saleh Private School"
    },
    {
        coords: [25.266498916694104, 55.3858024379706],
        name: "Al Sadiq Islamic English School, Dubai"
    },
    {
        coords: [25.25963293146638, 55.38161075331376],
        name: "Al Salam Community School"
    },
    {
        coords: [25.289475992784485, 55.369532495642986],
        name: "Al Salam Private School"
    },
    {
        coords: [25.213296665067432, 55.255266768655524],
        name: "Al Shorouq Private School"
    },
    {
        coords: [25.144119048511772, 55.24835052447431],
        name: "Ambassador International Academy"
    },
    {
        coords: [25.246370787068006, 55.28818966680583],
        name: "Ambassador School"
    },
    {
        coords: [25.246283450670674, 55.28827549749241],
        name: "Ambassador Kindergarten"
    },
    {
        coords: [25.270564917854745, 55.37841143797071],
        name: "Apple International School"
    },
    {
        coords: [25.0791038772378, 55.23406411097977],
        name: "Bloom World Academy"
    },
    {
        coords: [25.22247467893588, 55.395959337969245],
        name: "Bright Learners Private School"
    },
    {
        coords: [25.26570801018503, 55.37466675331382],
        name: "Cambridge International School, Dubai"
    },
    {
        coords: [25.209328982753537, 55.266833082148004],
        name: "Citizens School"
    },
    {
        coords: [25.153605590555667, 55.21088293452856],
        name: "Collegiate International School"
    },
    {
        coords: [25.212624532799403, 55.37343433241665],
        name: "Deira International School"
    },
    {
        coords: [25.245375989213613, 55.41719103982077],
        name: "Dubai Arabian American Private School"
    },
    {
        coords: [25.05331152431643, 55.16912880912821],
        name: "Dubai British Foundation School"
    },
    {
        coords: [25.23606690928921, 55.31624658029805],
        name: "Dubai Gem Private School"
    },
    {
        coords: [25.113840167629718, 55.20708088214504],
        name: "Dubai International Academy Al Barsha"
    },
    {
        coords: [25.135862231995777, 55.210132082145684],
        name: "Emirates International School, Jumeirah"
    },
    {
        coords: [25.065167839645895, 55.15587692262113],
        name: "Emirates International School, Meadows"
    },
    {
        coords: [25.080007289076537, 55.22988458029336],
        name: "GEMS Al Barsha National School"
    },
    {
        coords: [25.178680476142297, 55.30344590913213],
        name: "Hartland International School"
    },
    {
        coords: [24.98296522758476, 55.18332839563358],
        name: "Greenfield International School"
    },
    {
        coords: [25.28586379910684, 55.370193237971186],
        name: "His Highness Shaikh Rashid Al Maktoum Pakistan School Dubai"
    },
    {
        coords: [25.132953374044668, 55.20494773981731],
        name: "Horizon International School"
    },
    {
        coords: [25.104313477304796, 55.164026266801514],
        name: "iCademy Middle East"
    },
    {
        coords: [25.189064176019883, 55.41041056680405],
        name: "Ignite School"
    },
    {
        coords: [25.186590107042598, 55.40366195331142],
        name: "International Academic School Dubai"
    },
    {
        coords: [25.14790201571773, 55.3777334244744],
        name: "International School of Creative Science – Nad Al Sheba"
    },
    {
        coords: [25.00992986625724, 55.255089637962726],
        name: "Jebel Ali School"
    },
    {
        coords: [25.176804169676963, 55.23545780913197],
        name: "Jumeirah College"
    },
    {
        coords: [25.18010835506891, 55.24367811098294],
        name: "Jumeirah English Speaking School (JESS), Jumeirah"
    },
    {
        coords: [25.0840943190664, 55.25184491283074],
        name: "Kings School Al Barsha"
    },
    {
        coords: [25.23434354817746, 55.315772622626255],
        name: "Lycee Francais International Georges Pompidou Oud-Metha"
    },
    {
        coords: [25.23774025821219, 55.437167010984645],
        name: "Mirdif American School"
    },
    {
        coords: [25.274201908666715, 55.34172608029915],
        name: "National Charity School Primary"
    },
    {
        coords: [25.237539453726274, 55.34736698399958],
        name: "New Indian Model School, Dubai"
    },
    {
        coords: [25.18601851064372, 55.401835882147275],
        name: "Newlands School Dubai"
    },
    {
        coords: [25.23640532609672, 55.43177556680549],
        name: "North American International School (NAIS)"
    },
    {
        coords: [25.28120290423261, 55.40237225516513],
        name: "Oxford School Dubai"
    },
    {
        coords: [25.283605602359845, 55.34849410913532],
        name: "Queen International School"
    },
    {
        coords: [25.08016061480564, 55.242924484658815],
        name: "Repton School Al Barsha"
    },
    {
        coords: [25.079738425066882, 55.22655409574125],
        name: "Dubai Heights Academy"
    },
    {
        coords: [25.080152476498068, 55.232655464494286],
        name: "Brighton College Dubai"
    }    
];

export const retails = [
    {
        coords: [25.11351614841692, 55.13900623626816],
        name: "Shvili Nakheel Mall",
    },
    {
        coords: [25.076874511653948, 55.14054701418028],
        name: "Dubai Marina Mall",
    },
    {
        coords: [25.075729830770424, 55.13117552705891],
        name: "The Beach • JBR",
    },
    {
        coords: [25.042905353276936, 55.11801146891436],
        name: "Thrifty Car Rental - Ibn Battuta Mall",
    },
    {
        coords: [25.0991555710011, 55.2044746270828],
        name: "Al Barsha Mall",
    },
    {
        coords: [25.102339976119893, 55.23988247769101],
        name: "Dubai Hills Mall",
    },
    {
        coords: [25.029573131254864, 55.270207144552394],
        name: "The Ranches Souk | Arabian Ranches II",
    },
    {
        coords: [25.027846006196256, 55.26749598784451],
        name: "Mudon Community Centre",
    },
    {
        coords: [25.21844959127823, 55.25229206103343],
        name: "Mercato Shopping Mall",
    },
    {
        coords: [25.208566907745, 55.26272353239254],
        name: "City Walk",
    },
    {
        coords: [25.199740850354523, 55.27640893807277],
        name: "Dubai Mall Fashion Avenue",
    },
    {
        coords: [25.19760206742959, 55.279837237034776],
        name: "Dubai Mall",
    },
    {
        coords: [25.230155823448058, 55.318893011664024],
        name: "Wafi City",
    },
    {
        coords: [25.252607022082064, 55.33263087194112],
        name: "City Centre Deira",
    },
    {
        coords: [25.222110274697364, 55.35277798311384],
        name: "Dubai Festival City Mall",
    },
    {
        coords: [25.074737038786584, 55.40042673498007],
        name: "Dubai Outlet Mall",
    },
    {
        coords: [25.11216758335521, 55.374833891991216],
        name: "Silicon Central Mall",
    },
    {
        coords: [25.11919499230177, 55.200608],
        name: "Mall Of the Emirates",
    },
    {
        coords: [25.02596760127262, 55.152053877270525],
        name: "The Pavilion- Al Furjan",
    },
    {
        coords: [25.191935608409175, 55.26753768465882],
        name: "Bay Avenue Park",
    },
    {
        coords: [25.04127445797842, 55.19715902272949],
        name: "City Centre Me’aisem",
    },
    {
        coords: [25.044741579639872, 55.12087655278342],
        name: "Ibn Battuta Mall",
    },
    {
        coords: [25.216532519178823, 55.40857810065984],
        name: "Mirdif City Center",
    },
    {
        coords: [25.254629614911565, 55.30390269142569],
        name: "Bur Juman",
    },
    {
        coords: [25.17428542688742, 55.41692421092661],
        name: "Dragon Mart Dubai",
    },
    {
        coords: [25.12677351783237, 55.2105529475272],
        name: "Gold & Diamond Park",
    },
    {
        coords: [25.267504710671595, 55.31815485463798],
        name: "Al Ghurair Centre",
    },
    {
        coords: [25.139743828696567, 55.220523608610016],
        name: "Times Square Center Mall",
    },



    {
        coords: [25.13320772099167, 55.18609393890729],
        name: "Madinat Jumeirah",
    },
    {
        coords: [25.066214408778382, 55.216611933748815],
        name: "Circle Mall",
    },
    {
        coords: [25.047215696619052, 55.24411724483057],
        name: "First Avenue Mall",
    },
    {
        coords: [25.069789039895298, 55.24240958346659],
        name: "City Centre Al Barsha",
    },
    {
        coords: [25.04596166659546, 55.23964665820285],
        name: "The Ribbon",
    },
    {
        coords: [25.04551693873682, 55.233981042983366],
        name: "Union Coop Building Motor City",
    },
    {
        coords: [25.04133823620241, 55.22985865674944],
        name: "Waitrose Motor City Building",
    },
];

export const roads = [
    //roads ?????????????
    {
        coords: [25.145166314013206, 55.22141106197664],
        name: "Road E311",
        customIcon: e311,
    },
    {
        coords: [25.203248243983126, 55.269788310400415],
        name: "Road E102",
        customIcon: e102,
    },
    {
        coords: [25.177288723928108, 55.27720144171509],
        name: "Road E44",
        customIcon: e44,
    },
    {
        coords: [25.192989679260254, 55.24149192208289],
        name: "Road E11",
        customIcon: e11,
    },
    {
        coords: [25.182230933786364, 55.300593637280095],
        name: "Road E102",
        customIcon: e102,
    },
    {
        coords: [25.186284439375513, 55.35470809852404],
        name: "Road E102",
        customIcon: e102,
    },
];

export const dubai_islands = [
    { lat: 25.356706, lng: 55.29406 },
    { lat: 25.354612, lng: 55.297064 },
    { lat: 25.354767, lng: 55.296464 },
    { lat: 25.353293, lng: 55.296807 },
    { lat: 25.352828, lng: 55.298352 },
    { lat: 25.35213, lng: 55.298094 },
    { lat: 25.349958, lng: 55.30024 },
    { lat: 25.348407, lng: 55.30436 },
    { lat: 25.348019, lng: 55.305047 },
    { lat: 25.344994, lng: 55.308737 },
    { lat: 25.344683, lng: 55.309081 },
    { lat: 25.343442, lng: 55.308909 },
    { lat: 25.342511, lng: 55.309939 },
    { lat: 25.341658, lng: 55.310797 },
    { lat: 25.339952, lng: 55.312342 },
    { lat: 25.337314, lng: 55.310797 },
    { lat: 25.336693, lng: 55.310969 },
    { lat: 25.334366, lng: 55.314917 },
    { lat: 25.334133, lng: 55.315861 },
    { lat: 25.334366, lng: 55.317664 },
    { lat: 25.333978, lng: 55.319294 },
    { lat: 25.333203, lng: 55.319466 },
    { lat: 25.332039, lng: 55.319466 },
    { lat: 25.330487, lng: 55.321183 },
    { lat: 25.330099, lng: 55.32144 },
    { lat: 25.33041, lng: 55.322814 },
    { lat: 25.330875, lng: 55.324873 },
    { lat: 25.331263, lng: 55.325388 },
    { lat: 25.329168, lng: 55.32762 },
    { lat: 25.329091, lng: 55.328049 },
    { lat: 25.32785, lng: 55.330023 },
    { lat: 25.326376, lng: 55.331397 },
    { lat: 25.327384, lng: 55.329852 },
    { lat: 25.328315, lng: 55.328393 },
    { lat: 25.328781, lng: 55.327792 },
    { lat: 25.327462, lng: 55.328221 },
    { lat: 25.326841, lng: 55.328135 },
    { lat: 25.325755, lng: 55.327878 },
    { lat: 25.324746, lng: 55.328393 },
    { lat: 25.321721, lng: 55.328736 },
    { lat: 25.320479, lng: 55.330538 },
    { lat: 25.320479, lng: 55.331053 },
    { lat: 25.322652, lng: 55.331397 },
    { lat: 25.311696, lng: 55.329508 },
    { lat: 25.308748, lng: 55.329508 },
    { lat: 25.307739, lng: 55.329508 },
    { lat: 25.307196, lng: 55.328993 },
    { lat: 25.307363, lng: 55.328086 },
    { lat: 25.307441, lng: 55.327742 },
    { lat: 25.307751, lng: 55.327227 },
    { lat: 25.307363, lng: 55.326626 },
    { lat: 25.306354, lng: 55.327227 },
    { lat: 25.304647, lng: 55.327742 },
    { lat: 25.304104, lng: 55.327399 },
    { lat: 25.303871, lng: 55.326541 },
    { lat: 25.303716, lng: 55.325682 },
    { lat: 25.303716, lng: 55.325339 },
    { lat: 25.303095, lng: 55.325339 },
    { lat: 25.302397, lng: 55.325511 },
    { lat: 25.302009, lng: 55.325854 },
    { lat: 25.301, lng: 55.325854 },
    { lat: 25.300845, lng: 55.325167 },
    { lat: 25.301699, lng: 55.322678 },
    { lat: 25.302164, lng: 55.321648 },
    { lat: 25.302319, lng: 55.322077 },
    { lat: 25.303173, lng: 55.320618 },
    { lat: 25.303949, lng: 55.319846 },
    { lat: 25.304647, lng: 55.319331 },
    { lat: 25.304802, lng: 55.317958 },
    { lat: 25.304182, lng: 55.316241 },
    { lat: 25.303483, lng: 55.315554 },
    { lat: 25.302397, lng: 55.315211 },
    { lat: 25.301854, lng: 55.315125 },
    { lat: 25.301466, lng: 55.315297 },
    { lat: 25.301543, lng: 55.316842 },
    { lat: 25.300612, lng: 55.320361 },
    { lat: 25.299526, lng: 55.322936 },
    { lat: 25.299215, lng: 55.32388 },
    { lat: 25.299138, lng: 55.324052 },
    { lat: 25.298983, lng: 55.323537 },
    { lat: 25.298439, lng: 55.323537 },
    { lat: 25.294637, lng: 55.319846 },
    { lat: 25.294947, lng: 55.318902 },
    { lat: 25.295103, lng: 55.318644 },
    { lat: 25.293628, lng: 55.316928 },
    { lat: 25.293085, lng: 55.317872 },
    { lat: 25.291145, lng: 55.314696 },
    { lat: 25.290928, lng: 55.314434 },
    { lat: 25.291005, lng: 55.313919 },
    { lat: 25.290229, lng: 55.313575 },
    { lat: 25.289996, lng: 55.313575 },
    { lat: 25.285837, lng: 55.307396 },
    { lat: 25.283819, lng: 55.303962 },
    { lat: 25.283043, lng: 55.303447 },
    { lat: 25.283043, lng: 55.302932 },
    { lat: 25.283043, lng: 55.301731 },
    { lat: 25.283509, lng: 55.301302 },
    { lat: 25.282422, lng: 55.299843 },
    { lat: 25.282313, lng: 55.299499 },
    { lat: 25.281615, lng: 55.299671 },
    { lat: 25.279364, lng: 55.294693 },
    { lat: 25.285495, lng: 55.292375 },
    { lat: 25.286039, lng: 55.292547 },
    { lat: 25.285806, lng: 55.29289 },
    { lat: 25.28534, lng: 55.292976 },
    { lat: 25.285262, lng: 55.293062 },
    { lat: 25.285262, lng: 55.294178 },
    { lat: 25.289996, lng: 55.29289 },
    { lat: 25.289841, lng: 55.290315 },
    { lat: 25.288211, lng: 55.290487 },
    { lat: 25.286659, lng: 55.291603 },
    { lat: 25.286582, lng: 55.29186 },
    { lat: 25.286271, lng: 55.291603 },
    { lat: 25.286039, lng: 55.291431 },
    { lat: 25.287668, lng: 55.290315 },
    { lat: 25.289919, lng: 55.289972 },
    { lat: 25.293799, lng: 55.290916 },
    { lat: 25.295273, lng: 55.289972 },
    { lat: 25.296593, lng: 55.2892 },
    { lat: 25.296437, lng: 55.286968 },
    { lat: 25.301792, lng: 55.284221 },
    { lat: 25.304896, lng: 55.281303 },
    { lat: 25.312112, lng: 55.283363 },
    { lat: 25.314517, lng: 55.28508 },
    { lat: 25.313974, lng: 55.285766 },
    { lat: 25.313431, lng: 55.286281 },
    { lat: 25.312655, lng: 55.286796 },
    { lat: 25.311801, lng: 55.288427 },
    { lat: 25.31087, lng: 55.288856 },
    { lat: 25.310172, lng: 55.2898 },
    { lat: 25.306991, lng: 55.295036 },
    { lat: 25.306991, lng: 55.295637 },
    { lat: 25.306215, lng: 55.296066 },
    { lat: 25.305284, lng: 55.295637 },
    { lat: 25.30443, lng: 55.295637 },
    { lat: 25.303421, lng: 55.292976 },
    { lat: 25.301171, lng: 55.290659 },
    { lat: 25.298688, lng: 55.289972 },
    { lat: 25.297446, lng: 55.290659 },
    { lat: 25.296903, lng: 55.29126 },
    { lat: 25.296593, lng: 55.291517 },
    { lat: 25.296437, lng: 55.292032 },
    { lat: 25.297446, lng: 55.29289 },
    { lat: 25.299076, lng: 55.293319 },
    { lat: 25.30055, lng: 55.294349 },
    { lat: 25.302412, lng: 55.296581 },
    { lat: 25.303266, lng: 55.299156 },
    { lat: 25.303421, lng: 55.302761 },
    { lat: 25.303266, lng: 55.304048 },
    { lat: 25.302956, lng: 55.304907 },
    { lat: 25.301792, lng: 55.306022 },
    { lat: 25.301481, lng: 55.309799 },
    { lat: 25.301792, lng: 55.311601 },
    { lat: 25.303732, lng: 55.312803 },
    { lat: 25.304818, lng: 55.31246 },
    { lat: 25.305904, lng: 55.311172 },
    { lat: 25.307146, lng: 55.311001 },
    { lat: 25.307999, lng: 55.310743 },
    { lat: 25.31056, lng: 55.31452 },
    { lat: 25.311336, lng: 55.31658 },
    { lat: 25.312112, lng: 55.317867 },
    { lat: 25.314827, lng: 55.319927 },
    { lat: 25.318241, lng: 55.321729 },
    { lat: 25.323905, lng: 55.323618 },
    { lat: 25.325379, lng: 55.323189 },
    { lat: 25.327163, lng: 55.322073 },
    { lat: 25.327474, lng: 55.320699 },
    { lat: 25.327241, lng: 55.319927 },
    { lat: 25.32631, lng: 55.319669 },
    { lat: 25.324913, lng: 55.319669 },
    { lat: 25.324448, lng: 55.320356 },
    { lat: 25.324448, lng: 55.320785 },
    { lat: 25.319095, lng: 55.31924 },
    { lat: 25.318707, lng: 55.319069 },
    { lat: 25.318319, lng: 55.318296 },
    { lat: 25.317077, lng: 55.317095 },
    { lat: 25.316379, lng: 55.317266 },
    { lat: 25.315914, lng: 55.317438 },
    { lat: 25.315991, lng: 55.316923 },
    { lat: 25.316224, lng: 55.315893 },
    { lat: 25.317233, lng: 55.31452 },
    { lat: 25.31731, lng: 55.313232 },
    { lat: 25.31731, lng: 55.312717 },
    { lat: 25.316379, lng: 55.312374 },
    { lat: 25.319576, lng: 55.308168 },
    { lat: 25.319886, lng: 55.307396 },
    { lat: 25.319964, lng: 55.306967 },
    { lat: 25.320739, lng: 55.306022 },
    { lat: 25.32136, lng: 55.304048 },
    { lat: 25.322912, lng: 55.302847 },
    { lat: 25.324386, lng: 55.300958 },
    { lat: 25.326403, lng: 55.298555 },
    { lat: 25.329816, lng: 55.295294 },
    { lat: 25.330204, lng: 55.294693 },
    { lat: 25.330282, lng: 55.293749 },
    { lat: 25.33067, lng: 55.293491 },
    { lat: 25.331678, lng: 55.292547 },
    { lat: 25.332671, lng: 55.292032 },
    { lat: 25.334611, lng: 55.292375 },
    { lat: 25.339188, lng: 55.293405 },
    { lat: 25.340584, lng: 55.296152 },
    { lat: 25.341592, lng: 55.296581 },
    { lat: 25.342678, lng: 55.29907 },
    { lat: 25.344462, lng: 55.29907 },
    { lat: 25.346206, lng: 55.298126 },
    { lat: 25.347525, lng: 55.296839 },
    { lat: 25.348068, lng: 55.295036 },
    { lat: 25.347912, lng: 55.294178 },
    { lat: 25.354893, lng: 55.292461 },
    { lat: 25.356057, lng: 55.292719 },
    { lat: 25.356755, lng: 55.293749 },
];

export const palm_jumeirah_1 = [
    { lat: 25.127692, lng: 55.121645 },
    { lat: 25.127381, lng: 55.12216 },
    { lat: 25.127303, lng: 55.122675 },
    { lat: 25.127692, lng: 55.123276 },
    { lat: 25.128546, lng: 55.123276 },
    { lat: 25.130023, lng: 55.123448 },
    { lat: 25.130178, lng: 55.124306 },
    { lat: 25.130023, lng: 55.124735 },
    { lat: 25.128857, lng: 55.124907 },
    { lat: 25.128002, lng: 55.124649 },
    { lat: 25.125283, lng: 55.125765 },
    { lat: 25.124816, lng: 55.126194 },
    { lat: 25.124506, lng: 55.12628 },
    { lat: 25.124583, lng: 55.126795 },
    { lat: 25.126526, lng: 55.126538 },
    { lat: 25.131577, lng: 55.126538 },
    { lat: 25.132665, lng: 55.12731 },
    { lat: 25.132506, lng: 55.128086 },
    { lat: 25.13204, lng: 55.128258 },
    { lat: 25.129476, lng: 55.127743 },
    { lat: 25.126212, lng: 55.127657 },
    { lat: 25.124192, lng: 55.127914 },
    { lat: 25.123415, lng: 55.128172 },
    { lat: 25.123182, lng: 55.128343 },
    { lat: 25.123104, lng: 55.128429 },
    { lat: 25.124503, lng: 55.128858 },
    { lat: 25.129554, lng: 55.13006 },
    { lat: 25.13274, lng: 55.131433 },
    { lat: 25.134682, lng: 55.132463 },
    { lat: 25.135226, lng: 55.133322 },
    { lat: 25.135226, lng: 55.134008 },
    { lat: 25.134682, lng: 55.134781 },
    { lat: 25.134371, lng: 55.134781 },
    { lat: 25.133284, lng: 55.134266 },
    { lat: 25.132118, lng: 55.133236 },
    { lat: 25.130486, lng: 55.131691 },
    { lat: 25.13002, lng: 55.131691 },
    { lat: 25.129243, lng: 55.131433 },
    { lat: 25.127378, lng: 55.130403 },
    { lat: 25.12458, lng: 55.129974 },
    { lat: 25.123104, lng: 55.129717 },
    { lat: 25.12186, lng: 55.129545 },
    { lat: 25.122016, lng: 55.13006 },
    { lat: 25.123959, lng: 55.130747 },
    { lat: 25.126678, lng: 55.132034 },
    { lat: 25.129942, lng: 55.134352 },
    { lat: 25.132506, lng: 55.136497 },
    { lat: 25.134255, lng: 55.138428 },
    { lat: 25.135148, lng: 55.139287 },
    { lat: 25.13542, lng: 55.139845 },
    { lat: 25.13511, lng: 55.140531 },
    { lat: 25.134527, lng: 55.14066 },
    { lat: 25.133665, lng: 55.140403 },
    { lat: 25.133393, lng: 55.139845 },
    { lat: 25.132849, lng: 55.138943 },
    { lat: 25.131256, lng: 55.136884 },
    { lat: 25.129741, lng: 55.135467 },
    { lat: 25.129274, lng: 55.135038 },
    { lat: 25.127876, lng: 55.133965 },
    { lat: 25.1257, lng: 55.132764 },
    { lat: 25.12399, lng: 55.132034 },
    { lat: 25.122902, lng: 55.131605 },
    { lat: 25.121814, lng: 55.131219 },
    { lat: 25.121193, lng: 55.130961 },
    { lat: 25.120843, lng: 55.13109 },
    { lat: 25.120843, lng: 55.131476 },
    { lat: 25.121503, lng: 55.131691 },
    { lat: 25.124573, lng: 55.133579 },
    { lat: 25.126594, lng: 55.135253 },
    { lat: 25.128653, lng: 55.137613 },
    { lat: 25.130557, lng: 55.140703 },
    { lat: 25.131683, lng: 55.142677 },
    { lat: 25.132227, lng: 55.14375 },
    { lat: 25.132499, lng: 55.144522 },
    { lat: 25.132266, lng: 55.145209 },
    { lat: 25.131916, lng: 55.145853 },
    { lat: 25.131606, lng: 55.146025 },
    { lat: 25.130984, lng: 55.145724 },
    { lat: 25.130751, lng: 55.144437 },
    { lat: 25.129624, lng: 55.141776 },
    { lat: 25.128536, lng: 55.139716 },
    { lat: 25.127254, lng: 55.137742 },
    { lat: 25.12399, lng: 55.134609 },
    { lat: 25.122708, lng: 55.133536 },
    { lat: 25.121465, lng: 55.132849 },
    { lat: 25.120229, lng: 55.132292 },
    { lat: 25.119957, lng: 55.132292 },
    { lat: 25.120074, lng: 55.132764 },
    { lat: 25.121006, lng: 55.13345 },
    { lat: 25.123454, lng: 55.136068 },
    { lat: 25.126057, lng: 55.139845 },
    { lat: 25.127845, lng: 55.143664 },
    { lat: 25.128505, lng: 55.146411 },
    { lat: 25.128435, lng: 55.147226 },
    { lat: 25.128218, lng: 55.147612 },
    { lat: 25.127984, lng: 55.147569 },
    { lat: 25.127285, lng: 55.14714 },
    { lat: 25.126897, lng: 55.145295 },
    { lat: 25.126042, lng: 55.142463 },
    { lat: 25.123322, lng: 55.137613 },
    { lat: 25.121496, lng: 55.135339 },
    { lat: 25.119421, lng: 55.133536 },
    { lat: 25.11911, lng: 55.133579 },
    { lat: 25.119032, lng: 55.134051 },
    { lat: 25.12148, lng: 55.137313 },
    { lat: 25.123889, lng: 55.143235 },
    { lat: 25.124394, lng: 55.146926 },
    { lat: 25.124278, lng: 55.14787 },
    { lat: 25.123928, lng: 55.147956 },
    { lat: 25.123501, lng: 55.147913 },
    { lat: 25.123337, lng: 55.147698 },
    { lat: 25.123036, lng: 55.146507 },
    { lat: 25.122415, lng: 55.142902 },
    { lat: 25.12121, lng: 55.139383 },
    { lat: 25.119772, lng: 55.136637 },
    { lat: 25.118801, lng: 55.135092 },
    { lat: 25.118335, lng: 55.134577 },
    { lat: 25.118218, lng: 55.134663 },
    { lat: 25.118296, lng: 55.135092 },
    { lat: 25.119384, lng: 55.137667 },
    { lat: 25.120666, lng: 55.14213 },
    { lat: 25.120821, lng: 55.144447 },
    { lat: 25.120627, lng: 55.147408 },
    { lat: 25.120316, lng: 55.147623 },
    { lat: 25.119345, lng: 55.147408 },
    { lat: 25.119112, lng: 55.147065 },
    { lat: 25.119345, lng: 55.146078 },
    { lat: 25.119423, lng: 55.143331 },
    { lat: 25.119384, lng: 55.1414 },
    { lat: 25.118568, lng: 55.138225 },
    { lat: 25.117557, lng: 55.136036 },
    { lat: 25.117285, lng: 55.136293 },
    { lat: 25.117091, lng: 55.13668 },
    { lat: 25.116625, lng: 55.13698 },
    { lat: 25.11678, lng: 55.137667 },
    { lat: 25.11678, lng: 55.138225 },
    { lat: 25.117013, lng: 55.138525 },
    { lat: 25.116975, lng: 55.138868 },
    { lat: 25.116741, lng: 55.139126 },
    { lat: 25.116625, lng: 55.139383 },
    { lat: 25.116625, lng: 55.139769 },
    { lat: 25.116547, lng: 55.14007 },
    { lat: 25.116159, lng: 55.140542 },
    { lat: 25.118024, lng: 55.14213 },
    { lat: 25.118101, lng: 55.14243 },
    { lat: 25.11612, lng: 55.143031 },
    { lat: 25.115964, lng: 55.143246 },
    { lat: 25.114682, lng: 55.142859 },
    { lat: 25.114068, lng: 55.143417 },
    { lat: 25.112941, lng: 55.144533 },
    { lat: 25.112863, lng: 55.145563 },
    { lat: 25.113135, lng: 55.146078 },
    { lat: 25.113485, lng: 55.146507 },
    { lat: 25.114185, lng: 55.147108 },
    { lat: 25.114651, lng: 55.147022 },
    { lat: 25.115389, lng: 55.147323 },
    { lat: 25.115389, lng: 55.147966 },
    { lat: 25.114029, lng: 55.149125 },
    { lat: 25.114029, lng: 55.149383 },
    { lat: 25.11333, lng: 55.149168 },
    { lat: 25.112902, lng: 55.14831 },
    { lat: 25.111853, lng: 55.147323 },
    { lat: 25.111076, lng: 55.147408 },
    { lat: 25.110338, lng: 55.148267 },
    { lat: 25.108783, lng: 55.150198 },
    { lat: 25.107967, lng: 55.151571 },
    { lat: 25.107734, lng: 55.151829 },
    { lat: 25.107579, lng: 55.152601 },
    { lat: 25.107812, lng: 55.152859 },
    { lat: 25.107656, lng: 55.153116 },
    { lat: 25.106918, lng: 55.152987 },
    { lat: 25.106102, lng: 55.152859 },
    { lat: 25.105363, lng: 55.152902 },
    { lat: 25.104237, lng: 55.152301 },
    { lat: 25.103172, lng: 55.151245 },
    { lat: 25.102861, lng: 55.150215 },
    { lat: 25.102084, lng: 55.148498 },
    { lat: 25.10255, lng: 55.148241 },
    { lat: 25.106047, lng: 55.143864 },
    { lat: 25.108457, lng: 55.140516 },
    { lat: 25.108923, lng: 55.1388 },
    { lat: 25.109436, lng: 55.13801 },
    { lat: 25.109475, lng: 55.137495 },
    { lat: 25.109902, lng: 55.135735 },
    { lat: 25.109786, lng: 55.135349 },
    { lat: 25.109786, lng: 55.135135 },
    { lat: 25.110136, lng: 55.135092 },
    { lat: 25.111806, lng: 55.136722 },
    { lat: 25.112234, lng: 55.136894 },
    { lat: 25.112351, lng: 55.136336 },
    { lat: 25.112661, lng: 55.136079 },
    { lat: 25.113244, lng: 55.13595 },
    { lat: 25.113672, lng: 55.135392 },
    { lat: 25.114293, lng: 55.135521 },
    { lat: 25.115086, lng: 55.135435 },
    { lat: 25.115319, lng: 55.134963 },
    { lat: 25.115747, lng: 55.134834 },
    { lat: 25.115929, lng: 55.134693 },
    { lat: 25.115929, lng: 55.134263 },
    { lat: 25.112976, lng: 55.133448 },
    { lat: 25.11045, lng: 55.133448 },
    { lat: 25.106409, lng: 55.134178 },
    { lat: 25.105398, lng: 55.134306 },
    { lat: 25.104971, lng: 55.133963 },
    { lat: 25.104854, lng: 55.13362 },
    { lat: 25.105126, lng: 55.133019 },
    { lat: 25.106681, lng: 55.132633 },
    { lat: 25.111033, lng: 55.132246 },
    { lat: 25.114569, lng: 55.132847 },
    { lat: 25.116629, lng: 55.133448 },
    { lat: 25.116862, lng: 55.133577 },
    { lat: 25.116784, lng: 55.133319 },
    { lat: 25.115891, lng: 55.132547 },
    { lat: 25.111266, lng: 55.131002 },
    { lat: 25.107225, lng: 55.130487 },
    { lat: 25.104233, lng: 55.130401 },
    { lat: 25.103805, lng: 55.129886 },
    { lat: 25.103922, lng: 55.129071 },
    { lat: 25.10431, lng: 55.128942 },
    { lat: 25.105709, lng: 55.129028 },
    { lat: 25.110528, lng: 55.129629 },
    { lat: 25.114725, lng: 55.13083 },
    { lat: 25.116784, lng: 55.132032 },
    { lat: 25.117367, lng: 55.132332 },
    { lat: 25.117484, lng: 55.132319 },
    { lat: 25.117795, lng: 55.132148 },
    { lat: 25.117056, lng: 55.131118 },
    { lat: 25.114608, lng: 55.129315 },
    { lat: 25.110528, lng: 55.126998 },
    { lat: 25.106525, lng: 55.125968 },
    { lat: 25.103961, lng: 55.12571 },
    { lat: 25.10365, lng: 55.125496 },
    { lat: 25.103533, lng: 55.125153 },
    { lat: 25.103766, lng: 55.124638 },
    { lat: 25.104777, lng: 55.124423 },
    { lat: 25.107108, lng: 55.124723 },
    { lat: 25.11045, lng: 55.125839 },
    { lat: 25.113442, lng: 55.127212 },
    { lat: 25.115657, lng: 55.1288 },
    { lat: 25.117056, lng: 55.130131 },
    { lat: 25.117989, lng: 55.130946 },
    { lat: 25.118377, lng: 55.131332 },
    { lat: 25.118494, lng: 55.131075 },
    { lat: 25.118727, lng: 55.130946 },
    { lat: 25.117678, lng: 55.129187 },
    { lat: 25.11488, lng: 55.126225 },
    { lat: 25.11216, lng: 55.124294 },
    { lat: 25.109129, lng: 55.122663 },
    { lat: 25.104893, lng: 55.121548 },
    { lat: 25.104271, lng: 55.120861 },
    { lat: 25.104816, lng: 55.120303 },
    { lat: 25.106525, lng: 55.120303 },
    { lat: 25.109129, lng: 55.121118 },
    { lat: 25.110683, lng: 55.121805 },
    { lat: 25.114422, lng: 55.12414 },
    { lat: 25.116598, lng: 55.126285 },
    { lat: 25.118844, lng: 55.129444 },
    { lat: 25.11931, lng: 55.129959 },
    { lat: 25.119582, lng: 55.129959 },
    { lat: 25.119543, lng: 55.129444 },
    { lat: 25.118455, lng: 55.126869 },
    { lat: 25.11659, lng: 55.123865 },
    { lat: 25.111546, lng: 55.119162 },
    { lat: 25.109681, lng: 55.118303 },
    { lat: 25.108624, lng: 55.117556 },
    { lat: 25.10843, lng: 55.11717 },
    { lat: 25.108391, lng: 55.116355 },
    { lat: 25.10909, lng: 55.115668 },
    { lat: 25.109751, lng: 55.115668 },
    { lat: 25.111227, lng: 55.117042 },
    { lat: 25.114958, lng: 55.120131 },
    { lat: 25.119038, lng: 55.126054 },
    { lat: 25.120087, lng: 55.128371 },
    { lat: 25.120437, lng: 55.128757 },
    { lat: 25.12067, lng: 55.128586 },
    { lat: 25.120592, lng: 55.127685 },
    { lat: 25.119854, lng: 55.125281 },
    { lat: 25.118416, lng: 55.121591 },
    { lat: 25.116512, lng: 55.118586 },
    { lat: 25.11453, lng: 55.116956 },
    { lat: 25.113986, lng: 55.116527 },
    { lat: 25.114142, lng: 55.11584 },
    { lat: 25.114391, lng: 55.115411 },
    { lat: 25.115129, lng: 55.115153 },
    { lat: 25.115867, lng: 55.115754 },
    { lat: 25.117732, lng: 55.118501 },
    { lat: 25.118976, lng: 55.120689 },
    { lat: 25.120802, lng: 55.124938 },
    { lat: 25.121307, lng: 55.126826 },
    { lat: 25.121346, lng: 55.127255 },
    { lat: 25.121773, lng: 55.126998 },
    { lat: 25.12189, lng: 55.126054 },
    { lat: 25.121424, lng: 55.122363 },
    { lat: 25.120724, lng: 55.119273 },
    { lat: 25.119908, lng: 55.117385 },
    { lat: 25.120064, lng: 55.117042 },
    { lat: 25.120413, lng: 55.116612 },
    { lat: 25.120919, lng: 55.116698 },
    { lat: 25.121463, lng: 55.117385 },
    { lat: 25.122007, lng: 55.119788 },
    { lat: 25.122551, lng: 55.122878 },
    { lat: 25.122784, lng: 55.124595 },
    { lat: 25.122784, lng: 55.12511 },
    { lat: 25.122784, lng: 55.125453 },
    { lat: 25.123172, lng: 55.125153 },
    { lat: 25.123483, lng: 55.123951 },
    { lat: 25.123833, lng: 55.119702 },
    { lat: 25.123677, lng: 55.119531 },
    { lat: 25.123755, lng: 55.119059 },
    { lat: 25.12426, lng: 55.118758 },
    { lat: 25.124804, lng: 55.11893 },
    { lat: 25.125037, lng: 55.120346 },
    { lat: 25.125037, lng: 55.120818 },
    { lat: 25.125193, lng: 55.121247 },
    { lat: 25.125659, lng: 55.121419 },
    { lat: 25.126009, lng: 55.12129 },
    { lat: 25.126475, lng: 55.120904 },
    { lat: 25.126902, lng: 55.120732 },
    { lat: 25.127563, lng: 55.121161 },
    { lat: 25.127718, lng: 55.121376 },
    { lat: 25.127835, lng: 55.121505 },
];

export const palm_jumeirah_2 = [
    { lat: 25.127936, lng: 55.120149 },
    { lat: 25.127936, lng: 55.119548 },
    { lat: 25.126926, lng: 55.117402 },
    { lat: 25.125993, lng: 55.115342 },
    { lat: 25.126926, lng: 55.115771 },
    { lat: 25.128946, lng: 55.117745 },
    { lat: 25.131433, lng: 55.119805 },
    { lat: 25.132987, lng: 55.121179 },
    { lat: 25.133375, lng: 55.122638 },
    { lat: 25.133608, lng: 55.123324 },
    { lat: 25.134852, lng: 55.125213 },
    { lat: 25.135629, lng: 55.126071 },
    { lat: 25.136095, lng: 55.126243 },
    { lat: 25.136483, lng: 55.128783 },
    { lat: 25.137183, lng: 55.129298 },
    { lat: 25.138271, lng: 55.130843 },
    { lat: 25.138581, lng: 55.133161 },
    { lat: 25.139048, lng: 55.135993 },
    { lat: 25.139203, lng: 55.138568 },
    { lat: 25.138659, lng: 55.141658 },
    { lat: 25.13796, lng: 55.143203 },
    { lat: 25.13726, lng: 55.14552 },
    { lat: 25.136561, lng: 55.14655 },
    { lat: 25.137649, lng: 55.14801 },
    { lat: 25.137649, lng: 55.14861 },
    { lat: 25.137027, lng: 55.149383 },
    { lat: 25.135473, lng: 55.14801 },
    { lat: 25.134852, lng: 55.148696 },
    { lat: 25.133531, lng: 55.149383 },
    { lat: 25.129568, lng: 55.1517 },
    { lat: 25.125061, lng: 55.152387 },
    { lat: 25.123273, lng: 55.152129 },
    { lat: 25.12203, lng: 55.151872 },
    { lat: 25.120553, lng: 55.151872 },
    { lat: 25.120009, lng: 55.152816 },
    { lat: 25.119854, lng: 55.153846 },
    { lat: 25.119621, lng: 55.154189 },
    { lat: 25.118921, lng: 55.153503 },
    { lat: 25.119077, lng: 55.152473 },
    { lat: 25.118921, lng: 55.152473 },
    { lat: 25.118533, lng: 55.152988 },
    { lat: 25.118688, lng: 55.153674 },
    { lat: 25.119232, lng: 55.154275 },
    { lat: 25.120009, lng: 55.154876 },
    { lat: 25.122807, lng: 55.154876 },
    { lat: 25.12949, lng: 55.154533 },
    { lat: 25.130966, lng: 55.153932 },
    { lat: 25.135784, lng: 55.151529 },
    { lat: 25.13695, lng: 55.150155 },
    { lat: 25.137105, lng: 55.149812 },
    { lat: 25.138896, lng: 55.147572 },
    { lat: 25.140761, lng: 55.143194 },
    { lat: 25.140839, lng: 55.142765 },
    { lat: 25.141616, lng: 55.14019 },
    { lat: 25.141616, lng: 55.138731 },
    { lat: 25.141616, lng: 55.13607 },
    { lat: 25.14115, lng: 55.131092 },
    { lat: 25.139362, lng: 55.126972 },
    { lat: 25.135555, lng: 55.120192 },
    { lat: 25.131748, lng: 55.115471 },
    { lat: 25.12592, lng: 55.111437 },
    { lat: 25.119159, lng: 55.108776 },
    { lat: 25.115661, lng: 55.108347 },
    { lat: 25.112211, lng: 55.108433 },
    { lat: 25.109009, lng: 55.108948 },
    { lat: 25.104812, lng: 55.110836 },
    { lat: 25.103413, lng: 55.111866 },
    { lat: 25.100724, lng: 55.114441 },
    { lat: 25.098936, lng: 55.117359 },
    { lat: 25.097226, lng: 55.123711 },
    { lat: 25.097148, lng: 55.12826 },
    { lat: 25.098314, lng: 55.134096 },
    { lat: 25.099014, lng: 55.135899 },
    { lat: 25.099325, lng: 55.136414 },
    { lat: 25.099566, lng: 55.136568 },
    { lat: 25.100071, lng: 55.136611 },
    { lat: 25.100654, lng: 55.136482 },
    { lat: 25.101003, lng: 55.136096 },
    { lat: 25.101003, lng: 55.135839 },
    { lat: 25.100871, lng: 55.135813 },
    { lat: 25.100599, lng: 55.136156 },
    { lat: 25.099744, lng: 55.136199 },
    { lat: 25.099511, lng: 55.136114 },
    { lat: 25.099278, lng: 55.135813 },
    { lat: 25.099122, lng: 55.135212 },
    { lat: 25.099433, lng: 55.134869 },
    { lat: 25.100211, lng: 55.13444 },
    { lat: 25.10056, lng: 55.133582 },
    { lat: 25.100638, lng: 55.132852 },
    { lat: 25.100133, lng: 55.130921 },
    { lat: 25.099589, lng: 55.127917 },
    { lat: 25.099822, lng: 55.124097 },
    { lat: 25.100016, lng: 55.122037 },
    { lat: 25.100529, lng: 55.120321 },
    { lat: 25.102045, lng: 55.117274 },
    { lat: 25.102939, lng: 55.115514 },
    { lat: 25.103211, lng: 55.115343 },
    { lat: 25.103211, lng: 55.114956 },
    { lat: 25.102667, lng: 55.114699 },
    { lat: 25.101889, lng: 55.114441 },
    { lat: 25.103001, lng: 55.113085 },
    { lat: 25.105099, lng: 55.1136 },
    { lat: 25.106965, lng: 55.112914 },
    { lat: 25.11225, lng: 55.110854 },
    { lat: 25.117224, lng: 55.111111 },
    { lat: 25.120721, lng: 55.112141 },
    { lat: 25.124062, lng: 55.112999 },
    { lat: 25.124995, lng: 55.114287 },
    { lat: 25.125306, lng: 55.116347 },
    { lat: 25.126238, lng: 55.118063 },
    { lat: 25.127171, lng: 55.119694 },
    { lat: 25.127248, lng: 55.120467 },
];

export const palm_jebel_ali_1 = [
    { lat: 25.036343, lng: 54.989657 },
    { lat: 25.036421, lng: 54.990258 },
    { lat: 25.036576, lng: 54.993691 },
    { lat: 25.035177, lng: 55.002704 },
    { lat: 25.033388, lng: 55.00854 },
    { lat: 25.030744, lng: 55.013003 },
    { lat: 25.024755, lng: 55.019183 },
    { lat: 25.023666, lng: 55.018411 },
    { lat: 25.021878, lng: 55.019526 },
    { lat: 25.022033, lng: 55.020814 },
    { lat: 25.019856, lng: 55.021758 },
    { lat: 25.019233, lng: 55.022101 },
    { lat: 25.018331, lng: 55.022616 },
    { lat: 25.011486, lng: 55.023732 },
    { lat: 25.008375, lng: 55.023646 },
    { lat: 25.003552, lng: 55.022702 },
    { lat: 24.997796, lng: 55.020213 },
    { lat: 24.995462, lng: 55.018754 },
    { lat: 24.99554, lng: 55.017981 },
    { lat: 24.99554, lng: 55.01678 },
    { lat: 24.992195, lng: 55.015321 },
    { lat: 24.989923, lng: 55.01472 },
    { lat: 24.988056, lng: 55.01472 },
    { lat: 24.9858, lng: 55.015406 },
    { lat: 24.985178, lng: 55.016093 },
    { lat: 24.984587, lng: 55.014291 },
    { lat: 24.985209, lng: 55.014119 },
    { lat: 24.985365, lng: 55.012832 },
    { lat: 24.98801, lng: 55.011973 },
    { lat: 24.991433, lng: 55.012402 },
    { lat: 24.992677, lng: 55.01266 },
    { lat: 24.992911, lng: 55.013089 },
    { lat: 24.992911, lng: 55.01369 },
    { lat: 24.996178, lng: 55.01575 },
    { lat: 24.997189, lng: 55.014462 },
    { lat: 24.997423, lng: 55.014376 },
    { lat: 24.997423, lng: 55.015149 },
    { lat: 24.998216, lng: 55.015836 },
    { lat: 25.001639, lng: 55.017466 },
    { lat: 25.005373, lng: 55.019011 },
    { lat: 25.00794, lng: 55.019526 },
    { lat: 25.010973, lng: 55.019698 },
    { lat: 25.014115, lng: 55.019526 },
    { lat: 25.017071, lng: 55.01884 },
    { lat: 25.019871, lng: 55.017724 },
    { lat: 25.020182, lng: 55.017724 },
    { lat: 25.019949, lng: 55.017123 },
    { lat: 25.019871, lng: 55.017037 },
    { lat: 25.02096, lng: 55.018153 },
    { lat: 25.022982, lng: 55.017295 },
    { lat: 25.022204, lng: 55.015406 },
    { lat: 25.022982, lng: 55.015492 },
    { lat: 25.024849, lng: 55.014119 },
    { lat: 25.028426, lng: 55.009656 },
    { lat: 25.030137, lng: 55.006738 },
    { lat: 25.031693, lng: 55.002704 },
    { lat: 25.032704, lng: 54.997811 },
    { lat: 25.032937, lng: 54.993176 },
    { lat: 25.032937, lng: 54.990687 },
    { lat: 25.032626, lng: 54.988971 },
    { lat: 25.032159, lng: 54.988885 },
    { lat: 25.036281, lng: 54.989486 },
];

export const palm_jebel_ali_2 = [
    { lat: 25.035736, lng: 54.986396 },
    { lat: 25.035814, lng: 54.985881 },
    { lat: 25.035892, lng: 54.985366 },
    { lat: 25.035736, lng: 54.984507 },
    { lat: 25.036514, lng: 54.980044 },
    { lat: 25.036359, lng: 54.977641 },
    { lat: 25.035736, lng: 54.974208 },
    { lat: 25.033948, lng: 54.970002 },
    { lat: 25.031381, lng: 54.966655 },
    { lat: 25.027726, lng: 54.963822 },
    { lat: 25.024849, lng: 54.962621 },
    { lat: 25.024226, lng: 54.962106 },
    { lat: 25.020182, lng: 54.958587 },
    { lat: 25.017149, lng: 54.956698 },
    { lat: 25.011082, lng: 54.953694 },
    { lat: 25.004937, lng: 54.952407 },
    { lat: 24.998621, lng: 54.952578 },
    { lat: 24.992709, lng: 54.954552 },
    { lat: 24.992864, lng: 54.955582 },
    { lat: 24.992864, lng: 54.956097 },
    { lat: 24.990873, lng: 54.956527 },
    { lat: 24.990561, lng: 54.956527 },
    { lat: 24.989939, lng: 54.956269 },
    { lat: 24.987761, lng: 54.957728 },
    { lat: 24.985894, lng: 54.959102 },
    { lat: 24.984026, lng: 54.961247 },
    { lat: 24.98177, lng: 54.964595 },
    { lat: 24.980525, lng: 54.96674 },
    { lat: 24.980136, lng: 54.967856 },
    { lat: 24.979747, lng: 54.968028 },
    { lat: 24.97967, lng: 54.9688 },
    { lat: 24.978736, lng: 54.971375 },
    { lat: 24.978347, lng: 54.972491 },
    { lat: 24.978082, lng: 54.972749 },
    { lat: 24.977616, lng: 54.972749 },
    { lat: 24.976838, lng: 54.972491 },
    { lat: 24.976449, lng: 54.972491 },
    { lat: 24.976137, lng: 54.97292 },
    { lat: 24.976137, lng: 54.973178 },
    { lat: 24.977616, lng: 54.973864 },
    { lat: 24.977927, lng: 54.974465 },
    { lat: 24.977382, lng: 54.977813 },
    { lat: 24.976915, lng: 54.980388 },
    { lat: 24.977149, lng: 54.980817 },
    { lat: 24.976915, lng: 54.98425 },
    { lat: 24.975048, lng: 54.986567 },
    { lat: 24.974192, lng: 54.987426 },
    { lat: 24.97178, lng: 54.987168 },
    { lat: 24.970924, lng: 54.986653 },
    { lat: 24.973725, lng: 54.992061 },
    { lat: 24.974037, lng: 54.991717 },
    { lat: 24.976215, lng: 54.990001 },
    { lat: 24.978005, lng: 54.988198 },
    { lat: 24.97816, lng: 54.98734 },
    { lat: 24.977693, lng: 54.986567 },
    { lat: 24.977693, lng: 54.985709 },
    { lat: 24.978238, lng: 54.984336 },
    { lat: 24.979327, lng: 54.98219 },
    { lat: 24.981739, lng: 54.982276 },
    { lat: 24.981117, lng: 54.98116 },
    { lat: 24.981023, lng: 54.979443 },
    { lat: 24.982035, lng: 54.97395 },
    { lat: 24.983202, lng: 54.970088 },
    { lat: 24.986236, lng: 54.964938 },
    { lat: 24.990748, lng: 54.960131 },
    { lat: 24.991215, lng: 54.959788 },
    { lat: 24.991526, lng: 54.959702 },
    { lat: 24.991915, lng: 54.959702 },
    { lat: 24.992148, lng: 54.960131 },
    { lat: 24.991682, lng: 54.958844 },
    { lat: 24.991682, lng: 54.958157 },
    { lat: 24.993549, lng: 54.957385 },
    { lat: 24.994093, lng: 54.958844 },
    { lat: 24.994716, lng: 54.958157 },
    { lat: 24.996692, lng: 54.957213 },
    { lat: 25.000425, lng: 54.956355 },
    { lat: 25.003926, lng: 54.956441 },
    { lat: 25.008749, lng: 54.957042 },
    { lat: 25.014115, lng: 54.959102 },
    { lat: 25.016449, lng: 54.960904 },
    { lat: 25.017227, lng: 54.961505 },
    { lat: 25.018704, lng: 54.962277 },
    { lat: 25.019716, lng: 54.962449 },
    { lat: 25.020416, lng: 54.962277 },
    { lat: 25.022049, lng: 54.963307 },
    { lat: 25.023838, lng: 54.966569 },
    { lat: 25.024771, lng: 54.968028 },
    { lat: 25.025004, lng: 54.96983 },
    { lat: 25.025004, lng: 54.970603 },
    { lat: 25.027104, lng: 54.972749 },
    { lat: 25.029126, lng: 54.976697 },
    { lat: 25.030122, lng: 54.980079 },
    { lat: 25.029966, lng: 54.980765 },
    { lat: 25.032455, lng: 54.983512 },
    { lat: 25.033232, lng: 54.984885 },
    { lat: 25.033232, lng: 54.985572 },
    { lat: 25.033232, lng: 54.986258 },
    { lat: 25.035721, lng: 54.986602 },
];

export const palm_jebel_ali_3 = [
    { lat: 25.027026, lng: 54.994859 },
    { lat: 25.026404, lng: 54.993485 },
    { lat: 25.023838, lng: 54.989709 },
    { lat: 25.021349, lng: 54.987992 },
    { lat: 25.018393, lng: 54.985846 },
    { lat: 25.016216, lng: 54.985417 },
    { lat: 25.016371, lng: 54.984645 },
    { lat: 25.017071, lng: 54.984301 },
    { lat: 25.019171, lng: 54.984902 },
    { lat: 25.02096, lng: 54.985589 },
    { lat: 25.021427, lng: 54.98516 },
    { lat: 25.02166, lng: 54.984559 },
    { lat: 25.020338, lng: 54.983443 },
    { lat: 25.018938, lng: 54.982327 },
    { lat: 25.018471, lng: 54.982241 },
    { lat: 25.019093, lng: 54.980782 },
    { lat: 25.019093, lng: 54.980525 },
    { lat: 25.019093, lng: 54.980182 },
    { lat: 25.018004, lng: 54.980439 },
    { lat: 25.017693, lng: 54.980525 },
    { lat: 25.017382, lng: 54.979409 },
    { lat: 25.018082, lng: 54.976748 },
    { lat: 25.018627, lng: 54.9728 },
    { lat: 25.018316, lng: 54.97074 },
    { lat: 25.018004, lng: 54.969796 },
    { lat: 25.017304, lng: 54.969453 },
    { lat: 25.016604, lng: 54.970139 },
    { lat: 25.01676, lng: 54.971169 },
    { lat: 25.016527, lng: 54.977006 },
    { lat: 25.015749, lng: 54.979581 },
    { lat: 25.015049, lng: 54.980782 },
    { lat: 25.014893, lng: 54.981126 },
    { lat: 25.01466, lng: 54.981212 },
    { lat: 25.01396, lng: 54.98104 },
    { lat: 25.014349, lng: 54.978894 },
    { lat: 25.014738, lng: 54.975289 },
    { lat: 25.01466, lng: 54.972628 },
    { lat: 25.013804, lng: 54.968509 },
    { lat: 25.012638, lng: 54.965934 },
    { lat: 25.012093, lng: 54.965848 },
    { lat: 25.011471, lng: 54.966363 },
    { lat: 25.011315, lng: 54.966792 },
    { lat: 25.012638, lng: 54.970139 },
    { lat: 25.013338, lng: 54.973229 },
    { lat: 25.013649, lng: 54.977006 },
    { lat: 25.013415, lng: 54.979409 },
    { lat: 25.012482, lng: 54.981726 },
    { lat: 25.012404, lng: 54.98207 },
    { lat: 25.011782, lng: 54.98207 },
    { lat: 25.01116, lng: 54.98207 },
    { lat: 25.011082, lng: 54.978722 },
    { lat: 25.010226, lng: 54.97383 },
    { lat: 25.009293, lng: 54.970912 },
    { lat: 25.007271, lng: 54.966878 },
    { lat: 25.005093, lng: 54.964646 },
    { lat: 25.004315, lng: 54.964045 },
    { lat: 25.003693, lng: 54.964475 },
    { lat: 25.003459, lng: 54.965161 },
    { lat: 25.005248, lng: 54.967135 },
    { lat: 25.008515, lng: 54.972028 },
    { lat: 25.009604, lng: 54.975633 },
    { lat: 25.010226, lng: 54.98001 },
    { lat: 25.009993, lng: 54.983186 },
    { lat: 25.009915, lng: 54.983357 },
    { lat: 25.00976, lng: 54.983443 },
    { lat: 25.009215, lng: 54.983615 },
    { lat: 25.00836, lng: 54.979066 },
    { lat: 25.005715, lng: 54.973229 },
    { lat: 25.002915, lng: 54.968852 },
    { lat: 24.999103, lng: 54.965504 },
    { lat: 24.998247, lng: 54.965161 },
    { lat: 24.997547, lng: 54.965676 },
    { lat: 24.997469, lng: 54.966449 },
    { lat: 24.998947, lng: 54.967736 },
    { lat: 25.001981, lng: 54.970225 },
    { lat: 25.004081, lng: 54.972371 },
    { lat: 25.006493, lng: 54.976748 },
    { lat: 25.007426, lng: 54.980525 },
    { lat: 25.007971, lng: 54.984473 },
    { lat: 25.007815, lng: 54.984731 },
    { lat: 25.007426, lng: 54.984902 },
    { lat: 25.005637, lng: 54.980782 },
    { lat: 25.002837, lng: 54.976319 },
    { lat: 24.999476, lng: 54.973229 },
    { lat: 24.995742, lng: 54.970311 },
    { lat: 24.992942, lng: 54.969367 },
    { lat: 24.992242, lng: 54.969453 },
    { lat: 24.991697, lng: 54.969882 },
    { lat: 24.991853, lng: 54.97074 },
    { lat: 24.996209, lng: 54.972714 },
    { lat: 24.999087, lng: 54.974259 },
    { lat: 25.001577, lng: 54.97692 },
    { lat: 25.003677, lng: 54.979323 },
    { lat: 25.005544, lng: 54.983529 },
    { lat: 25.006244, lng: 54.984816 },
    { lat: 25.006244, lng: 54.985417 },
    { lat: 25.005155, lng: 54.985846 },
    { lat: 25.001343, lng: 54.980182 },
    { lat: 24.996443, lng: 54.976233 },
    { lat: 24.992709, lng: 54.974173 },
    { lat: 24.989752, lng: 54.973573 },
    { lat: 24.98913, lng: 54.973658 },
    { lat: 24.988819, lng: 54.974603 },
    { lat: 24.989208, lng: 54.975289 },
    { lat: 24.992242, lng: 54.97589 },
    { lat: 24.99652, lng: 54.977692 },
    { lat: 25.00111, lng: 54.981812 },
    { lat: 25.003288, lng: 54.985246 },
    { lat: 25.00391, lng: 54.986018 },
    { lat: 25.004066, lng: 54.986619 },
    { lat: 25.003599, lng: 54.987134 },
    { lat: 25.003288, lng: 54.987134 },
    { lat: 24.999399, lng: 54.983186 },
    { lat: 24.994576, lng: 54.980439 },
    { lat: 24.98885, lng: 54.978808 },
    { lat: 24.987372, lng: 54.97898 },
    { lat: 24.987029, lng: 54.979752 },
    { lat: 24.98773, lng: 54.980439 },
    { lat: 24.990919, lng: 54.980782 },
    { lat: 24.994047, lng: 54.981555 },
    { lat: 24.995991, lng: 54.982413 },
    { lat: 25.000425, lng: 54.985846 },
    { lat: 25.002137, lng: 54.987649 },
    { lat: 25.00237, lng: 54.987992 },
    { lat: 25.00167, lng: 54.988335 },
    { lat: 24.999181, lng: 54.986705 },
    { lat: 24.993735, lng: 54.984387 },
    { lat: 24.989223, lng: 54.98413 },
    { lat: 24.987201, lng: 54.984559 },
    { lat: 24.986578, lng: 54.984988 },
    { lat: 24.986407, lng: 54.985589 },
    { lat: 24.98664, lng: 54.986276 },
    { lat: 24.987574, lng: 54.986361 },
    { lat: 24.988897, lng: 54.985932 },
    { lat: 24.994638, lng: 54.985932 },
    { lat: 24.996894, lng: 54.986791 },
    { lat: 25.000083, lng: 54.988765 },
    { lat: 25.000783, lng: 54.989451 },
    { lat: 25.001172, lng: 54.990395 },
    { lat: 25.00055, lng: 54.990739 },
    { lat: 25.000083, lng: 54.99091 },
    { lat: 24.999461, lng: 54.990052 },
    { lat: 24.999072, lng: 54.98988 },
    { lat: 24.998683, lng: 54.990138 },
    { lat: 24.997749, lng: 54.990653 },
    { lat: 24.996349, lng: 54.99134 },
    { lat: 24.98608, lng: 54.99709 },
    { lat: 24.981646, lng: 55.000008 },
    { lat: 24.980557, lng: 54.999665 },
    { lat: 24.980323, lng: 55.001639 },
    { lat: 24.979234, lng: 55.002583 },
    { lat: 24.979934, lng: 55.004643 },
    { lat: 24.981101, lng: 55.004042 },
    { lat: 24.98328, lng: 55.005845 },
    { lat: 24.984447, lng: 55.004729 },
    { lat: 24.999694, lng: 54.995202 },
    { lat: 25.000083, lng: 54.995116 },
    { lat: 25.001017, lng: 54.994086 },
    { lat: 25.001561, lng: 54.993914 },
    { lat: 25.00055, lng: 54.992455 },
    { lat: 25.001717, lng: 54.991769 },
    { lat: 25.002572, lng: 54.993056 },
    { lat: 25.00195, lng: 54.999408 },
    { lat: 25.000705, lng: 55.002154 },
    { lat: 24.997516, lng: 55.006532 },
    { lat: 24.997283, lng: 55.007047 },
    { lat: 24.997796, lng: 55.00842 },
    { lat: 24.998652, lng: 55.008248 },
    { lat: 25.000674, lng: 55.00533 },
    { lat: 25.002619, lng: 55.001983 },
    { lat: 25.003786, lng: 54.99709 },
    { lat: 25.003786, lng: 54.994258 },
    { lat: 25.00363, lng: 54.99297 },
    { lat: 25.004641, lng: 54.99237 },
    { lat: 25.005264, lng: 54.996747 },
    { lat: 25.005342, lng: 54.999322 },
    { lat: 25.004641, lng: 55.002927 },
    { lat: 25.004253, lng: 55.004128 },
    { lat: 25.004097, lng: 55.0043 },
    { lat: 25.003086, lng: 55.00739 },
    { lat: 25.001997, lng: 55.009621 },
    { lat: 25.002386, lng: 55.010995 },
    { lat: 25.003164, lng: 55.011081 },
    { lat: 25.005497, lng: 55.005673 },
    { lat: 25.005808, lng: 55.004815 },
    { lat: 25.005886, lng: 55.003527 },
    { lat: 25.006415, lng: 54.996833 },
    { lat: 25.005871, lng: 54.992198 },
    { lat: 25.006182, lng: 54.991597 },
    { lat: 25.006804, lng: 54.991425 },
    { lat: 25.007737, lng: 54.993914 },
    { lat: 25.008904, lng: 54.998893 },
    { lat: 25.008904, lng: 55.003442 },
    { lat: 25.00836, lng: 55.00533 },
    { lat: 25.008515, lng: 55.006102 },
    { lat: 25.00696, lng: 55.011596 },
    { lat: 25.007971, lng: 55.012626 },
    { lat: 25.008515, lng: 55.012025 },
    { lat: 25.009293, lng: 55.009793 },
    { lat: 25.010164, lng: 55.004987 },
    { lat: 25.01032, lng: 55.003785 },
    { lat: 25.010086, lng: 54.998978 },
    { lat: 25.007986, lng: 54.99134 },
    { lat: 25.007908, lng: 54.990825 },
    { lat: 25.008064, lng: 54.990224 },
    { lat: 25.010009, lng: 54.992455 },
    { lat: 25.011409, lng: 54.995459 },
    { lat: 25.012731, lng: 55.000094 },
    { lat: 25.01312, lng: 55.003356 },
    { lat: 25.012575, lng: 55.005416 },
    { lat: 25.01172, lng: 55.010823 },
    { lat: 25.012498, lng: 55.011767 },
    { lat: 25.013431, lng: 55.011081 },
    { lat: 25.01452, lng: 55.005244 },
    { lat: 25.01452, lng: 55.002755 },
    { lat: 25.013898, lng: 54.999064 },
    { lat: 25.012887, lng: 54.995374 },
    { lat: 25.009853, lng: 54.990138 },
    { lat: 25.00962, lng: 54.989795 },
    { lat: 25.009853, lng: 54.989022 },
    { lat: 25.011098, lng: 54.989709 },
    { lat: 25.015764, lng: 54.995803 },
    { lat: 25.017553, lng: 55.001382 },
    { lat: 25.018098, lng: 55.007733 },
    { lat: 25.018876, lng: 55.008763 },
    { lat: 25.019887, lng: 55.008592 },
    { lat: 25.019498, lng: 55.001639 },
    { lat: 25.017631, lng: 54.996661 },
    { lat: 25.013587, lng: 54.99031 },
    { lat: 25.011564, lng: 54.988679 },
    { lat: 25.011953, lng: 54.98782 },
    { lat: 25.013898, lng: 54.988679 },
    { lat: 25.016931, lng: 54.991082 },
    { lat: 25.019653, lng: 54.995545 },
    { lat: 25.021131, lng: 54.998206 },
    { lat: 25.022298, lng: 55.003098 },
    { lat: 25.022609, lng: 55.003699 },
    { lat: 25.023853, lng: 55.003871 },
    { lat: 25.024164, lng: 55.003442 },
    { lat: 25.023542, lng: 55.000609 },
    { lat: 25.021753, lng: 54.995803 },
    { lat: 25.018798, lng: 54.991082 },
    { lat: 25.016153, lng: 54.988679 },
    { lat: 25.014442, lng: 54.98722 },
    { lat: 25.014131, lng: 54.987134 },
    { lat: 25.014442, lng: 54.986705 },
    { lat: 25.014987, lng: 54.986361 },
    { lat: 25.017398, lng: 54.98722 },
    { lat: 25.020198, lng: 54.989194 },
    { lat: 25.024009, lng: 54.992884 },
    { lat: 25.025486, lng: 54.995459 },
    { lat: 25.026264, lng: 54.995545 },
    { lat: 25.02712, lng: 54.995116 },
];

export const the_gardens = [
    { lat: 25.052781, lng: 55.132506 },
    { lat: 25.051381, lng: 55.133536 },
    { lat: 25.045472, lng: 55.141432 },
    { lat: 25.041288, lng: 55.146668 },
    { lat: 25.038333, lng: 55.150101 },
    { lat: 25.032267, lng: 55.157397 },
    { lat: 25.029001, lng: 55.161946 },
    { lat: 25.020586, lng: 55.154822 },
    { lat: 25.019341, lng: 55.146496 },
    { lat: 25.018253, lng: 55.144865 },
    { lat: 25.01763, lng: 55.141175 },
    { lat: 25.017319, lng: 55.137913 },
    { lat: 25.017319, lng: 55.132935 },
    { lat: 25.01763, lng: 55.123236 },
    { lat: 25.017941, lng: 55.121176 },
    { lat: 25.022375, lng: 55.121262 },
    { lat: 25.024319, lng: 55.122893 },
    { lat: 25.031863, lng: 55.132592 },
    { lat: 25.034196, lng: 55.136626 },
    { lat: 25.036451, lng: 55.137227 },
    { lat: 25.038473, lng: 55.136368 },
    { lat: 25.040853, lng: 55.137999 },
    { lat: 25.041941, lng: 55.136797 },
    { lat: 25.038675, lng: 55.133965 },
    { lat: 25.03782, lng: 55.131991 },
    { lat: 25.038209, lng: 55.129673 },
    { lat: 25.038831, lng: 55.1283 },
    { lat: 25.037742, lng: 55.124009 },
    { lat: 25.038286, lng: 55.122979 },
    { lat: 25.040464, lng: 55.120747 },
    { lat: 25.040697, lng: 55.12006 },
    { lat: 25.039686, lng: 55.117142 },
    { lat: 25.039842, lng: 55.115426 },
    { lat: 25.041397, lng: 55.113623 },
    { lat: 25.041786, lng: 55.114052 },
    { lat: 25.040697, lng: 55.115082 },
    { lat: 25.041864, lng: 55.116026 },
    { lat: 25.043652, lng: 55.115941 },
    { lat: 25.045674, lng: 55.117571 },
    { lat: 25.048629, lng: 55.120232 },
    { lat: 25.049406, lng: 55.121262 },
    { lat: 25.049173, lng: 55.121863 },
    { lat: 25.049795, lng: 55.122807 },
    { lat: 25.051039, lng: 55.124352 },
    { lat: 25.051428, lng: 55.126326 },
    { lat: 25.053216, lng: 55.131733 },
    { lat: 25.053294, lng: 55.131905 },
];

export const jumeirah_village_triangle = [
    { lat: 25.059263, lng: 55.194349 },
    { lat: 25.057008, lng: 55.191602 },
    { lat: 25.052964, lng: 55.187997 },
    { lat: 25.047833, lng: 55.183362 },
    { lat: 25.043633, lng: 55.180015 },
    { lat: 25.042467, lng: 55.179242 },
    { lat: 25.042234, lng: 55.179328 },
    { lat: 25.041767, lng: 55.188529 },
    { lat: 25.042778, lng: 55.193164 },
    { lat: 25.043322, lng: 55.194795 },
    { lat: 25.045577, lng: 55.19634 },
    { lat: 25.046899, lng: 55.196082 },
    { lat: 25.058252, lng: 55.194623 },
    { lat: 25.058874, lng: 55.194795 },
    { lat: 25.059263, lng: 55.194537 },
];

export const jumeirah_village_circle = [
    { lat: 25.075047, lng: 55.211874 },
    { lat: 25.07427, lng: 55.212904 },
    { lat: 25.071315, lng: 55.216251 },
    { lat: 25.070227, lng: 55.21711 },
    { lat: 25.068983, lng: 55.217281 },
    { lat: 25.067739, lng: 55.217453 },
    { lat: 25.064474, lng: 55.218397 },
    { lat: 25.063852, lng: 55.219341 },
    { lat: 25.062608, lng: 55.219341 },
    { lat: 25.060897, lng: 55.219856 },
    { lat: 25.059498, lng: 55.220371 },
    { lat: 25.057787, lng: 55.220886 },
    { lat: 25.055921, lng: 55.221144 },
    { lat: 25.054677, lng: 55.221058 },
    { lat: 25.051956, lng: 55.21977 },
    { lat: 25.050251, lng: 55.218483 },
    { lat: 25.050018, lng: 55.216852 },
    { lat: 25.049318, lng: 55.216165 },
    { lat: 25.047919, lng: 55.21402 },
    { lat: 25.046985, lng: 55.211359 },
    { lat: 25.047219, lng: 55.210844 },
    { lat: 25.046752, lng: 55.209556 },
    { lat: 25.046363, lng: 55.209213 },
    { lat: 25.045586, lng: 55.206638 },
    { lat: 25.045508, lng: 55.205694 },
    { lat: 25.045508, lng: 55.204664 },
    { lat: 25.045819, lng: 55.202948 },
    { lat: 25.045664, lng: 55.201231 },
    { lat: 25.046286, lng: 55.199171 },
    { lat: 25.049318, lng: 55.196854 },
    { lat: 25.051262, lng: 55.196424 },
    { lat: 25.052273, lng: 55.197197 },
    { lat: 25.053439, lng: 55.197197 },
    { lat: 25.05445, lng: 55.196596 },
    { lat: 25.055461, lng: 55.195995 },
    { lat: 25.058338, lng: 55.196596 },
    { lat: 25.058804, lng: 55.19754 },
    { lat: 25.059971, lng: 55.197454 },
    { lat: 25.062303, lng: 55.198913 },
    { lat: 25.06277, lng: 55.199943 },
    { lat: 25.063469, lng: 55.200029 },
    { lat: 25.064713, lng: 55.201059 },
    { lat: 25.070311, lng: 55.205694 },
    { lat: 25.074509, lng: 55.209042 },
    { lat: 25.075131, lng: 55.210157 },
    { lat: 25.075131, lng: 55.211702 },
];

export const district_one = [
    { lat: 25.178322, lng: 55.316573 },
    { lat: 25.179099, lng: 55.312968 },
    { lat: 25.18174, lng: 55.300952 },
    { lat: 25.182361, lng: 55.293398 },
    { lat: 25.181429, lng: 55.291682 },
    { lat: 25.181584, lng: 55.287562 },
    { lat: 25.180186, lng: 55.282069 },
    { lat: 25.178167, lng: 55.277606 },
    { lat: 25.174593, lng: 55.274516 },
    { lat: 25.170243, lng: 55.270568 },
    { lat: 25.167291, lng: 55.270396 },
    { lat: 25.163407, lng: 55.272799 },
    { lat: 25.159989, lng: 55.276061 },
    { lat: 25.154706, lng: 55.278979 },
    { lat: 25.149268, lng: 55.283957 },
    { lat: 25.144917, lng: 55.286875 },
    { lat: 25.137768, lng: 55.28945 },
    { lat: 25.131863, lng: 55.290137 },
    { lat: 25.131086, lng: 55.290995 },
    { lat: 25.131086, lng: 55.300952 },
    { lat: 25.134505, lng: 55.303012 },
    { lat: 25.136681, lng: 55.30593 },
    { lat: 25.140255, lng: 55.306273 },
    { lat: 25.141653, lng: 55.316401 },
    { lat: 25.150666, lng: 55.338545 },
    { lat: 25.17831, lng: 55.317193 },
];

export const district_11 = [
    { lat: 25.271521, lng: 55.307759 },
    { lat: 25.271474, lng: 55.307612 },
    { lat: 25.271201, lng: 55.307675 },
    { lat: 25.271245, lng: 55.307851 },
    { lat: 25.271523, lng: 55.307763 },
];

export const jumeirah_heights = [
    { lat: 25.0738, lng: 55.142039 },
    { lat: 25.073256, lng: 55.141095 },
    { lat: 25.069602, lng: 55.138177 },
    { lat: 25.06828, lng: 55.136546 },
    { lat: 25.066259, lng: 55.136375 },
    { lat: 25.063538, lng: 55.136718 },
    { lat: 25.061439, lng: 55.137147 },
    { lat: 25.060039, lng: 55.13895 },
    { lat: 25.058329, lng: 55.13955 },
    { lat: 25.056618, lng: 55.139379 },
    { lat: 25.052964, lng: 55.141524 },
    { lat: 25.049853, lng: 55.144271 },
    { lat: 25.046976, lng: 55.147704 },
    { lat: 25.043866, lng: 55.151567 },
    { lat: 25.041066, lng: 55.154742 },
    { lat: 25.042, lng: 55.159377 },
    { lat: 25.042544, lng: 55.16015 },
    { lat: 25.042933, lng: 55.16118 },
    { lat: 25.046665, lng: 55.164785 },
    { lat: 25.049076, lng: 55.167102 },
    { lat: 25.053041, lng: 55.170793 },
    { lat: 25.057193, lng: 55.164098 },
    { lat: 25.059526, lng: 55.161094 },
    { lat: 25.062714, lng: 55.157403 },
    { lat: 25.069711, lng: 55.146846 },
    { lat: 25.070877, lng: 55.146846 },
    { lat: 25.071266, lng: 55.146159 },
    { lat: 25.073987, lng: 55.14264 },
];

export const international_city_1 = [
    { lat: 25.179232, lng: 55.401485 },
    { lat: 25.174261, lng: 55.425261 },
    { lat: 25.172241, lng: 55.426977 },
    { lat: 25.154218, lng: 55.423372 },
    { lat: 25.149867, lng: 55.417364 },
    { lat: 25.149324, lng: 55.415991 },
    { lat: 25.153208, lng: 55.405005 },
    { lat: 25.15888, lng: 55.393332 },
    { lat: 25.160511, lng: 55.391186 },
    { lat: 25.176358, lng: 55.396936 },
    { lat: 25.178533, lng: 55.400198 },
    { lat: 25.178921, lng: 55.402 },
];

export const international_city_2 = [
    { lat: 25.158445, lng: 55.393847 },
    { lat: 25.152851, lng: 55.405519 },
    { lat: 25.148811, lng: 55.417107 },
    { lat: 25.14345, lng: 55.422342 },
    { lat: 25.139332, lng: 55.42569 },
    { lat: 25.138477, lng: 55.42569 },
    { lat: 25.127987, lng: 55.41127 },
    { lat: 25.13307, lng: 55.398074 },
    { lat: 25.138898, lng: 55.383139 },
    { lat: 25.158131, lng: 55.390958 },
    { lat: 25.15883, lng: 55.392331 },
    { lat: 25.158908, lng: 55.393275 },
];

export const the_world_pol_1 = [
    { lat: 25.218093, lng: 55.194659 },
    { lat: 25.216889, lng: 55.19393 },
    { lat: 25.215531, lng: 55.193157 },
    { lat: 25.215259, lng: 55.194015 },
    { lat: 25.218831, lng: 55.19702 },
    { lat: 25.219801, lng: 55.197234 },
    { lat: 25.220151, lng: 55.196676 },
    { lat: 25.218093, lng: 55.194659 },
];

export const the_world_pol_2 = [
    { lat: 25.213918, lng: 55.189914 },
    { lat: 25.216403, lng: 55.193176 },
    { lat: 25.218267, lng: 55.193862 },
    { lat: 25.220596, lng: 55.194892 },
    { lat: 25.221994, lng: 55.196266 },
    { lat: 25.221683, lng: 55.197124 },
    { lat: 25.222537, lng: 55.196781 },
    { lat: 25.222926, lng: 55.196137 },
    { lat: 25.222809, lng: 55.194892 },
    { lat: 25.222382, lng: 55.194377 },
    { lat: 25.221606, lng: 55.194206 },
    { lat: 25.220907, lng: 55.193734 },
    { lat: 25.22048, lng: 55.193433 },
    { lat: 25.218111, lng: 55.192704 },
    { lat: 25.217102, lng: 55.19206 },
    { lat: 25.21454, lng: 55.188541 },
    { lat: 25.213833, lng: 55.188234 },
    { lat: 25.21296, lng: 55.188427 },
    { lat: 25.212067, lng: 55.188191 },
    { lat: 25.21212, lng: 55.188044 },
    { lat: 25.211533, lng: 55.187936 },
    { lat: 25.209912, lng: 55.187164 },
    { lat: 25.209131, lng: 55.186987 },
    { lat: 25.207199, lng: 55.187405 },
    { lat: 25.20552, lng: 55.187888 },
    { lat: 25.205267, lng: 55.188274 },
    { lat: 25.205209, lng: 55.18851 },
    { lat: 25.205287, lng: 55.189251 },
    { lat: 25.207092, lng: 55.188757 },
    { lat: 25.210218, lng: 55.189025 },
    { lat: 25.213732, lng: 55.18953 },
    { lat: 25.213907, lng: 55.189843 },
];

export const the_world_pol_3 = [
    { lat: 25.238118, lng: 55.193974 },
    { lat: 25.238545, lng: 55.195347 },
    { lat: 25.238701, lng: 55.195561 },
    { lat: 25.238662, lng: 55.195905 },
    { lat: 25.238662, lng: 55.196205 },
    { lat: 25.238584, lng: 55.196591 },
    { lat: 25.238468, lng: 55.196935 },
    { lat: 25.238274, lng: 55.197578 },
    { lat: 25.236566, lng: 55.199939 },
    { lat: 25.236216, lng: 55.199767 },
    { lat: 25.235479, lng: 55.199853 },
    { lat: 25.234935, lng: 55.199553 },
    { lat: 25.235712, lng: 55.198093 },
    { lat: 25.236177, lng: 55.197578 },
    { lat: 25.236799, lng: 55.196591 },
    { lat: 25.236799, lng: 55.195733 },
    { lat: 25.236488, lng: 55.194231 },
    { lat: 25.236449, lng: 55.1926 },
    { lat: 25.238312, lng: 55.191957 },
    { lat: 25.238739, lng: 55.191613 },
    { lat: 25.238623, lng: 55.191399 },
    { lat: 25.237536, lng: 55.190412 },
    { lat: 25.236876, lng: 55.188352 },
    { lat: 25.236954, lng: 55.188008 },
    { lat: 25.236799, lng: 55.187922 },
    { lat: 25.236566, lng: 55.187579 },
    { lat: 25.2361, lng: 55.187536 },
    { lat: 25.235712, lng: 55.18788 },
    { lat: 25.235362, lng: 55.188051 },
    { lat: 25.234858, lng: 55.187536 },
    { lat: 25.235052, lng: 55.187021 },
    { lat: 25.23676, lng: 55.186764 },
    { lat: 25.237342, lng: 55.187922 },
    { lat: 25.237575, lng: 55.189167 },
    { lat: 25.237963, lng: 55.190326 },
    { lat: 25.238739, lng: 55.190626 },
    { lat: 25.238468, lng: 55.188352 },
    { lat: 25.238468, lng: 55.188051 },
    { lat: 25.238739, lng: 55.188051 },
    { lat: 25.239166, lng: 55.189296 },
    { lat: 25.239283, lng: 55.190283 },
    { lat: 25.239205, lng: 55.191141 },
    { lat: 25.239205, lng: 55.192085 },
    { lat: 25.239205, lng: 55.192557 },
    { lat: 25.239516, lng: 55.193544 },
];

export const the_world_pol_4 = [
    { lat: 25.234236, lng: 55.193373 },
    { lat: 25.234743, lng: 55.194009 },
    { lat: 25.234549, lng: 55.194094 },
    { lat: 25.234238, lng: 55.194352 },
    { lat: 25.233811, lng: 55.194867 },
    { lat: 25.233617, lng: 55.195468 },
    { lat: 25.233694, lng: 55.196369 },
    { lat: 25.233966, lng: 55.197098 },
    { lat: 25.234121, lng: 55.197656 },
    { lat: 25.234432, lng: 55.197914 },
    { lat: 25.234587, lng: 55.198128 },
    { lat: 25.23451, lng: 55.198558 },
    { lat: 25.234121, lng: 55.198772 },
    { lat: 25.233927, lng: 55.19903 },
    { lat: 25.233733, lng: 55.199845 },
    { lat: 25.233694, lng: 55.200875 },
    { lat: 25.2335, lng: 55.200918 },
    { lat: 25.233035, lng: 55.201047 },
    { lat: 25.232879, lng: 55.201047 },
    { lat: 25.23253, lng: 55.200875 },
    { lat: 25.232103, lng: 55.200575 },
    { lat: 25.231948, lng: 55.200017 },
    { lat: 25.231676, lng: 55.199373 },
    { lat: 25.231598, lng: 55.199115 },
    { lat: 25.231909, lng: 55.198944 },
    { lat: 25.232103, lng: 55.199373 },
    { lat: 25.232258, lng: 55.199845 },
    { lat: 25.232724, lng: 55.200532 },
    { lat: 25.233267, lng: 55.200532 },
    { lat: 25.23319, lng: 55.199888 },
    { lat: 25.232879, lng: 55.198901 },
    { lat: 25.232608, lng: 55.1986 },
    { lat: 25.232491, lng: 55.198472 },
    { lat: 25.231909, lng: 55.197184 },
    { lat: 25.231715, lng: 55.196755 },
    { lat: 25.231792, lng: 55.195553 },
    { lat: 25.231986, lng: 55.195167 },
    { lat: 25.231986, lng: 55.194695 },
    { lat: 25.231093, lng: 55.194781 },
    { lat: 25.228958, lng: 55.194395 },
    { lat: 25.2276, lng: 55.19388 },
    { lat: 25.227056, lng: 55.193451 },
    { lat: 25.226978, lng: 55.193408 },
    { lat: 25.227056, lng: 55.19315 },
    { lat: 25.227483, lng: 55.192764 },
    { lat: 25.227483, lng: 55.192378 },
    { lat: 25.227638, lng: 55.19212 },
    { lat: 25.228143, lng: 55.192249 },
    { lat: 25.228919, lng: 55.192678 },
    { lat: 25.229774, lng: 55.192979 },
    { lat: 25.230589, lng: 55.19315 },
    { lat: 25.231404, lng: 55.193279 },
    { lat: 25.231443, lng: 55.193622 },
    { lat: 25.231443, lng: 55.193923 },
    { lat: 25.231521, lng: 55.194137 },
    { lat: 25.231909, lng: 55.19418 },
    { lat: 25.232297, lng: 55.19418 },
    { lat: 25.232724, lng: 55.193622 },
    { lat: 25.233423, lng: 55.192206 },
    { lat: 25.233112, lng: 55.189846 },
    { lat: 25.233384, lng: 55.189631 },
    { lat: 25.233578, lng: 55.189932 },
    { lat: 25.233966, lng: 55.189932 },
    { lat: 25.234471, lng: 55.18946 },
    { lat: 25.234704, lng: 55.189288 },
    { lat: 25.234937, lng: 55.189674 },
    { lat: 25.235325, lng: 55.190575 },
    { lat: 25.235791, lng: 55.191176 },
    { lat: 25.235985, lng: 55.191605 },
    { lat: 25.235674, lng: 55.19212 },
    { lat: 25.235053, lng: 55.19212 },
    { lat: 25.234743, lng: 55.192378 },
    { lat: 25.23482, lng: 55.19285 },
];

export const the_world_pol_5 = [
    { lat: 25.24041, lng: 55.200231 },
    { lat: 25.240016, lng: 55.20084 },
    { lat: 25.238773, lng: 55.200711 },
    { lat: 25.238463, lng: 55.200883 },
    { lat: 25.237725, lng: 55.201526 },
    { lat: 25.236522, lng: 55.201698 },
    { lat: 25.236367, lng: 55.201612 },
    { lat: 25.236367, lng: 55.201355 },
    { lat: 25.236638, lng: 55.201054 },
    { lat: 25.237881, lng: 55.200454 },
    { lat: 25.238773, lng: 55.200196 },
    { lat: 25.240054, lng: 55.199166 },
    { lat: 25.240908, lng: 55.197621 },
    { lat: 25.24087, lng: 55.196505 },
    { lat: 25.24052, lng: 55.195776 },
    { lat: 25.240792, lng: 55.194875 },
    { lat: 25.240171, lng: 55.193158 },
    { lat: 25.240093, lng: 55.192943 },
    { lat: 25.240481, lng: 55.192986 },
    { lat: 25.241103, lng: 55.194531 },
    { lat: 25.241413, lng: 55.19642 },
    { lat: 25.240714, lng: 55.199037 },
    { lat: 25.240637, lng: 55.199896 },
    { lat: 25.240637, lng: 55.20011 },
];

export const the_world_pol_6 = [
    { lat: 25.232096, lng: 55.19054 },
    { lat: 25.232096, lng: 55.190368 },
    { lat: 25.231902, lng: 55.19024 },
    { lat: 25.230932, lng: 55.189896 },
    { lat: 25.230427, lng: 55.189725 },
    { lat: 25.228797, lng: 55.189381 },
    { lat: 25.228525, lng: 55.189639 },
    { lat: 25.228603, lng: 55.190154 },
    { lat: 25.22868, lng: 55.190454 },
    { lat: 25.228408, lng: 55.191098 },
    { lat: 25.228603, lng: 55.191356 },
    { lat: 25.229146, lng: 55.191098 },
    { lat: 25.230117, lng: 55.191356 },
    { lat: 25.231864, lng: 55.191956 },
    { lat: 25.23198, lng: 55.191656 },
    { lat: 25.232058, lng: 55.191098 },
    { lat: 25.232096, lng: 55.190883 },
    { lat: 25.232291, lng: 55.190755 },
];

export const the_world_pol_7 = [
    { lat: 25.231087, lng: 55.18612 },
    { lat: 25.231087, lng: 55.185777 },
    { lat: 25.230699, lng: 55.185648 },
    { lat: 25.230233, lng: 55.185605 },
    { lat: 25.23, lng: 55.185777 },
    { lat: 25.229961, lng: 55.186463 },
    { lat: 25.230427, lng: 55.186935 },
    { lat: 25.231087, lng: 55.187021 },
    { lat: 25.231359, lng: 55.186549 },
    { lat: 25.231359, lng: 55.18642 },
    { lat: 25.23132, lng: 55.186292 },
];

export const the_world_pol_8 = [
    { lat: 25.248185, lng: 55.193082 },
    { lat: 25.248146, lng: 55.192588 },
    { lat: 25.248185, lng: 55.192073 },
    { lat: 25.248204, lng: 55.191258 },
    { lat: 25.248107, lng: 55.190807 },
    { lat: 25.247894, lng: 55.190657 },
    { lat: 25.247544, lng: 55.190614 },
    { lat: 25.247311, lng: 55.190829 },
    { lat: 25.246923, lng: 55.191365 },
    { lat: 25.246787, lng: 55.191558 },
    { lat: 25.246671, lng: 55.191687 },
    { lat: 25.246419, lng: 55.191966 },
    { lat: 25.24603, lng: 55.192116 },
    { lat: 25.245856, lng: 55.192245 },
    { lat: 25.245468, lng: 55.192502 },
    { lat: 25.245196, lng: 55.192803 },
    { lat: 25.244555, lng: 55.193296 },
    { lat: 25.244128, lng: 55.193962 },
    { lat: 25.244031, lng: 55.194519 },
    { lat: 25.24409, lng: 55.19467 },
    { lat: 25.244536, lng: 55.194648 },
    { lat: 25.245021, lng: 55.194262 },
    { lat: 25.245468, lng: 55.193618 },
    { lat: 25.245565, lng: 55.193447 },
    { lat: 25.245972, lng: 55.193253 },
    { lat: 25.246341, lng: 55.193082 },
    { lat: 25.246613, lng: 55.192889 },
    { lat: 25.246923, lng: 55.193189 },
    { lat: 25.246923, lng: 55.19364 },
    { lat: 25.246807, lng: 55.194369 },
    { lat: 25.246108, lng: 55.194927 },
    { lat: 25.245953, lng: 55.195292 },
    { lat: 25.24603, lng: 55.195592 },
    { lat: 25.246535, lng: 55.195785 },
    { lat: 25.246787, lng: 55.195678 },
    { lat: 25.247137, lng: 55.19497 },
    { lat: 25.247564, lng: 55.194326 },
    { lat: 25.248185, lng: 55.193146 },
];

export const the_world_pol_9 = [
    { lat: 25.24616, lng: 55.183847 },
    { lat: 25.245422, lng: 55.184662 },
    { lat: 25.244762, lng: 55.184791 },
    { lat: 25.243986, lng: 55.184619 },
    { lat: 25.243132, lng: 55.184276 },
    { lat: 25.242821, lng: 55.184061 },
    { lat: 25.242899, lng: 55.183718 },
    { lat: 25.243636, lng: 55.183375 },
    { lat: 25.24418, lng: 55.183332 },
    { lat: 25.244801, lng: 55.183546 },
    { lat: 25.245383, lng: 55.183203 },
    { lat: 25.245577, lng: 55.182903 },
    { lat: 25.246082, lng: 55.182817 },
    { lat: 25.24647, lng: 55.183074 },
    { lat: 25.246315, lng: 55.183761 },
];

export const the_world_pol_10 = [
    { lat: 25.248122, lng: 55.179998 },
    { lat: 25.247773, lng: 55.180728 },
    { lat: 25.247579, lng: 55.181372 },
    { lat: 25.247268, lng: 55.181672 },
    { lat: 25.246802, lng: 55.181758 },
    { lat: 25.246337, lng: 55.181758 },
    { lat: 25.246104, lng: 55.181629 },
    { lat: 25.245987, lng: 55.1815 },
    { lat: 25.246259, lng: 55.181114 },
    { lat: 25.246608, lng: 55.180556 },
    { lat: 25.246841, lng: 55.180213 },
    { lat: 25.247113, lng: 55.179741 },
    { lat: 25.247579, lng: 55.179355 },
    { lat: 25.247928, lng: 55.179011 },
    { lat: 25.248239, lng: 55.179784 },
];

export const the_world_pol_11 = [
    { lat: 25.249248, lng: 55.181071 },
    { lat: 25.249054, lng: 55.181887 },
    { lat: 25.248937, lng: 55.182101 },
    { lat: 25.248433, lng: 55.182101 },
    { lat: 25.248122, lng: 55.181715 },
    { lat: 25.248122, lng: 55.181114 },
    { lat: 25.248588, lng: 55.180771 },
    { lat: 25.249131, lng: 55.180985 },
];

export const the_world_pol_12 = [
    { lat: 25.250994, lng: 55.180041 },
    { lat: 25.251072, lng: 55.180213 },
    { lat: 25.250723, lng: 55.180985 },
    { lat: 25.250335, lng: 55.181329 },
    { lat: 25.249946, lng: 55.181415 },
    { lat: 25.249675, lng: 55.1809 },
    { lat: 25.249481, lng: 55.180256 },
    { lat: 25.249558, lng: 55.180041 },
    { lat: 25.250412, lng: 55.179955 },
    { lat: 25.2508, lng: 55.17987 },
    { lat: 25.250878, lng: 55.179912 },
];

export const the_world_pol_13 = [
    { lat: 25.24983, lng: 55.178625 },
    { lat: 25.249675, lng: 55.177895 },
    { lat: 25.249248, lng: 55.177209 },
    { lat: 25.248743, lng: 55.176951 },
    { lat: 25.2482, lng: 55.177681 },
    { lat: 25.248044, lng: 55.178024 },
    { lat: 25.248277, lng: 55.17884 },
    { lat: 25.249519, lng: 55.179698 },
    { lat: 25.249869, lng: 55.17944 },
    { lat: 25.249714, lng: 55.178668 },
];

export const the_world_pol_14 = [
    { lat: 25.255458, lng: 55.17944 },
    { lat: 25.255225, lng: 55.179011 },
    { lat: 25.254371, lng: 55.177509 },
    { lat: 25.253673, lng: 55.175836 },
    { lat: 25.253207, lng: 55.174376 },
    { lat: 25.25278, lng: 55.173346 },
    { lat: 25.252236, lng: 55.17266 },
    { lat: 25.251615, lng: 55.171673 },
    { lat: 25.251072, lng: 55.171887 },
    { lat: 25.250762, lng: 55.172359 },
    { lat: 25.250606, lng: 55.17266 },
    { lat: 25.250567, lng: 55.17296 },
    { lat: 25.250878, lng: 55.173175 },
    { lat: 25.251072, lng: 55.173518 },
    { lat: 25.251188, lng: 55.173861 },
    { lat: 25.251383, lng: 55.174033 },
    { lat: 25.251577, lng: 55.173518 },
    { lat: 25.251926, lng: 55.173132 },
    { lat: 25.252198, lng: 55.173175 },
    { lat: 25.252431, lng: 55.173569 },
    { lat: 25.25212, lng: 55.173998 },
    { lat: 25.251771, lng: 55.174127 },
    { lat: 25.251577, lng: 55.173955 },
    { lat: 25.251421, lng: 55.174255 },
    { lat: 25.251033, lng: 55.174942 },
    { lat: 25.250723, lng: 55.1752 },
    { lat: 25.250684, lng: 55.175757 },
    { lat: 25.251227, lng: 55.176187 },
    { lat: 25.251693, lng: 55.176315 },
    { lat: 25.251848, lng: 55.176015 },
    { lat: 25.252314, lng: 55.175972 },
    { lat: 25.252353, lng: 55.176358 },
    { lat: 25.251965, lng: 55.176616 },
    { lat: 25.251499, lng: 55.177131 },
    { lat: 25.251227, lng: 55.17756 },
    { lat: 25.250994, lng: 55.17786 },
    { lat: 25.250956, lng: 55.178075 },
    { lat: 25.251615, lng: 55.178375 },
    { lat: 25.252159, lng: 55.179191 },
    { lat: 25.252392, lng: 55.179663 },
    { lat: 25.252935, lng: 55.180778 },
    { lat: 25.253905, lng: 55.181508 },
    { lat: 25.255109, lng: 55.181208 },
    { lat: 25.255225, lng: 55.180306 },
    { lat: 25.255497, lng: 55.179534 },
];

export const the_world_pol_15 = [
    { lat: 25.257049, lng: 55.171208 },
    { lat: 25.256273, lng: 55.169449 },
    { lat: 25.255497, lng: 55.168633 },
    { lat: 25.254061, lng: 55.167947 },
    { lat: 25.252974, lng: 55.167603 },
    { lat: 25.25181, lng: 55.167518 },
    { lat: 25.251615, lng: 55.168505 },
    { lat: 25.251926, lng: 55.169878 },
    { lat: 25.252857, lng: 55.170393 },
    { lat: 25.253711, lng: 55.17035 },
    { lat: 25.255264, lng: 55.171165 },
    { lat: 25.255497, lng: 55.17168 },
    { lat: 25.255807, lng: 55.172625 },
    { lat: 25.25604, lng: 55.172839 },
    { lat: 25.256584, lng: 55.172839 },
    { lat: 25.257049, lng: 55.171852 },
    { lat: 25.257088, lng: 55.171294 },
];

export const the_world_pol_16 = [
    { lat: 25.250645, lng: 55.168633 },
    { lat: 25.250373, lng: 55.168118 },
    { lat: 25.249675, lng: 55.167603 },
    { lat: 25.249248, lng: 55.167818 },
    { lat: 25.249248, lng: 55.168118 },
    { lat: 25.249714, lng: 55.168719 },
    { lat: 25.25014, lng: 55.169106 },
    { lat: 25.250684, lng: 55.168676 },
];

export const the_world_pol_17 = [
    { lat: 25.250717, lng: 55.170517 },
    { lat: 25.250058, lng: 55.169487 },
    { lat: 25.249281, lng: 55.1688 },
    { lat: 25.248777, lng: 55.168328 },
    { lat: 25.248466, lng: 55.168671 },
    { lat: 25.247368, lng: 55.1695 },
    { lat: 25.247135, lng: 55.169886 },
    { lat: 25.247212, lng: 55.170143 },
    { lat: 25.246397, lng: 55.17083 },
    { lat: 25.246397, lng: 55.171173 },
    { lat: 25.246747, lng: 55.171474 },
    { lat: 25.24729, lng: 55.170658 },
    { lat: 25.247911, lng: 55.170616 },
    { lat: 25.248726, lng: 55.171088 },
    { lat: 25.249658, lng: 55.171946 },
    { lat: 25.25055, lng: 55.170616 },
    { lat: 25.250706, lng: 55.170573 },
];

export const the_world_pol_18 = [
    { lat: 25.250589, lng: 55.174693 },
    { lat: 25.25055, lng: 55.17422 },
    { lat: 25.25055, lng: 55.173534 },
    { lat: 25.250162, lng: 55.173062 },
    { lat: 25.249813, lng: 55.172976 },
    { lat: 25.249153, lng: 55.173276 },
    { lat: 25.248881, lng: 55.173233 },
    { lat: 25.24892, lng: 55.172761 },
    { lat: 25.248687, lng: 55.172203 },
    { lat: 25.248493, lng: 55.172032 },
    { lat: 25.248027, lng: 55.171946 },
    { lat: 25.247223, lng: 55.172909 },
    { lat: 25.24765, lng: 55.173553 },
    { lat: 25.248116, lng: 55.173639 },
    { lat: 25.248621, lng: 55.173639 },
    { lat: 25.249203, lng: 55.174411 },
    { lat: 25.24963, lng: 55.174712 },
    { lat: 25.250445, lng: 55.174754 },
];

export const the_world_pol_19 = [
    { lat: 25.248465, lng: 55.176085 },
    { lat: 25.24831, lng: 55.175398 },
    { lat: 25.247728, lng: 55.175398 },
    { lat: 25.24734, lng: 55.175913 },
    { lat: 25.247107, lng: 55.176171 },
    { lat: 25.247378, lng: 55.176857 },
    { lat: 25.247844, lng: 55.176943 },
    { lat: 25.248465, lng: 55.176256 },
    { lat: 25.248504, lng: 55.176128 },
];

export const the_world_pol_20 = [
    { lat: 25.247068, lng: 55.175055 },
    { lat: 25.246913, lng: 55.174454 },
    { lat: 25.246447, lng: 55.17454 },
    { lat: 25.245942, lng: 55.175012 },
    { lat: 25.245709, lng: 55.175226 },
    { lat: 25.245748, lng: 55.175956 },
    { lat: 25.246292, lng: 55.176085 },
    { lat: 25.247107, lng: 55.175184 },
];

export const the_world_pol_21 = [
    { lat: 25.24567, lng: 55.174368 },
    { lat: 25.245515, lng: 55.173767 },
    { lat: 25.244972, lng: 55.17351 },
    { lat: 25.244506, lng: 55.174068 },
    { lat: 25.244467, lng: 55.174583 },
    { lat: 25.244428, lng: 55.175098 },
    { lat: 25.244972, lng: 55.175226 },
    { lat: 25.24567, lng: 55.17454 },
];

export const the_world_pol_22 = [
    { lat: 25.246447, lng: 55.17218 },
    { lat: 25.245865, lng: 55.173081 },
    { lat: 25.24536, lng: 55.173081 },
    { lat: 25.245244, lng: 55.172866 },
    { lat: 25.245011, lng: 55.172308 },
    { lat: 25.245282, lng: 55.171665 },
    { lat: 25.245787, lng: 55.171407 },
    { lat: 25.246292, lng: 55.171493 },
    { lat: 25.246447, lng: 55.171965 },
];

export const the_world_pol_23 = [
    { lat: 25.245515, lng: 55.17012 },
    { lat: 25.245205, lng: 55.170677 },
    { lat: 25.244894, lng: 55.170763 },
    { lat: 25.244467, lng: 55.170849 },
    { lat: 25.244079, lng: 55.170677 },
    { lat: 25.244079, lng: 55.169948 },
    { lat: 25.2447, lng: 55.169519 },
    { lat: 25.245088, lng: 55.169519 },
    { lat: 25.245476, lng: 55.169905 },
    { lat: 25.245515, lng: 55.170034 },
];

export const the_world_pol_24 = [
    { lat: 25.244118, lng: 55.16866 },
    { lat: 25.243807, lng: 55.167416 },
    { lat: 25.243846, lng: 55.166987 },
    { lat: 25.243652, lng: 55.166729 },
    { lat: 25.243147, lng: 55.166643 },
    { lat: 25.24272, lng: 55.166858 },
    { lat: 25.242604, lng: 55.167115 },
    { lat: 25.242488, lng: 55.16836 },
    { lat: 25.243147, lng: 55.169218 },
    { lat: 25.243963, lng: 55.168703 },
];

export const the_world_pol_25 = [
    { lat: 25.243536, lng: 55.171235 },
    { lat: 25.243497, lng: 55.170549 },
    { lat: 25.242837, lng: 55.170034 },
    { lat: 25.242138, lng: 55.169991 },
    { lat: 25.241944, lng: 55.170334 },
    { lat: 25.241828, lng: 55.170506 },
    { lat: 25.241944, lng: 55.170806 },
    { lat: 25.242604, lng: 55.171407 },
    { lat: 25.243186, lng: 55.171536 },
    { lat: 25.243497, lng: 55.171321 },
];

export const the_world_pol_26 = [
    { lat: 25.243947, lng: 55.173467 },
    { lat: 25.243404, lng: 55.174111 },
    { lat: 25.243093, lng: 55.174197 },
    { lat: 25.242472, lng: 55.173767 },
    { lat: 25.242588, lng: 55.173081 },
    { lat: 25.243015, lng: 55.172694 },
    { lat: 25.243404, lng: 55.172523 },
    { lat: 25.243947, lng: 55.173338 },
];

export const the_world_pol_27 = [
    { lat: 25.246121, lng: 55.178917 },
    { lat: 25.246121, lng: 55.178831 },
    { lat: 25.24581, lng: 55.178188 },
    { lat: 25.245616, lng: 55.178059 },
    { lat: 25.245383, lng: 55.178231 },
    { lat: 25.245112, lng: 55.178273 },
    { lat: 25.244917, lng: 55.178188 },
    { lat: 25.244607, lng: 55.177759 },
    { lat: 25.244723, lng: 55.177329 },
    { lat: 25.244646, lng: 55.177201 },
    { lat: 25.244646, lng: 55.176771 },
    { lat: 25.244646, lng: 55.176256 },
    { lat: 25.244646, lng: 55.17587 },
    { lat: 25.24449, lng: 55.175527 },
    { lat: 25.24418, lng: 55.175398 },
    { lat: 25.243753, lng: 55.175398 },
    { lat: 25.243481, lng: 55.175699 },
    { lat: 25.243404, lng: 55.176042 },
    { lat: 25.243598, lng: 55.176986 },
    { lat: 25.243675, lng: 55.177544 },
    { lat: 25.243869, lng: 55.178059 },
    { lat: 25.244219, lng: 55.178359 },
    { lat: 25.244219, lng: 55.17896 },
    { lat: 25.24418, lng: 55.179389 },
    { lat: 25.243908, lng: 55.179518 },
    { lat: 25.243636, lng: 55.179733 },
    { lat: 25.243636, lng: 55.179947 },
    { lat: 25.243714, lng: 55.180291 },
    { lat: 25.244219, lng: 55.18072 },
    { lat: 25.244646, lng: 55.180548 },
    { lat: 25.244995, lng: 55.180333 },
    { lat: 25.245383, lng: 55.179776 },
    { lat: 25.245733, lng: 55.179432 },
    { lat: 25.246121, lng: 55.179046 },
];

export const the_world_pol_28 = [
    { lat: 25.243097, lng: 55.178353 },
    { lat: 25.242747, lng: 55.177924 },
    { lat: 25.242825, lng: 55.177366 },
    { lat: 25.242631, lng: 55.176937 },
    { lat: 25.242281, lng: 55.176465 },
    { lat: 25.241893, lng: 55.176293 },
    { lat: 25.241389, lng: 55.176336 },
    { lat: 25.241311, lng: 55.176851 },
    { lat: 25.241893, lng: 55.178353 },
    { lat: 25.242786, lng: 55.178611 },
    { lat: 25.24298, lng: 55.178396 },
];

export const the_world_pol_29 = [
    { lat: 25.24253, lng: 55.180456 },
    { lat: 25.241598, lng: 55.181443 },
    { lat: 25.241482, lng: 55.181443 },
    { lat: 25.240977, lng: 55.1814 },
    { lat: 25.2409, lng: 55.1811 },
    { lat: 25.241094, lng: 55.180456 },
    { lat: 25.241327, lng: 55.18007 },
    { lat: 25.241792, lng: 55.179727 },
    { lat: 25.242375, lng: 55.179855 },
    { lat: 25.242608, lng: 55.180242 },
];

export const the_world_pol_30 = [
    { lat: 25.240938, lng: 55.178268 },
    { lat: 25.241055, lng: 55.178697 },
    { lat: 25.240667, lng: 55.179255 },
    { lat: 25.240511, lng: 55.179555 },
    { lat: 25.239929, lng: 55.179898 },
    { lat: 25.239386, lng: 55.179898 },
    { lat: 25.23857, lng: 55.179255 },
    { lat: 25.238532, lng: 55.178697 },
    { lat: 25.238881, lng: 55.178525 },
    { lat: 25.239347, lng: 55.178225 },
    { lat: 25.23958, lng: 55.177838 },
    { lat: 25.240046, lng: 55.177753 },
    { lat: 25.240434, lng: 55.17771 },
    { lat: 25.240977, lng: 55.177924 },
    { lat: 25.241132, lng: 55.178096 },
];

export const the_world_pol_31 = [
    { lat: 25.239929, lng: 55.176516 },
    { lat: 25.239774, lng: 55.176688 },
    { lat: 25.239774, lng: 55.176902 },
    { lat: 25.239463, lng: 55.177203 },
    { lat: 25.239114, lng: 55.177074 },
    { lat: 25.238881, lng: 55.177117 },
    { lat: 25.238532, lng: 55.176988 },
    { lat: 25.238376, lng: 55.176645 },
    { lat: 25.238221, lng: 55.176301 },
    { lat: 25.238493, lng: 55.175829 },
    { lat: 25.238881, lng: 55.175658 },
    { lat: 25.23923, lng: 55.175872 },
    { lat: 25.239774, lng: 55.176387 },
    { lat: 25.239929, lng: 55.17643 },
];

export const the_world_pol_32 = [
    { lat: 25.240729, lng: 55.175443 },
    { lat: 25.240612, lng: 55.175314 },
    { lat: 25.240418, lng: 55.175057 },
    { lat: 25.240147, lng: 55.175143 },
    { lat: 25.239758, lng: 55.174628 },
    { lat: 25.239176, lng: 55.174113 },
    { lat: 25.238982, lng: 55.173898 },
    { lat: 25.239021, lng: 55.173641 },
    { lat: 25.239137, lng: 55.173383 },
    { lat: 25.238788, lng: 55.172954 },
    { lat: 25.238904, lng: 55.172353 },
    { lat: 25.239215, lng: 55.172053 },
    { lat: 25.239681, lng: 55.171752 },
    { lat: 25.240147, lng: 55.171194 },
    { lat: 25.240147, lng: 55.171023 },
    { lat: 25.239991, lng: 55.169993 },
    { lat: 25.240379, lng: 55.169478 },
    { lat: 25.240535, lng: 55.169392 },
    { lat: 25.240651, lng: 55.169306 },
    { lat: 25.240651, lng: 55.169049 },
    { lat: 25.240651, lng: 55.168705 },
    { lat: 25.240962, lng: 55.168276 },
    { lat: 25.241001, lng: 55.168019 },
    { lat: 25.24069, lng: 55.167761 },
    { lat: 25.240147, lng: 55.16759 },
    { lat: 25.239991, lng: 55.167504 },
    { lat: 25.239836, lng: 55.168276 },
    { lat: 25.239914, lng: 55.168963 },
    { lat: 25.239952, lng: 55.169306 },
    { lat: 25.239991, lng: 55.169649 },
    { lat: 25.239564, lng: 55.16995 },
    { lat: 25.239293, lng: 55.170551 },
    { lat: 25.239137, lng: 55.170551 },
    { lat: 25.238811, lng: 55.170851 },
    { lat: 25.238772, lng: 55.171023 },
    { lat: 25.238501, lng: 55.171452 },
    { lat: 25.238539, lng: 55.172224 },
    { lat: 25.238345, lng: 55.172739 },
    { lat: 25.23819, lng: 55.172911 },
    { lat: 25.23788, lng: 55.173169 },
    { lat: 25.237724, lng: 55.173297 },
    { lat: 25.237724, lng: 55.173512 },
    { lat: 25.238035, lng: 55.173641 },
    { lat: 25.238501, lng: 55.174156 },
    { lat: 25.238966, lng: 55.174885 },
    { lat: 25.239005, lng: 55.175143 },
    { lat: 25.240053, lng: 55.175743 },
    { lat: 25.240674, lng: 55.175486 },
];

export const the_world_pol_33 = [
    { lat: 25.241979, lng: 55.174027 },
    { lat: 25.241979, lng: 55.173812 },
    { lat: 25.241746, lng: 55.173426 },
    { lat: 25.241358, lng: 55.172997 },
    { lat: 25.240931, lng: 55.172739 },
    { lat: 25.240581, lng: 55.172997 },
    { lat: 25.240232, lng: 55.173644 },
    { lat: 25.240581, lng: 55.174116 },
    { lat: 25.241552, lng: 55.174631 },
    { lat: 25.242056, lng: 55.174159 },
];

export const the_world_pol_34 = [
    { lat: 25.237398, lng: 55.176047 },
    { lat: 25.237321, lng: 55.175404 },
    { lat: 25.237321, lng: 55.174417 },
    { lat: 25.237321, lng: 55.174116 },
    { lat: 25.236932, lng: 55.173987 },
    { lat: 25.236544, lng: 55.173987 },
    { lat: 25.236117, lng: 55.174631 },
    { lat: 25.236272, lng: 55.174974 },
    { lat: 25.236389, lng: 55.175232 },
    { lat: 25.236389, lng: 55.175404 },
    { lat: 25.236234, lng: 55.175661 },
    { lat: 25.236195, lng: 55.176047 },
    { lat: 25.236428, lng: 55.176519 },
    { lat: 25.236544, lng: 55.176863 },
    { lat: 25.236932, lng: 55.176605 },
    { lat: 25.237476, lng: 55.176519 },
    { lat: 25.237398, lng: 55.176133 },
];

export const the_world_pol_35 = [
    { lat: 25.23694, lng: 55.172571 },
    { lat: 25.236824, lng: 55.172099 },
    { lat: 25.236591, lng: 55.171455 },
    { lat: 25.236125, lng: 55.171069 },
    { lat: 25.235737, lng: 55.170855 },
    { lat: 25.235193, lng: 55.171069 },
    { lat: 25.235038, lng: 55.171198 },
    { lat: 25.234921, lng: 55.171713 },
    { lat: 25.23531, lng: 55.172743 },
    { lat: 25.235737, lng: 55.172872 },
    { lat: 25.236358, lng: 55.173 },
    { lat: 25.236707, lng: 55.172914 },
    { lat: 25.236901, lng: 55.172657 },
];

export const the_world_pol_36 = [
    { lat: 25.240434, lng: 55.185532 },
    { lat: 25.240434, lng: 55.185188 },
    { lat: 25.240084, lng: 55.184759 },
    { lat: 25.239774, lng: 55.184931 },
    { lat: 25.23958, lng: 55.185274 },
    { lat: 25.239619, lng: 55.185617 },
    { lat: 25.239696, lng: 55.186047 },
    { lat: 25.239968, lng: 55.186132 },
    { lat: 25.240434, lng: 55.185875 },
];

export const the_world_pol_37 = [
    { lat: 25.239153, lng: 55.184587 },
    { lat: 25.238997, lng: 55.185017 },
    { lat: 25.238765, lng: 55.185274 },
    { lat: 25.238376, lng: 55.185446 },
    { lat: 25.238105, lng: 55.185446 },
    { lat: 25.237639, lng: 55.185145 },
    { lat: 25.237561, lng: 55.184716 },
    { lat: 25.237949, lng: 55.184416 },
    { lat: 25.237988, lng: 55.183987 },
    { lat: 25.23826, lng: 55.183386 },
    { lat: 25.238415, lng: 55.1833 },
    { lat: 25.238803, lng: 55.1833 },
    { lat: 25.23892, lng: 55.183815 },
    { lat: 25.23923, lng: 55.184459 },
];

export const the_world_pol_38 = [
    { lat: 25.239774, lng: 55.181369 },
    { lat: 25.239774, lng: 55.182184 },
    { lat: 25.239619, lng: 55.182485 },
    { lat: 25.239541, lng: 55.182699 },
    { lat: 25.239308, lng: 55.182957 },
    { lat: 25.239036, lng: 55.183042 },
    { lat: 25.238765, lng: 55.182656 },
    { lat: 25.239036, lng: 55.182098 },
    { lat: 25.239269, lng: 55.181412 },
    { lat: 25.239657, lng: 55.181197 },
    { lat: 25.239813, lng: 55.18124 },
];

export const the_world_pol_39 = [
    { lat: 25.238431, lng: 55.182356 },
    { lat: 25.238392, lng: 55.182055 },
    { lat: 25.238392, lng: 55.181326 },
    { lat: 25.238043, lng: 55.181068 },
    { lat: 25.237926, lng: 55.181068 },
    { lat: 25.237732, lng: 55.181326 },
    { lat: 25.23746, lng: 55.181498 },
    { lat: 25.237344, lng: 55.181841 },
    { lat: 25.237305, lng: 55.182399 },
    { lat: 25.237732, lng: 55.182699 },
    { lat: 25.238353, lng: 55.18257 },
];

export const the_world_pol_40 = [
    { lat: 25.237616, lng: 55.183515 },
    { lat: 25.237499, lng: 55.183729 },
    { lat: 25.237305, lng: 55.184072 },
    { lat: 25.23715, lng: 55.184416 },
    { lat: 25.236956, lng: 55.18463 },
    { lat: 25.23614, lng: 55.185102 },
    { lat: 25.235791, lng: 55.185102 },
    { lat: 25.235558, lng: 55.18506 },
    { lat: 25.235442, lng: 55.184459 },
    { lat: 25.235713, lng: 55.18403 },
    { lat: 25.235869, lng: 55.1833 },
    { lat: 25.236335, lng: 55.183171 },
    { lat: 25.237111, lng: 55.183085 },
    { lat: 25.237771, lng: 55.183386 },
];

export const the_world_pol_41 = [
    { lat: 25.236606, lng: 55.18197 },
    { lat: 25.236567, lng: 55.181498 },
    { lat: 25.236645, lng: 55.180897 },
    { lat: 25.23649, lng: 55.180639 },
    { lat: 25.235985, lng: 55.180682 },
    { lat: 25.235675, lng: 55.180682 },
    { lat: 25.235364, lng: 55.181025 },
    { lat: 25.234976, lng: 55.181669 },
    { lat: 25.235325, lng: 55.182141 },
    { lat: 25.235869, lng: 55.182184 },
    { lat: 25.236412, lng: 55.182141 },
];

export const the_world_pol_42 = [
    { lat: 25.237421, lng: 55.179094 },
    { lat: 25.237344, lng: 55.17888 },
    { lat: 25.23715, lng: 55.178365 },
    { lat: 25.23715, lng: 55.178064 },
    { lat: 25.237111, lng: 55.177721 },
    { lat: 25.236645, lng: 55.177549 },
    { lat: 25.236529, lng: 55.177549 },
    { lat: 25.236102, lng: 55.177721 },
    { lat: 25.235442, lng: 55.178408 },
    { lat: 25.235442, lng: 55.178923 },
    { lat: 25.23548, lng: 55.179094 },
    { lat: 25.236024, lng: 55.179438 },
    { lat: 25.236335, lng: 55.179609 },
    { lat: 25.2368, lng: 55.179738 },
    { lat: 25.23715, lng: 55.179395 },
    { lat: 25.237383, lng: 55.179051 },
];

export const the_world_pol_43 = [
    { lat: 25.234665, lng: 55.181583 },
    { lat: 25.234665, lng: 55.181111 },
    { lat: 25.234665, lng: 55.180811 },
    { lat: 25.234782, lng: 55.180124 },
    { lat: 25.234549, lng: 55.179781 },
    { lat: 25.234238, lng: 55.179695 },
    { lat: 25.233889, lng: 55.179652 },
    { lat: 25.233656, lng: 55.17991 },
    { lat: 25.233617, lng: 55.180296 },
    { lat: 25.233811, lng: 55.180768 },
    { lat: 25.234005, lng: 55.181111 },
    { lat: 25.233928, lng: 55.181669 },
    { lat: 25.233928, lng: 55.181884 },
    { lat: 25.234316, lng: 55.181927 },
    { lat: 25.234665, lng: 55.181798 },
];

export const the_world_pol_44 = [
    { lat: 25.234471, lng: 55.178021 },
    { lat: 25.234394, lng: 55.177893 },
    { lat: 25.234316, lng: 55.177463 },
    { lat: 25.234005, lng: 55.177206 },
    { lat: 25.233539, lng: 55.176605 },
    { lat: 25.232957, lng: 55.176305 },
    { lat: 25.232647, lng: 55.176691 },
    { lat: 25.232841, lng: 55.177206 },
    { lat: 25.23288, lng: 55.177378 },
    { lat: 25.233151, lng: 55.178451 },
    { lat: 25.233578, lng: 55.178794 },
    { lat: 25.234005, lng: 55.178665 },
    { lat: 25.234394, lng: 55.178365 },
    { lat: 25.234471, lng: 55.178107 },
];

export const the_world_pol_45 = [
    { lat: 25.233695, lng: 55.183729 },
    { lat: 25.233578, lng: 55.18403 },
    { lat: 25.233307, lng: 55.184201 },
    { lat: 25.232957, lng: 55.184244 },
    { lat: 25.232763, lng: 55.184416 },
    { lat: 25.232453, lng: 55.184115 },
    { lat: 25.232724, lng: 55.183472 },
    { lat: 25.232608, lng: 55.183171 },
    { lat: 25.232608, lng: 55.182699 },
    { lat: 25.233074, lng: 55.182442 },
    { lat: 25.233384, lng: 55.182442 },
    { lat: 25.233695, lng: 55.182742 },
    { lat: 25.23385, lng: 55.183386 },
    { lat: 25.233811, lng: 55.183429 },
    { lat: 25.233811, lng: 55.183643 },
];

export const the_world_pol_46 = [
    { lat: 25.232072, lng: 55.183739 },
    { lat: 25.231917, lng: 55.184083 },
    { lat: 25.231839, lng: 55.184297 },
    { lat: 25.231567, lng: 55.184684 },
    { lat: 25.231567, lng: 55.184855 },
    { lat: 25.231373, lng: 55.184984 },
    { lat: 25.23114, lng: 55.184855 },
    { lat: 25.231024, lng: 55.184083 },
    { lat: 25.230985, lng: 55.183482 },
    { lat: 25.231218, lng: 55.183139 },
    { lat: 25.231451, lng: 55.182924 },
    { lat: 25.231878, lng: 55.18331 },
    { lat: 25.231994, lng: 55.183611 },
    { lat: 25.232033, lng: 55.183654 },
];

export const the_world_pol_47 = [
    { lat: 25.231373, lng: 55.186615 },
    { lat: 25.231063, lng: 55.186915 },
    { lat: 25.230869, lng: 55.186958 },
    { lat: 25.230752, lng: 55.186958 },
    { lat: 25.229937, lng: 55.186701 },
    { lat: 25.229859, lng: 55.186228 },
    { lat: 25.23017, lng: 55.185628 },
    { lat: 25.230636, lng: 55.185671 },
    { lat: 25.230946, lng: 55.185713 },
    { lat: 25.231412, lng: 55.186486 },
];

export const the_world_pol_48 = [
    { lat: 25.230209, lng: 55.181765 },
    { lat: 25.23017, lng: 55.18198 },
    { lat: 25.230015, lng: 55.182323 },
    { lat: 25.230015, lng: 55.182709 },
    { lat: 25.229704, lng: 55.182795 },
    { lat: 25.229393, lng: 55.182709 },
    { lat: 25.228772, lng: 55.18228 },
    { lat: 25.229083, lng: 55.181851 },
    { lat: 25.229432, lng: 55.181679 },
    { lat: 25.229704, lng: 55.181422 },
    { lat: 25.230209, lng: 55.18125 },
    { lat: 25.230286, lng: 55.181594 },
];

export const the_world_pol_49 = [
    { lat: 25.232126, lng: 55.180006 },
    { lat: 25.232088, lng: 55.180006 },
    { lat: 25.231505, lng: 55.180564 },
    { lat: 25.231389, lng: 55.180564 },
    { lat: 25.230806, lng: 55.180478 },
    { lat: 25.230496, lng: 55.180349 },
    { lat: 25.230108, lng: 55.18022 },
    { lat: 25.230185, lng: 55.179834 },
    { lat: 25.230457, lng: 55.179319 },
    { lat: 25.230729, lng: 55.17889 },
    { lat: 25.231039, lng: 55.178804 },
    { lat: 25.23135, lng: 55.179276 },
    { lat: 25.231583, lng: 55.179577 },
    { lat: 25.231816, lng: 55.179748 },
    { lat: 25.231855, lng: 55.179877 },
    { lat: 25.232088, lng: 55.179791 },
    { lat: 25.232126, lng: 55.179877 },
];

export const the_world_pol_50 = [
    { lat: 25.231816, lng: 55.177688 },
    { lat: 25.231738, lng: 55.177903 },
    { lat: 25.231699, lng: 55.178203 },
    { lat: 25.231466, lng: 55.178332 },
    { lat: 25.230923, lng: 55.178332 },
    { lat: 25.230729, lng: 55.178332 },
    { lat: 25.230185, lng: 55.178804 },
    { lat: 25.229991, lng: 55.179534 },
    { lat: 25.22972, lng: 55.180092 },
    { lat: 25.229331, lng: 55.180692 },
    { lat: 25.229098, lng: 55.180821 },
    { lat: 25.228827, lng: 55.181164 },
    { lat: 25.228555, lng: 55.180907 },
    { lat: 25.228555, lng: 55.18022 },
    { lat: 25.228865, lng: 55.179877 },
    { lat: 25.22937, lng: 55.178804 },
    { lat: 25.229487, lng: 55.178246 },
    { lat: 25.230574, lng: 55.177817 },
    { lat: 25.23135, lng: 55.177474 },
    { lat: 25.231816, lng: 55.17756 },
];

export const the_world_pol_51 = [
    { lat: 25.235737, lng: 55.174899 },
    { lat: 25.235543, lng: 55.1758 },
    { lat: 25.235387, lng: 55.175843 },
    { lat: 25.234999, lng: 55.175929 },
    { lat: 25.234766, lng: 55.175929 },
    { lat: 25.2343, lng: 55.175242 },
    { lat: 25.234456, lng: 55.175028 },
    { lat: 25.234689, lng: 55.174556 },
    { lat: 25.235038, lng: 55.174255 },
    { lat: 25.235581, lng: 55.174598 },
    { lat: 25.235776, lng: 55.174727 },
];

export const the_world_pol_52 = [
    { lat: 25.234223, lng: 55.174212 },
    { lat: 25.234106, lng: 55.174641 },
    { lat: 25.234106, lng: 55.174727 },
    { lat: 25.233679, lng: 55.174899 },
    { lat: 25.233291, lng: 55.175113 },
    { lat: 25.232748, lng: 55.175242 },
    { lat: 25.232476, lng: 55.174899 },
    { lat: 25.232476, lng: 55.174255 },
    { lat: 25.232825, lng: 55.173826 },
    { lat: 25.233291, lng: 55.173783 },
    { lat: 25.233679, lng: 55.173783 },
    { lat: 25.2343, lng: 55.174041 },
];

export const the_world_pol_53 = [
    { lat: 25.233757, lng: 55.172195 },
    { lat: 25.233679, lng: 55.172581 },
    { lat: 25.233291, lng: 55.173096 },
    { lat: 25.232825, lng: 55.173182 },
    { lat: 25.232321, lng: 55.172968 },
    { lat: 25.232204, lng: 55.172839 },
    { lat: 25.232049, lng: 55.172538 },
    { lat: 25.232282, lng: 55.172195 },
    { lat: 25.232748, lng: 55.171723 },
    { lat: 25.233136, lng: 55.17168 },
    { lat: 25.233563, lng: 55.171981 },
    { lat: 25.233835, lng: 55.172152 },
];

export const the_world_pol_54 = [
    { lat: 25.2343, lng: 55.169363 },
    { lat: 25.234184, lng: 55.169577 },
    { lat: 25.233912, lng: 55.170307 },
    { lat: 25.232942, lng: 55.17035 },
    { lat: 25.232592, lng: 55.170393 },
    { lat: 25.232243, lng: 55.170264 },
    { lat: 25.232553, lng: 55.169234 },
    { lat: 25.233058, lng: 55.168848 },
    { lat: 25.234145, lng: 55.168848 },
    { lat: 25.234145, lng: 55.169148 },
    { lat: 25.234223, lng: 55.169234 },
];

export const the_world_pol_55 = [
    { lat: 25.233524, lng: 55.166831 },
    { lat: 25.233213, lng: 55.167303 },
    { lat: 25.232864, lng: 55.167346 },
    { lat: 25.232864, lng: 55.167861 },
    { lat: 25.232553, lng: 55.168462 },
    { lat: 25.232476, lng: 55.168633 },
    { lat: 25.232126, lng: 55.168676 },
    { lat: 25.231699, lng: 55.168462 },
    { lat: 25.231661, lng: 55.167689 },
    { lat: 25.232126, lng: 55.167217 },
    { lat: 25.23267, lng: 55.166874 },
    { lat: 25.233213, lng: 55.166487 },
    { lat: 25.233524, lng: 55.166659 },
];

export const the_world_pol_56 = [
    { lat: 25.232377, lng: 55.165289 },
    { lat: 25.232144, lng: 55.166105 },
    { lat: 25.231756, lng: 55.166577 },
    { lat: 25.231096, lng: 55.166705 },
    { lat: 25.230359, lng: 55.166105 },
    { lat: 25.230436, lng: 55.165418 },
    { lat: 25.230592, lng: 55.164946 },
    { lat: 25.230902, lng: 55.164731 },
    { lat: 25.231251, lng: 55.164431 },
    { lat: 25.231562, lng: 55.164388 },
    { lat: 25.232067, lng: 55.164431 },
    { lat: 25.232416, lng: 55.164903 },
    { lat: 25.232533, lng: 55.165075 },
];

export const the_world_pol_57 = [
    { lat: 25.230514, lng: 55.167478 },
    { lat: 25.230514, lng: 55.167907 },
    { lat: 25.230514, lng: 55.16825 },
    { lat: 25.230242, lng: 55.168765 },
    { lat: 25.229737, lng: 55.168722 },
    { lat: 25.229233, lng: 55.168679 },
    { lat: 25.228806, lng: 55.168122 },
    { lat: 25.228689, lng: 55.167864 },
    { lat: 25.228961, lng: 55.167306 },
    { lat: 25.229427, lng: 55.166963 },
    { lat: 25.230048, lng: 55.166877 },
    { lat: 25.230592, lng: 55.167349 },
];

export const the_world_pol_58 = [
    { lat: 25.228612, lng: 55.168894 },
    { lat: 25.22865, lng: 55.169109 },
    { lat: 25.22865, lng: 55.169409 },
    { lat: 25.228456, lng: 55.170482 },
    { lat: 25.228068, lng: 55.170267 },
    { lat: 25.227758, lng: 55.170224 },
    { lat: 25.227563, lng: 55.169667 },
    { lat: 25.227408, lng: 55.169023 },
    { lat: 25.227835, lng: 55.168679 },
    { lat: 25.228185, lng: 55.168336 },
    { lat: 25.228612, lng: 55.168551 },
    { lat: 25.228767, lng: 55.168679 },
];

export const the_world_pol_59 = [
    { lat: 25.228487, lng: 55.171456 },
    { lat: 25.228487, lng: 55.171671 },
    { lat: 25.228487, lng: 55.172057 },
    { lat: 25.227711, lng: 55.172357 },
    { lat: 25.227245, lng: 55.172357 },
    { lat: 25.226973, lng: 55.171885 },
    { lat: 25.226934, lng: 55.171542 },
    { lat: 25.227245, lng: 55.17107 },
    { lat: 25.227866, lng: 55.170684 },
    { lat: 25.228526, lng: 55.17137 },
];

export const the_world_pol_60 = [
    { lat: 25.22907, lng: 55.176649 },
    { lat: 25.22872, lng: 55.176477 },
    { lat: 25.228487, lng: 55.176177 },
    { lat: 25.228099, lng: 55.176091 },
    { lat: 25.227983, lng: 55.175748 },
    { lat: 25.228022, lng: 55.175361 },
    { lat: 25.228449, lng: 55.174761 },
    { lat: 25.228332, lng: 55.174589 },
    { lat: 25.227711, lng: 55.174374 },
    { lat: 25.227633, lng: 55.174117 },
    { lat: 25.227711, lng: 55.173816 },
    { lat: 25.228681, lng: 55.173473 },
    { lat: 25.228759, lng: 55.173387 },
    { lat: 25.228643, lng: 55.173087 },
    { lat: 25.227866, lng: 55.172786 },
    { lat: 25.227245, lng: 55.17343 },
    { lat: 25.227245, lng: 55.173774 },
    { lat: 25.227206, lng: 55.174546 },
    { lat: 25.227012, lng: 55.174804 },
    { lat: 25.226934, lng: 55.174889 },
    { lat: 25.227245, lng: 55.175533 },
    { lat: 25.227711, lng: 55.175833 },
    { lat: 25.2274, lng: 55.176348 },
    { lat: 25.22709, lng: 55.17652 },
    { lat: 25.22709, lng: 55.176735 },
    { lat: 25.227129, lng: 55.177164 },
    { lat: 25.227594, lng: 55.177164 },
    { lat: 25.228293, lng: 55.177035 },
    { lat: 25.228759, lng: 55.176778 },
    { lat: 25.228953, lng: 55.176692 },
];

export const the_world_pol_61 = [
    { lat: 25.226438, lng: 55.176692 },
    { lat: 25.226127, lng: 55.177035 },
    { lat: 25.226049, lng: 55.177378 },
    { lat: 25.225156, lng: 55.177164 },
    { lat: 25.22504, lng: 55.177035 },
    { lat: 25.224923, lng: 55.176434 },
    { lat: 25.224574, lng: 55.176091 },
    { lat: 25.225312, lng: 55.175662 },
    { lat: 25.225506, lng: 55.175962 },
    { lat: 25.225855, lng: 55.176348 },
    { lat: 25.226282, lng: 55.176649 },
];

export const the_world_pol_62 = [
    { lat: 25.225816, lng: 55.174289 },
    { lat: 25.2257, lng: 55.174761 },
    { lat: 25.225118, lng: 55.174675 },
    { lat: 25.224807, lng: 55.17446 },
    { lat: 25.224885, lng: 55.173731 },
    { lat: 25.225389, lng: 55.173516 },
    { lat: 25.225855, lng: 55.17416 },
];

export const the_world_pol_63 = [
    { lat: 25.226422, lng: 55.172701 },
    { lat: 25.226073, lng: 55.173173 },
    { lat: 25.226034, lng: 55.17343 },
    { lat: 25.225568, lng: 55.17313 },
    { lat: 25.225219, lng: 55.172958 },
    { lat: 25.224908, lng: 55.172786 },
    { lat: 25.224675, lng: 55.172357 },
    { lat: 25.225024, lng: 55.171671 },
    { lat: 25.225451, lng: 55.171671 },
    { lat: 25.225646, lng: 55.172229 },
    { lat: 25.22615, lng: 55.172572 },
    { lat: 25.226383, lng: 55.172572 },
];

export const the_world_pol_64 = [
    { lat: 25.226104, lng: 55.170598 },
    { lat: 25.226104, lng: 55.170898 },
    { lat: 25.225987, lng: 55.171284 },
    { lat: 25.225715, lng: 55.171284 },
    { lat: 25.225133, lng: 55.171284 },
    { lat: 25.224784, lng: 55.171284 },
    { lat: 25.224628, lng: 55.17107 },
    { lat: 25.224823, lng: 55.170727 },
    { lat: 25.225211, lng: 55.170426 },
    { lat: 25.225444, lng: 55.170512 },
    { lat: 25.225832, lng: 55.170512 },
    { lat: 25.226065, lng: 55.170469 },
];

export const the_world_pol_65 = [
    { lat: 25.226818, lng: 55.169568 },
    { lat: 25.226624, lng: 55.169825 },
    { lat: 25.226158, lng: 55.169954 },
    { lat: 25.225615, lng: 55.169739 },
    { lat: 25.22511, lng: 55.169225 },
    { lat: 25.225382, lng: 55.168924 },
    { lat: 25.225847, lng: 55.168924 },
    { lat: 25.226779, lng: 55.16931 },
    { lat: 25.226779, lng: 55.169482 },
];

export const the_world_pol_66 = [
    { lat: 25.226817, lng: 55.168406 },
    { lat: 25.226623, lng: 55.168449 },
    { lat: 25.226157, lng: 55.168664 },
    { lat: 25.225963, lng: 55.168621 },
    { lat: 25.225769, lng: 55.168406 },
    { lat: 25.225691, lng: 55.168278 },
    { lat: 25.22573, lng: 55.167462 },
    { lat: 25.226312, lng: 55.167205 },
    { lat: 25.226702, lng: 55.167507 },
    { lat: 25.226857, lng: 55.168236 },
];

export const the_world_pol_67 = [
    { lat: 25.228317, lng: 55.166906 },
    { lat: 25.228317, lng: 55.167077 },
    { lat: 25.228006, lng: 55.167163 },
    { lat: 25.227202, lng: 55.167378 },
    { lat: 25.226613, lng: 55.16712 },
    { lat: 25.226263, lng: 55.16682 },
    { lat: 25.226652, lng: 55.16609 },
    { lat: 25.227195, lng: 55.166004 },
    { lat: 25.227816, lng: 55.166305 },
    { lat: 25.228399, lng: 55.166777 },
];

export const the_world_pol_68 = [
    { lat: 25.228655, lng: 55.163215 },
    { lat: 25.228655, lng: 55.163215 },
    { lat: 25.228267, lng: 55.163558 },
    { lat: 25.227723, lng: 55.163987 },
    { lat: 25.227257, lng: 55.164331 },
    { lat: 25.22652, lng: 55.164288 },
    { lat: 25.226481, lng: 55.163987 },
    { lat: 25.226325, lng: 55.16373 },
    { lat: 25.226558, lng: 55.163301 },
    { lat: 25.227102, lng: 55.1627 },
    { lat: 25.227645, lng: 55.162099 },
    { lat: 25.228228, lng: 55.162357 },
    { lat: 25.228616, lng: 55.162958 },
    { lat: 25.228616, lng: 55.163086 },
];

export const the_world_pol_69 = [
    { lat: 25.227762, lng: 55.160898 },
    { lat: 25.227568, lng: 55.161241 },
    { lat: 25.227296, lng: 55.161756 },
    { lat: 25.227102, lng: 55.161842 },
    { lat: 25.226636, lng: 55.161842 },
    { lat: 25.22586, lng: 55.161842 },
    { lat: 25.225277, lng: 55.161155 },
    { lat: 25.225704, lng: 55.160597 },
    { lat: 25.226248, lng: 55.16064 },
    { lat: 25.226908, lng: 55.16064 },
    { lat: 25.227257, lng: 55.16064 },
    { lat: 25.227723, lng: 55.160812 },
];

export const the_world_pol_70 = [
    { lat: 25.225238, lng: 55.162443 },
    { lat: 25.225238, lng: 55.162657 },
    { lat: 25.224734, lng: 55.162958 },
    { lat: 25.224268, lng: 55.162958 },
    { lat: 25.223918, lng: 55.162743 },
    { lat: 25.223647, lng: 55.162271 },
    { lat: 25.223647, lng: 55.162013 },
    { lat: 25.223957, lng: 55.161799 },
    { lat: 25.224578, lng: 55.161799 },
    { lat: 25.225005, lng: 55.161928 },
    { lat: 25.225238, lng: 55.162228 },
    { lat: 25.225316, lng: 55.162314 },
];

export const the_world_pol_71 = [
    { lat: 25.225976, lng: 55.164588 },
    { lat: 25.22586, lng: 55.164846 },
    { lat: 25.225471, lng: 55.165103 },
    { lat: 25.225277, lng: 55.164932 },
    { lat: 25.224889, lng: 55.164545 },
    { lat: 25.224423, lng: 55.164159 },
    { lat: 25.22419, lng: 55.16403 },
    { lat: 25.224617, lng: 55.163773 },
    { lat: 25.225083, lng: 55.163558 },
    { lat: 25.225704, lng: 55.16403 },
    { lat: 25.226092, lng: 55.164502 },
];

export const the_world_pol_72 = [
    { lat: 25.225316, lng: 55.166047 },
    { lat: 25.225238, lng: 55.166004 },
    { lat: 25.225005, lng: 55.166391 },
    { lat: 25.224695, lng: 55.166477 },
    { lat: 25.224423, lng: 55.166133 },
    { lat: 25.223957, lng: 55.165618 },
    { lat: 25.223802, lng: 55.165232 },
    { lat: 25.224112, lng: 55.164846 },
    { lat: 25.224462, lng: 55.16476 },
    { lat: 25.224811, lng: 55.164889 },
    { lat: 25.225316, lng: 55.16579 },
    { lat: 25.225316, lng: 55.165919 },
];

export const the_world_pol_73 = [
    { lat: 25.224586, lng: 55.167077 },
    { lat: 25.224431, lng: 55.167335 },
    { lat: 25.224043, lng: 55.167378 },
    { lat: 25.223421, lng: 55.167378 },
    { lat: 25.222994, lng: 55.16712 },
    { lat: 25.222994, lng: 55.166906 },
    { lat: 25.22315, lng: 55.166519 },
    { lat: 25.223654, lng: 55.166348 },
    { lat: 25.224276, lng: 55.166434 },
    { lat: 25.224664, lng: 55.166992 },
];

export const the_world_pol_74 = [
    { lat: 25.22412, lng: 55.158237 },
    { lat: 25.224198, lng: 55.15858 },
    { lat: 25.22412, lng: 55.159438 },
    { lat: 25.223926, lng: 55.160254 },
    { lat: 25.223538, lng: 55.160511 },
    { lat: 25.22346, lng: 55.160511 },
    { lat: 25.222684, lng: 55.160468 },
    { lat: 25.222296, lng: 55.160125 },
    { lat: 25.222334, lng: 55.159782 },
    { lat: 25.222684, lng: 55.15931 },
    { lat: 25.223188, lng: 55.158923 },
    { lat: 25.22346, lng: 55.158408 },
    { lat: 25.223771, lng: 55.157722 },
    { lat: 25.224237, lng: 55.158065 },
];

export const the_world_pol_75 = [
    { lat: 25.222567, lng: 55.15828 },
    { lat: 25.222567, lng: 55.15858 },
    { lat: 25.222063, lng: 55.159009 },
    { lat: 25.221674, lng: 55.159095 },
    { lat: 25.221364, lng: 55.159095 },
    { lat: 25.221053, lng: 55.158795 },
    { lat: 25.220937, lng: 55.158666 },
    { lat: 25.221092, lng: 55.158151 },
    { lat: 25.221713, lng: 55.158022 },
    { lat: 25.22214, lng: 55.157851 },
    { lat: 25.222567, lng: 55.158065 },
];

export const the_world_pol_76 = [
    { lat: 25.223188, lng: 55.162743 },
    { lat: 25.223188, lng: 55.162915 },
    { lat: 25.222567, lng: 55.16373 },
    { lat: 25.222101, lng: 55.163945 },
    { lat: 25.221713, lng: 55.163987 },
    { lat: 25.221441, lng: 55.163172 },
    { lat: 25.221325, lng: 55.162314 },
    { lat: 25.221597, lng: 55.161928 },
    { lat: 25.222218, lng: 55.161842 },
    { lat: 25.222761, lng: 55.162228 },
    { lat: 25.223227, lng: 55.162528 },
];

export const the_world_pol_77 = [
    { lat: 25.223305, lng: 55.16476 },
    { lat: 25.223111, lng: 55.165404 },
    { lat: 25.222761, lng: 55.165747 },
    { lat: 25.222257, lng: 55.165876 },
    { lat: 25.221907, lng: 55.165876 },
    { lat: 25.221364, lng: 55.165704 },
    { lat: 25.221014, lng: 55.165232 },
    { lat: 25.221014, lng: 55.164932 },
    { lat: 25.221247, lng: 55.164588 },
    { lat: 25.22183, lng: 55.16476 },
    { lat: 25.222218, lng: 55.164631 },
    { lat: 25.222567, lng: 55.164502 },
    { lat: 25.222761, lng: 55.164417 },
    { lat: 25.223188, lng: 55.164674 },
];

export const the_world_pol_78 = [
    { lat: 25.220774, lng: 55.162185 },
    { lat: 25.220812, lng: 55.162357 },
    { lat: 25.22089, lng: 55.163043 },
    { lat: 25.220735, lng: 55.163601 },
    { lat: 25.220308, lng: 55.163859 },
    { lat: 25.220002, lng: 55.163644 },
    { lat: 25.219497, lng: 55.163644 },
    { lat: 25.219109, lng: 55.163129 },
    { lat: 25.218992, lng: 55.162915 },
    { lat: 25.219148, lng: 55.162571 },
    { lat: 25.219303, lng: 55.162271 },
    { lat: 25.219458, lng: 55.161885 },
    { lat: 25.220079, lng: 55.16137 },
    { lat: 25.220312, lng: 55.16137 },
    { lat: 25.220856, lng: 55.162056 },
];

export const the_world_pol_79 = [
    { lat: 25.220273, lng: 55.167249 },
    { lat: 25.220235, lng: 55.167378 },
    { lat: 25.21973, lng: 55.167721 },
    { lat: 25.219303, lng: 55.167893 },
    { lat: 25.218837, lng: 55.167678 },
    { lat: 25.218255, lng: 55.167936 },
    { lat: 25.218177, lng: 55.167507 },
    { lat: 25.218255, lng: 55.16712 },
    { lat: 25.218371, lng: 55.166734 },
    { lat: 25.218216, lng: 55.166519 },
    { lat: 25.21806, lng: 55.166519 },
    { lat: 25.21775, lng: 55.166519 },
    { lat: 25.217672, lng: 55.166219 },
    { lat: 25.218099, lng: 55.166176 },
    { lat: 25.218449, lng: 55.166133 },
    { lat: 25.218604, lng: 55.166305 },
    { lat: 25.218915, lng: 55.166047 },
    { lat: 25.21907, lng: 55.16579 },
    { lat: 25.219963, lng: 55.166243 },
    { lat: 25.220118, lng: 55.166844 },
    { lat: 25.220312, lng: 55.167101 },
];

export const the_world_pol_80 = [
    { lat: 25.218332, lng: 55.168346 },
    { lat: 25.218371, lng: 55.168603 },
    { lat: 25.218099, lng: 55.169347 },
    { lat: 25.218022, lng: 55.169519 },
    { lat: 25.217905, lng: 55.17012 },
    { lat: 25.217905, lng: 55.170935 },
    { lat: 25.218022, lng: 55.171364 },
    { lat: 25.218022, lng: 55.171622 },
    { lat: 25.217633, lng: 55.171536 },
    { lat: 25.217362, lng: 55.171493 },
    { lat: 25.216442, lng: 55.170821 },
    { lat: 25.216287, lng: 55.170606 },
    { lat: 25.21621, lng: 55.170434 },
    { lat: 25.21652, lng: 55.170048 },
    { lat: 25.21687, lng: 55.169919 },
    { lat: 25.21718, lng: 55.169705 },
    { lat: 25.217219, lng: 55.169404 },
    { lat: 25.217064, lng: 55.168847 },
    { lat: 25.21687, lng: 55.168203 },
    { lat: 25.216792, lng: 55.167902 },
    { lat: 25.217219, lng: 55.167516 },
    { lat: 25.217685, lng: 55.167602 },
    { lat: 25.217763, lng: 55.167902 },
    { lat: 25.21753, lng: 55.168031 },
    { lat: 25.217607, lng: 55.168246 },
    { lat: 25.218112, lng: 55.168246 },
    { lat: 25.218306, lng: 55.168546 },
];

export const the_world_pol_81 = [
    { lat: 25.216241, lng: 55.168932 },
    { lat: 25.216241, lng: 55.168675 },
    { lat: 25.216124, lng: 55.168203 },
    { lat: 25.216008, lng: 55.167387 },
    { lat: 25.215891, lng: 55.166872 },
    { lat: 25.216046, lng: 55.166572 },
    { lat: 25.21593, lng: 55.166186 },
    { lat: 25.216396, lng: 55.165842 },
    { lat: 25.216707, lng: 55.1658 },
    { lat: 25.216862, lng: 55.1658 },
    { lat: 25.217328, lng: 55.165928 },
    { lat: 25.217677, lng: 55.165842 },
    { lat: 25.217716, lng: 55.165628 },
    { lat: 25.217561, lng: 55.165199 },
    { lat: 25.216396, lng: 55.164855 },
    { lat: 25.215891, lng: 55.164984 },
    { lat: 25.215386, lng: 55.16537 },
    { lat: 25.215153, lng: 55.165499 },
    { lat: 25.215231, lng: 55.165757 },
    { lat: 25.215542, lng: 55.166057 },
    { lat: 25.215619, lng: 55.166443 },
    { lat: 25.215619, lng: 55.16683 },
    { lat: 25.215037, lng: 55.167345 },
    { lat: 25.214765, lng: 55.167645 },
    { lat: 25.214532, lng: 55.168246 },
    { lat: 25.214493, lng: 55.168546 },
    { lat: 25.21461, lng: 55.168847 },
    { lat: 25.214843, lng: 55.168889 },
    { lat: 25.215231, lng: 55.168889 },
    { lat: 25.215697, lng: 55.168761 },
    { lat: 25.216124, lng: 55.169018 },
    { lat: 25.216318, lng: 55.169018 },
];

export const the_world_pol_82 = [
    { lat: 25.214175, lng: 55.169619 },
    { lat: 25.214198, lng: 55.169576 },
    { lat: 25.214082, lng: 55.169404 },
    { lat: 25.213927, lng: 55.169233 },
    { lat: 25.213888, lng: 55.169018 },
    { lat: 25.213694, lng: 55.169104 },
    { lat: 25.213461, lng: 55.16919 },
    { lat: 25.213111, lng: 55.16919 },
    { lat: 25.212529, lng: 55.169276 },
    { lat: 25.212373, lng: 55.169447 },
    { lat: 25.212684, lng: 55.169919 },
    { lat: 25.213111, lng: 55.170392 },
    { lat: 25.21381, lng: 55.17052 },
    { lat: 25.214121, lng: 55.17052 },
    { lat: 25.214043, lng: 55.170134 },
    { lat: 25.21416, lng: 55.169791 },
    { lat: 25.214354, lng: 55.169748 },
];

export const the_world_pol_83 = [
    { lat: 25.215821, lng: 55.172494 },
    { lat: 25.215736, lng: 55.172709 },
    { lat: 25.215464, lng: 55.173052 },
    { lat: 25.215153, lng: 55.173052 },
    { lat: 25.214843, lng: 55.172409 },
    { lat: 25.214455, lng: 55.17155 },
    { lat: 25.214299, lng: 55.171379 },
    { lat: 25.214455, lng: 55.170649 },
    { lat: 25.214882, lng: 55.170649 },
    { lat: 25.215309, lng: 55.170821 },
    { lat: 25.215542, lng: 55.171336 },
    { lat: 25.215542, lng: 55.171765 },
    { lat: 25.215775, lng: 55.172065 },
    { lat: 25.21593, lng: 55.17228 },
    { lat: 25.215969, lng: 55.172366 },
];

export const the_world_pol_84 = [
    { lat: 25.215177, lng: 55.173825 },
    { lat: 25.215254, lng: 55.173911 },
    { lat: 25.215254, lng: 55.174383 },
    { lat: 25.215138, lng: 55.174812 },
    { lat: 25.215254, lng: 55.175799 },
    { lat: 25.215099, lng: 55.175842 },
    { lat: 25.214983, lng: 55.176185 },
    { lat: 25.214827, lng: 55.176314 },
    { lat: 25.214672, lng: 55.176357 },
    { lat: 25.2144, lng: 55.175756 },
    { lat: 25.214323, lng: 55.175112 },
    { lat: 25.214361, lng: 55.174769 },
    { lat: 25.214361, lng: 55.174383 },
    { lat: 25.214284, lng: 55.174125 },
    { lat: 25.214633, lng: 55.173868 },
    { lat: 25.214827, lng: 55.17361 },
    { lat: 25.215021, lng: 55.173567 },
    { lat: 25.215254, lng: 55.173739 },
];

export const the_world_pol_85 = [
    { lat: 25.215293, lng: 55.178503 },
    { lat: 25.215138, lng: 55.177945 },
    { lat: 25.214944, lng: 55.177558 },
    { lat: 25.214633, lng: 55.177301 },
    { lat: 25.214439, lng: 55.177301 },
    { lat: 25.214128, lng: 55.177 },
    { lat: 25.213973, lng: 55.176786 },
    { lat: 25.213663, lng: 55.177043 },
    { lat: 25.213197, lng: 55.177301 },
    { lat: 25.213197, lng: 55.177515 },
    { lat: 25.21343, lng: 55.177859 },
    { lat: 25.21343, lng: 55.178116 },
    { lat: 25.213197, lng: 55.178331 },
    { lat: 25.212964, lng: 55.178245 },
    { lat: 25.21277, lng: 55.178588 },
    { lat: 25.212544, lng: 55.178889 },
    { lat: 25.21235, lng: 55.180262 },
    { lat: 25.212234, lng: 55.180434 },
    { lat: 25.212738, lng: 55.180777 },
    { lat: 25.213243, lng: 55.180906 },
    { lat: 25.21336, lng: 55.180777 },
    { lat: 25.21336, lng: 55.180434 },
    { lat: 25.213243, lng: 55.180391 },
    { lat: 25.213437, lng: 55.179404 },
    { lat: 25.213554, lng: 55.178889 },
    { lat: 25.213554, lng: 55.178503 },
    { lat: 25.213826, lng: 55.178245 },
    { lat: 25.214175, lng: 55.178202 },
    { lat: 25.214486, lng: 55.178545 },
    { lat: 25.215029, lng: 55.178631 },
    { lat: 25.215223, lng: 55.178503 },
];

export const the_world_pol_86 = [
    { lat: 25.215402, lng: 55.18318 },
    { lat: 25.215231, lng: 55.183395 },
    { lat: 25.215076, lng: 55.183524 },
    { lat: 25.214765, lng: 55.183567 },
    { lat: 25.214571, lng: 55.183609 },
    { lat: 25.214377, lng: 55.183652 },
    { lat: 25.213795, lng: 55.183867 },
    { lat: 25.213484, lng: 55.184167 },
    { lat: 25.213057, lng: 55.184596 },
    { lat: 25.212902, lng: 55.184596 },
    { lat: 25.212164, lng: 55.18391 },
    { lat: 25.212086, lng: 55.183524 },
    { lat: 25.21228, lng: 55.182494 },
    { lat: 25.212746, lng: 55.182193 },
    { lat: 25.213523, lng: 55.181979 },
    { lat: 25.213833, lng: 55.183052 },
    { lat: 25.213833, lng: 55.183309 },
    { lat: 25.214338, lng: 55.183352 },
    { lat: 25.214338, lng: 55.182923 },
    { lat: 25.214959, lng: 55.182537 },
    { lat: 25.215192, lng: 55.182708 },
    { lat: 25.215309, lng: 55.182708 },
    { lat: 25.215503, lng: 55.182923 },
    { lat: 25.215386, lng: 55.183009 },
];

export const the_world_pol_87 = [
    { lat: 25.215324, lng: 55.181163 },
    { lat: 25.215324, lng: 55.181592 },
    { lat: 25.215091, lng: 55.182193 },
    { lat: 25.214781, lng: 55.182279 },
    { lat: 25.214004, lng: 55.182322 },
    { lat: 25.213849, lng: 55.182236 },
    { lat: 25.213849, lng: 55.181335 },
    { lat: 25.213849, lng: 55.180949 },
    { lat: 25.214043, lng: 55.180605 },
    { lat: 25.214664, lng: 55.180605 },
    { lat: 25.215208, lng: 55.180605 },
    { lat: 25.215285, lng: 55.181077 },
];

export const the_world_pol_88 = [
    { lat: 25.217258, lng: 55.181721 },
    { lat: 25.217297, lng: 55.181721 },
    { lat: 25.217025, lng: 55.182451 },
    { lat: 25.216908, lng: 55.182622 },
    { lat: 25.216675, lng: 55.182837 },
    { lat: 25.216132, lng: 55.182665 },
    { lat: 25.215666, lng: 55.182665 },
    { lat: 25.21621, lng: 55.182279 },
    { lat: 25.215938, lng: 55.181936 },
    { lat: 25.216054, lng: 55.181378 },
    { lat: 25.216132, lng: 55.18112 },
    { lat: 25.216598, lng: 55.180949 },
    { lat: 25.216947, lng: 55.181035 },
    { lat: 25.217219, lng: 55.181635 },
];

export const the_world_pol_89 = [
    { lat: 25.217568, lng: 55.179661 },
    { lat: 25.217413, lng: 55.179919 },
    { lat: 25.217258, lng: 55.180133 },
    { lat: 25.216986, lng: 55.180176 },
    { lat: 25.216481, lng: 55.180133 },
    { lat: 25.215666, lng: 55.180005 },
    { lat: 25.215511, lng: 55.179747 },
    { lat: 25.215472, lng: 55.179447 },
    { lat: 25.216132, lng: 55.178717 },
    { lat: 25.216598, lng: 55.178631 },
    { lat: 25.217219, lng: 55.178889 },
    { lat: 25.217491, lng: 55.179618 },
];

export const the_world_pol_90 = [
    { lat: 25.218353, lng: 55.176829 },
    { lat: 25.218291, lng: 55.177515 },
    { lat: 25.217747, lng: 55.178159 },
    { lat: 25.217592, lng: 55.178288 },
    { lat: 25.217281, lng: 55.178116 },
    { lat: 25.216815, lng: 55.178073 },
    { lat: 25.216699, lng: 55.177859 },
    { lat: 25.216776, lng: 55.177301 },
    { lat: 25.216776, lng: 55.177 },
    { lat: 25.217126, lng: 55.176958 },
    { lat: 25.216971, lng: 55.176485 },
    { lat: 25.216971, lng: 55.176185 },
    { lat: 25.217281, lng: 55.176142 },
    { lat: 25.217553, lng: 55.176485 },
    { lat: 25.217747, lng: 55.176185 },
    { lat: 25.218252, lng: 55.176786 },
];

export const the_world_pol_91 = [
    { lat: 25.2193, lng: 55.175413 },
    { lat: 25.219191, lng: 55.175799 },
    { lat: 25.218648, lng: 55.176142 },
    { lat: 25.218337, lng: 55.176142 },
    { lat: 25.218065, lng: 55.176185 },
    { lat: 25.217949, lng: 55.175885 },
    { lat: 25.217444, lng: 55.175241 },
    { lat: 25.217405, lng: 55.175026 },
    { lat: 25.217405, lng: 55.174554 },
    { lat: 25.217716, lng: 55.174468 },
    { lat: 25.218609, lng: 55.174554 },
    { lat: 25.21923, lng: 55.175112 },
    { lat: 25.219308, lng: 55.175284 },
];

export const the_world_pol_92 = [
    { lat: 25.219867, lng: 55.171808 },
    { lat: 25.219595, lng: 55.172709 },
    { lat: 25.219362, lng: 55.173052 },
    { lat: 25.218702, lng: 55.173567 },
    { lat: 25.21812, lng: 55.173653 },
    { lat: 25.217964, lng: 55.172409 },
    { lat: 25.218314, lng: 55.172151 },
    { lat: 25.218857, lng: 55.171936 },
    { lat: 25.219323, lng: 55.17155 },
    { lat: 25.219712, lng: 55.171336 },
    { lat: 25.219828, lng: 55.171679 },
];

export const the_world_pol_93 = [
    { lat: 25.221381, lng: 55.170306 },
    { lat: 25.221265, lng: 55.170778 },
    { lat: 25.220089, lng: 55.171164 },
    { lat: 25.219778, lng: 55.171164 },
    { lat: 25.219429, lng: 55.170649 },
    { lat: 25.219856, lng: 55.170048 },
    { lat: 25.22005, lng: 55.169662 },
    { lat: 25.220516, lng: 55.169447 },
    { lat: 25.220943, lng: 55.169748 },
    { lat: 25.221409, lng: 55.170134 },
];

export const the_world_pol_94 = [
    { lat: 25.22335, lng: 55.170134 },
    { lat: 25.223117, lng: 55.170692 },
    { lat: 25.223001, lng: 55.170821 },
    { lat: 25.222191, lng: 55.17044 },
    { lat: 25.221802, lng: 55.169968 },
    { lat: 25.221841, lng: 55.169668 },
    { lat: 25.222229, lng: 55.169367 },
    { lat: 25.222734, lng: 55.169367 },
    { lat: 25.223433, lng: 55.169925 },
    { lat: 25.223316, lng: 55.170011 },
];

export const the_world_pol_95 = [
    { lat: 25.223045, lng: 55.171985 },
    { lat: 25.223006, lng: 55.1725 },
    { lat: 25.222851, lng: 55.172543 },
    { lat: 25.222695, lng: 55.172629 },
    { lat: 25.222462, lng: 55.172543 },
    { lat: 25.222152, lng: 55.172457 },
    { lat: 25.221841, lng: 55.172157 },
    { lat: 25.221531, lng: 55.171899 },
    { lat: 25.221414, lng: 55.171513 },
    { lat: 25.221764, lng: 55.171041 },
    { lat: 25.222462, lng: 55.17117 },
    { lat: 25.222773, lng: 55.171513 },
    { lat: 25.223006, lng: 55.171856 },
];

export const the_world_pol_96 = [
    { lat: 25.221958, lng: 55.172886 },
    { lat: 25.221608, lng: 55.173316 },
    { lat: 25.221414, lng: 55.173573 },
    { lat: 25.220599, lng: 55.173616 },
    { lat: 25.220016, lng: 55.173187 },
    { lat: 25.219822, lng: 55.173187 },
    { lat: 25.220016, lng: 55.172886 },
    { lat: 25.220172, lng: 55.172886 },
    { lat: 25.220638, lng: 55.172543 },
    { lat: 25.220948, lng: 55.172157 },
    { lat: 25.221414, lng: 55.172243 },
    { lat: 25.22188, lng: 55.172715 },
];

export const the_world_pol_97 = [
    { lat: 25.221181, lng: 55.175976 },
    { lat: 25.220638, lng: 55.176963 },
    { lat: 25.220366, lng: 55.177178 },
    { lat: 25.219745, lng: 55.177178 },
    { lat: 25.219551, lng: 55.177049 },
    { lat: 25.219589, lng: 55.176835 },
    { lat: 25.219861, lng: 55.176234 },
    { lat: 25.219745, lng: 55.175976 },
    { lat: 25.220016, lng: 55.175633 },
    { lat: 25.220133, lng: 55.175376 },
    { lat: 25.220444, lng: 55.175118 },
    { lat: 25.220793, lng: 55.175032 },
    { lat: 25.221181, lng: 55.175805 },
];

export const the_world_pol_98 = [
    { lat: 25.219993, lng: 55.178895 },
    { lat: 25.219683, lng: 55.179753 },
    { lat: 25.219372, lng: 55.180268 },
    { lat: 25.219139, lng: 55.180568 },
    { lat: 25.218246, lng: 55.179839 },
    { lat: 25.218246, lng: 55.179581 },
    { lat: 25.218363, lng: 55.179066 },
    { lat: 25.218906, lng: 55.17868 },
    { lat: 25.219294, lng: 55.17838 },
    { lat: 25.219877, lng: 55.178594 },
    { lat: 25.220032, lng: 55.179023 },
];

export const the_world_pol_99 = [
    { lat: 25.219411, lng: 55.180783 },
    { lat: 25.219683, lng: 55.180955 },
    { lat: 25.219838, lng: 55.181469 },
    { lat: 25.219838, lng: 55.181984 },
    { lat: 25.219294, lng: 55.182371 },
    { lat: 25.219061, lng: 55.182714 },
    { lat: 25.218828, lng: 55.182843 },
    { lat: 25.217867, lng: 55.182124 },
    { lat: 25.2181, lng: 55.181351 },
    { lat: 25.219187, lng: 55.181051 },
    { lat: 25.219265, lng: 55.180836 },
];

export const the_world_pol_100 = [
    { lat: 25.220872, lng: 55.184141 },
    { lat: 25.219979, lng: 55.185257 },
    { lat: 25.218931, lng: 55.1856 },
    { lat: 25.218465, lng: 55.185471 },
    { lat: 25.218698, lng: 55.18457 },
    { lat: 25.219319, lng: 55.184184 },
    { lat: 25.219785, lng: 55.183583 },
    { lat: 25.220406, lng: 55.18354 },
    { lat: 25.220872, lng: 55.183712 },
    { lat: 25.220911, lng: 55.184012 },
];

export const the_world_pol_101 = [
    { lat: 25.224666, lng: 55.178546 },
    { lat: 25.224549, lng: 55.178717 },
    { lat: 25.223772, lng: 55.179233 },
    { lat: 25.2235, lng: 55.179361 },
    { lat: 25.223073, lng: 55.179275 },
    { lat: 25.222855, lng: 55.179147 },
    { lat: 25.222855, lng: 55.178589 },
    { lat: 25.223321, lng: 55.17816 },
    { lat: 25.223321, lng: 55.177773 },
    { lat: 25.223632, lng: 55.177516 },
    { lat: 25.223981, lng: 55.177516 },
    { lat: 25.224719, lng: 55.178331 },
];

export const the_world_pol_102 = [
    { lat: 25.226272, lng: 55.183438 },
    { lat: 25.226272, lng: 55.183009 },
    { lat: 25.226233, lng: 55.182537 },
    { lat: 25.225563, lng: 55.182419 },
    { lat: 25.225408, lng: 55.183234 },
    { lat: 25.225641, lng: 55.183578 },
    { lat: 25.225913, lng: 55.183621 },
    { lat: 25.226184, lng: 55.183406 },
];

export const the_world_pol_103 = [
    { lat: 25.225951, lng: 55.184694 },
    { lat: 25.225486, lng: 55.185552 },
    { lat: 25.224981, lng: 55.185595 },
    { lat: 25.224437, lng: 55.185337 },
    { lat: 25.224554, lng: 55.184436 },
    { lat: 25.224864, lng: 55.184264 },
    { lat: 25.225641, lng: 55.184093 },
    { lat: 25.22599, lng: 55.184608 },
];

export const the_world_pol_104 = [
    { lat: 25.224981, lng: 55.182548 },
    { lat: 25.224593, lng: 55.183535 },
    { lat: 25.22467, lng: 55.183921 },
    { lat: 25.224554, lng: 55.183921 },
    { lat: 25.224127, lng: 55.183578 },
    { lat: 25.223816, lng: 55.182977 },
    { lat: 25.223971, lng: 55.182011 },
    { lat: 25.224476, lng: 55.18184 },
    { lat: 25.22502, lng: 55.182483 },
];

export const the_world_pol_105 = [
    { lat: 25.248624, lng: 55.166679 },
    { lat: 25.248561, lng: 55.166893 },
    { lat: 25.248406, lng: 55.167451 },
    { lat: 25.246575, lng: 55.169004 },
    { lat: 25.246048, lng: 55.16879 },
    { lat: 25.245932, lng: 55.168403 },
    { lat: 25.246087, lng: 55.168146 },
    { lat: 25.246475, lng: 55.168017 },
    { lat: 25.246631, lng: 55.167588 },
    { lat: 25.246359, lng: 55.166944 },
    { lat: 25.245932, lng: 55.165957 },
    { lat: 25.244791, lng: 55.164927 },
    { lat: 25.245023, lng: 55.164026 },
    { lat: 25.247663, lng: 55.165828 },
    { lat: 25.2484, lng: 55.166258 },
    { lat: 25.248517, lng: 55.166472 },
    { lat: 25.248595, lng: 55.166386 },
    { lat: 25.248595, lng: 55.166515 },
];

export const the_world_pol_106 = [
    { lat: 25.248315, lng: 55.162653 },
    { lat: 25.247306, lng: 55.164069 },
    { lat: 25.247073, lng: 55.164284 },
    { lat: 25.246452, lng: 55.164241 },
    { lat: 25.246413, lng: 55.163726 },
    { lat: 25.246374, lng: 55.163082 },
    { lat: 25.246374, lng: 55.162696 },
    { lat: 25.246956, lng: 55.161752 },
    { lat: 25.247461, lng: 55.16158 },
    { lat: 25.247849, lng: 55.161709 },
    { lat: 25.248315, lng: 55.162395 },
    { lat: 25.248315, lng: 55.162481 },
    { lat: 25.24847, lng: 55.162524 },
];

export const the_world_pol_107 = [
    { lat: 25.244286, lng: 55.163597 },
    { lat: 25.244364, lng: 55.164284 },
    { lat: 25.243859, lng: 55.164927 },
    { lat: 25.243471, lng: 55.164841 },
    { lat: 25.243044, lng: 55.164412 },
    { lat: 25.24316, lng: 55.16394 },
    { lat: 25.243471, lng: 55.163211 },
    { lat: 25.244169, lng: 55.163511 },
];

export const the_world_pol_108 = [
    { lat: 25.246576, lng: 55.15952 },
    { lat: 25.246498, lng: 55.160507 },
    { lat: 25.245761, lng: 55.161494 },
    { lat: 25.24545, lng: 55.162395 },
    { lat: 25.244402, lng: 55.162095 },
    { lat: 25.24382, lng: 55.161794 },
    { lat: 25.243626, lng: 55.161194 },
    { lat: 25.243199, lng: 55.160121 },
    { lat: 25.24382, lng: 55.159348 },
    { lat: 25.245334, lng: 55.158833 },
    { lat: 25.246498, lng: 55.15952 },
];

export const the_world_pol_109 = [
    { lat: 25.242035, lng: 55.158104 },
    { lat: 25.241918, lng: 55.158361 },
    { lat: 25.241452, lng: 55.158447 },
    { lat: 25.239977, lng: 55.158361 },
    { lat: 25.239162, lng: 55.158662 },
    { lat: 25.238813, lng: 55.158533 },
    { lat: 25.238619, lng: 55.158104 },
    { lat: 25.23889, lng: 55.157675 },
    { lat: 25.240909, lng: 55.157589 },
    { lat: 25.241996, lng: 55.157975 },
];

export const the_world_pol_110 = [
    { lat: 25.238277, lng: 55.159005 },
    { lat: 25.238021, lng: 55.15952 },
    { lat: 25.237555, lng: 55.159906 },
    { lat: 25.237516, lng: 55.160378 },
    { lat: 25.237128, lng: 55.160335 },
    { lat: 25.236895, lng: 55.159563 },
    { lat: 25.237128, lng: 55.158962 },
    { lat: 25.238021, lng: 55.158576 },
    { lat: 25.238331, lng: 55.158747 },
    { lat: 25.238448, lng: 55.158833 },
];

export const the_world_pol_111 = [
    { lat: 25.242128, lng: 55.163125 },
    { lat: 25.242027, lng: 55.163554 },
    { lat: 25.242104, lng: 55.164155 },
    { lat: 25.242066, lng: 55.164326 },
    { lat: 25.241017, lng: 55.164627 },
    { lat: 25.239581, lng: 55.164584 },
    { lat: 25.23896, lng: 55.164584 },
    { lat: 25.238611, lng: 55.164455 },
    { lat: 25.238455, lng: 55.162824 },
    { lat: 25.238611, lng: 55.162481 },
    { lat: 25.239969, lng: 55.162781 },
    { lat: 25.242027, lng: 55.163254 },
];

export const the_world_pol_112 = [
    { lat: 25.241134, lng: 55.1657 },
    { lat: 25.241173, lng: 55.165828 },
    { lat: 25.240862, lng: 55.166258 },
    { lat: 25.239853, lng: 55.16673 },
    { lat: 25.239077, lng: 55.166215 },
    { lat: 25.238805, lng: 55.165571 },
    { lat: 25.239309, lng: 55.164884 },
    { lat: 25.239698, lng: 55.165185 },
    { lat: 25.24028, lng: 55.165228 },
    { lat: 25.240629, lng: 55.165314 },
    { lat: 25.24125, lng: 55.165528 },
];

export const the_world_pol_113 = [
    { lat: 25.23764, lng: 55.163983 },
    { lat: 25.237609, lng: 55.164112 },
    { lat: 25.237493, lng: 55.162996 },
    { lat: 25.237454, lng: 55.162395 },
    { lat: 25.237454, lng: 55.162009 },
    { lat: 25.235618, lng: 55.161169 },
    { lat: 25.233813, lng: 55.160912 },
    { lat: 25.233658, lng: 55.161126 },
    { lat: 25.233852, lng: 55.161856 },
    { lat: 25.233891, lng: 55.162414 },
    { lat: 25.234396, lng: 55.162671 },
    { lat: 25.235133, lng: 55.163014 },
    { lat: 25.235832, lng: 55.163358 },
    { lat: 25.236104, lng: 55.164044 },
    { lat: 25.236841, lng: 55.164044 },
    { lat: 25.23754, lng: 55.164259 },
];

export const the_world_pol_114 = [
    { lat: 25.237517, lng: 55.16589 },
    { lat: 25.236826, lng: 55.167048 },
    { lat: 25.23601, lng: 55.166963 },
    { lat: 25.235583, lng: 55.166276 },
    { lat: 25.235933, lng: 55.165117 },
    { lat: 25.23667, lng: 55.16516 },
    { lat: 25.237369, lng: 55.165418 },
    { lat: 25.237369, lng: 55.165761 },
    { lat: 25.237563, lng: 55.165933 },
];

export const the_world_pol_115 = [
    { lat: 25.235646, lng: 55.164774 },
    { lat: 25.235288, lng: 55.165546 },
    { lat: 25.234512, lng: 55.166233 },
    { lat: 25.233891, lng: 55.165289 },
    { lat: 25.234163, lng: 55.164044 },
    { lat: 25.23459, lng: 55.163615 },
    { lat: 25.235211, lng: 55.163658 },
    { lat: 25.235444, lng: 55.163787 },
    { lat: 25.235599, lng: 55.164731 },
];

export const the_world_pol_116 = [
    { lat: 25.23292, lng: 55.162242 },
    { lat: 25.232408, lng: 55.1631 },
    { lat: 25.231632, lng: 55.163272 },
    { lat: 25.230855, lng: 55.161899 },
    { lat: 25.228681, lng: 55.161512 },
    { lat: 25.228293, lng: 55.160654 },
    { lat: 25.228836, lng: 55.159967 },
    { lat: 25.229846, lng: 55.159367 },
    { lat: 25.230583, lng: 55.159538 },
    { lat: 25.231127, lng: 55.160182 },
    { lat: 25.231709, lng: 55.160826 },
    { lat: 25.232486, lng: 55.160826 },
    { lat: 25.232796, lng: 55.161384 },
    { lat: 25.233029, lng: 55.16207 },
];

export const the_world_pol_117 = [
    { lat: 25.2332, lng: 55.16001 },
    { lat: 25.2332, lng: 55.16001 },
    { lat: 25.23285, lng: 55.160525 },
    { lat: 25.232152, lng: 55.160482 },
    { lat: 25.231958, lng: 55.160053 },
    { lat: 25.23188, lng: 55.159796 },
    { lat: 25.23188, lng: 55.159495 },
    { lat: 25.232385, lng: 55.159109 },
    { lat: 25.232695, lng: 55.159324 },
    { lat: 25.233122, lng: 55.159839 },
];

export const the_world_pol_118 = [
    { lat: 25.233743, lng: 55.157264 },
    { lat: 25.233588, lng: 55.15765 },
    { lat: 25.232346, lng: 55.157779 },
    { lat: 25.231802, lng: 55.157178 },
    { lat: 25.231919, lng: 55.156792 },
    { lat: 25.232501, lng: 55.15632 },
    { lat: 25.233316, lng: 55.156706 },
    { lat: 25.233899, lng: 55.157092 },
];

export const the_world_pol_119 = [
    { lat: 25.230327, lng: 55.157822 },
    { lat: 25.229489, lng: 55.158294 },
    { lat: 25.228246, lng: 55.158723 },
    { lat: 25.227625, lng: 55.15838 },
    { lat: 25.227353, lng: 55.158036 },
    { lat: 25.227625, lng: 55.157736 },
    { lat: 25.228518, lng: 55.157478 },
    { lat: 25.229256, lng: 55.157264 },
    { lat: 25.229799, lng: 55.157178 },
    { lat: 25.230187, lng: 55.157307 },
    { lat: 25.230459, lng: 55.157607 },
];

export const the_world_pol_120 = [
    { lat: 25.209699, lng: 55.167575 },
    { lat: 25.209583, lng: 55.168004 },
    { lat: 25.209117, lng: 55.168218 },
    { lat: 25.207717, lng: 55.168603 },
    { lat: 25.207368, lng: 55.168088 },
    { lat: 25.207795, lng: 55.167701 },
    { lat: 25.208455, lng: 55.167229 },
    { lat: 25.209038, lng: 55.166972 },
    { lat: 25.209387, lng: 55.166929 },
    { lat: 25.209814, lng: 55.167487 },
];

export const the_world_pol_121 = [
    { lat: 25.208634, lng: 55.166285 },
    { lat: 25.208634, lng: 55.165899 },
    { lat: 25.20844, lng: 55.165384 },
    { lat: 25.208129, lng: 55.164569 },
    { lat: 25.207624, lng: 55.16444 },
    { lat: 25.206848, lng: 55.16444 },
    { lat: 25.207003, lng: 55.165556 },
    { lat: 25.207275, lng: 55.166328 },
    { lat: 25.208129, lng: 55.166671 },
    { lat: 25.20844, lng: 55.166457 },
    { lat: 25.208478, lng: 55.166371 },
];

export const the_world_pol_122 = [
    { lat: 25.20743, lng: 55.162895 },
    { lat: 25.206886, lng: 55.163667 },
    { lat: 25.20642, lng: 55.16341 },
    { lat: 25.206032, lng: 55.162981 },
    { lat: 25.205877, lng: 55.16268 },
    { lat: 25.20611, lng: 55.162037 },
    { lat: 25.206615, lng: 55.161393 },
    { lat: 25.206886, lng: 55.161393 },
    { lat: 25.207081, lng: 55.161951 },
    { lat: 25.207547, lng: 55.162637 },
];

export const the_world_pol_123 = [
    { lat: 25.20875, lng: 55.160706 },
    { lat: 25.20844, lng: 55.161092 },
    { lat: 25.208284, lng: 55.16135 },
    { lat: 25.207779, lng: 55.160406 },
    { lat: 25.207663, lng: 55.159977 },
    { lat: 25.208168, lng: 55.159805 },
    { lat: 25.208673, lng: 55.160492 },
    { lat: 25.20875, lng: 55.160577 },
];

export const the_world_pol_124 = [
    { lat: 25.206855, lng: 55.158475 },
    { lat: 25.206809, lng: 55.158646 },
    { lat: 25.206304, lng: 55.15826 },
    { lat: 25.205955, lng: 55.157788 },
    { lat: 25.205799, lng: 55.157316 },
    { lat: 25.20611, lng: 55.15723 },
    { lat: 25.20642, lng: 55.157402 },
    { lat: 25.206731, lng: 55.157745 },
    { lat: 25.207003, lng: 55.158303 },
];

export const the_world_pol_125 = [
    { lat: 25.206576, lng: 55.160663 },
    { lat: 25.205978, lng: 55.161264 },
    { lat: 25.20559, lng: 55.161393 },
    { lat: 25.204929, lng: 55.160835 },
    { lat: 25.204502, lng: 55.160577 },
    { lat: 25.204308, lng: 55.160277 },
    { lat: 25.204308, lng: 55.159462 },
    { lat: 25.205434, lng: 55.159118 },
    { lat: 25.205823, lng: 55.159204 },
    { lat: 25.206638, lng: 55.160492 },
];

export const the_world_pol_126 = [
    { lat: 25.203998, lng: 55.162337 },
    { lat: 25.203803, lng: 55.162466 },
    { lat: 25.203066, lng: 55.162552 },
    { lat: 25.202017, lng: 55.161693 },
    { lat: 25.201978, lng: 55.161307 },
    { lat: 25.202134, lng: 55.161092 },
    { lat: 25.202522, lng: 55.160921 },
    { lat: 25.20291, lng: 55.160449 },
    { lat: 25.203221, lng: 55.160406 },
    { lat: 25.203648, lng: 55.160878 },
    { lat: 25.203998, lng: 55.162165 },
];

export const the_world_pol_127 = [
    { lat: 25.2059, lng: 55.164268 },
    { lat: 25.206024, lng: 55.164097 },
    { lat: 25.20583, lng: 55.164869 },
    { lat: 25.205364, lng: 55.164783 },
    { lat: 25.20486, lng: 55.164912 },
    { lat: 25.204277, lng: 55.16577 },
    { lat: 25.203656, lng: 55.165599 },
    { lat: 25.203578, lng: 55.164998 },
    { lat: 25.203889, lng: 55.164054 },
    { lat: 25.204898, lng: 55.162723 },
    { lat: 25.205442, lng: 55.162809 },
    { lat: 25.205675, lng: 55.163753 },
    { lat: 25.205986, lng: 55.163968 },
];

export const the_world_pol_128 = [
    { lat: 25.206545, lng: 55.166629 },
    { lat: 25.206351, lng: 55.167015 },
    { lat: 25.205458, lng: 55.167787 },
    { lat: 25.205186, lng: 55.167186 },
    { lat: 25.204914, lng: 55.1665 },
    { lat: 25.205108, lng: 55.165642 },
    { lat: 25.205574, lng: 55.165599 },
    { lat: 25.206001, lng: 55.166028 },
    { lat: 25.206273, lng: 55.166414 },
    { lat: 25.206545, lng: 55.166457 },
];

export const the_world_pol_129 = [
    { lat: 25.204328, lng: 55.168276 },
    { lat: 25.204405, lng: 55.167761 },
    { lat: 25.20425, lng: 55.166645 },
    { lat: 25.203745, lng: 55.166388 },
    { lat: 25.203163, lng: 55.166388 },
    { lat: 25.202813, lng: 55.166903 },
    { lat: 25.202813, lng: 55.167332 },
    { lat: 25.203318, lng: 55.167976 },
    { lat: 25.203745, lng: 55.168619 },
    { lat: 25.20425, lng: 55.16892 },
    { lat: 25.20425, lng: 55.168534 },
    { lat: 25.204405, lng: 55.168319 },
];

export const the_world_pol_130 = [
    { lat: 25.206269, lng: 55.169392 },
    { lat: 25.205997, lng: 55.169864 },
    { lat: 25.204211, lng: 55.170164 },
    { lat: 25.203862, lng: 55.169993 },
    { lat: 25.204095, lng: 55.169349 },
    { lat: 25.204794, lng: 55.169263 },
    { lat: 25.206075, lng: 55.168705 },
    { lat: 25.206347, lng: 55.169177 },
];

export const the_world_pol_131 = [
    { lat: 25.203349, lng: 55.170293 },
    { lat: 25.202961, lng: 55.170894 },
    { lat: 25.202262, lng: 55.170593 },
    { lat: 25.201874, lng: 55.170207 },
    { lat: 25.202068, lng: 55.169735 },
    { lat: 25.202534, lng: 55.16922 },
    { lat: 25.202806, lng: 55.169349 },
    { lat: 25.203349, lng: 55.170207 },
];

export const the_world_pol_132 = [
    { lat: 25.202107, lng: 55.168491 },
    { lat: 25.201718, lng: 55.169563 },
    { lat: 25.200825, lng: 55.170894 },
    { lat: 25.200165, lng: 55.171151 },
    { lat: 25.199777, lng: 55.170636 },
    { lat: 25.200359, lng: 55.169735 },
    { lat: 25.201058, lng: 55.169049 },
    { lat: 25.201641, lng: 55.167976 },
    { lat: 25.202068, lng: 55.168319 },
];

export const the_world_pol_133 = [
    { lat: 25.201175, lng: 55.167031 },
    { lat: 25.200616, lng: 55.168147 },
    { lat: 25.199761, lng: 55.168319 },
    { lat: 25.199489, lng: 55.168233 },
    { lat: 25.199412, lng: 55.167761 },
    { lat: 25.200616, lng: 55.166602 },
    { lat: 25.200771, lng: 55.166602 },
    { lat: 25.201004, lng: 55.166645 },
    { lat: 25.201314, lng: 55.166903 },
];

export const the_world_pol_134 = [
    { lat: 25.202945, lng: 55.164671 },
    { lat: 25.202542, lng: 55.165272 },
    { lat: 25.201804, lng: 55.165229 },
    { lat: 25.200949, lng: 55.164027 },
    { lat: 25.201182, lng: 55.163512 },
    { lat: 25.201959, lng: 55.163169 },
    { lat: 25.202542, lng: 55.163899 },
    { lat: 25.202852, lng: 55.164371 },
    { lat: 25.202891, lng: 55.164585 },
];

export const the_world_pol_135 = [
    { lat: 25.199404, lng: 55.17128 },
    { lat: 25.199404, lng: 55.171709 },
    { lat: 25.19886, lng: 55.172439 },
    { lat: 25.198472, lng: 55.172482 },
    { lat: 25.198084, lng: 55.17201 },
    { lat: 25.198239, lng: 55.171151 },
    { lat: 25.198472, lng: 55.171194 },
    { lat: 25.198938, lng: 55.170937 },
    { lat: 25.199326, lng: 55.171366 },
];

export const the_world_pol_136 = [
    { lat: 25.199862, lng: 55.174756 },
    { lat: 25.199319, lng: 55.175271 },
    { lat: 25.198387, lng: 55.174327 },
    { lat: 25.19827, lng: 55.173683 },
    { lat: 25.198891, lng: 55.173683 },
    { lat: 25.199513, lng: 55.174155 },
    { lat: 25.199901, lng: 55.17467 },
];

export const the_world_pol_137 = [
    { lat: 25.197695, lng: 55.173426 },
    { lat: 25.197579, lng: 55.174198 },
    { lat: 25.197191, lng: 55.174327 },
    { lat: 25.19688, lng: 55.174155 },
    { lat: 25.196686, lng: 55.173812 },
    { lat: 25.196919, lng: 55.173297 },
    { lat: 25.19754, lng: 55.172739 },
    { lat: 25.197695, lng: 55.173297 },
];

export const the_world_pol_138 = [
    { lat: 25.19688, lng: 55.172396 },
    { lat: 25.196259, lng: 55.173941 },
    { lat: 25.19622, lng: 55.174456 },
    { lat: 25.195715, lng: 55.174456 },
    { lat: 25.195404, lng: 55.173125 },
    { lat: 25.195676, lng: 55.172224 },
    { lat: 25.196297, lng: 55.171838 },
    { lat: 25.196763, lng: 55.172095 },
    { lat: 25.196919, lng: 55.172138 },
    { lat: 25.196919, lng: 55.17231 },
];

export const the_world_pol_139 = [
    { lat: 25.197602, lng: 55.170121 },
    { lat: 25.197641, lng: 55.170508 },
    { lat: 25.197758, lng: 55.170765 },
    { lat: 25.197447, lng: 55.171151 },
    { lat: 25.196787, lng: 55.171666 },
    { lat: 25.196282, lng: 55.171237 },
    { lat: 25.196903, lng: 55.170207 },
    { lat: 25.197292, lng: 55.16995 },
    { lat: 25.197719, lng: 55.16995 },
];

export const the_world_pol_140 = [
    { lat: 25.199272, lng: 55.168877 },
    { lat: 25.199311, lng: 55.169263 },
    { lat: 25.19834, lng: 55.170164 },
    { lat: 25.197913, lng: 55.170036 },
    { lat: 25.197719, lng: 55.169821 },
    { lat: 25.198029, lng: 55.169049 },
    { lat: 25.198534, lng: 55.168662 },
    { lat: 25.199272, lng: 55.168662 },
    { lat: 25.199388, lng: 55.168748 },
];

export const the_world_pol_141 = [
    { lat: 25.231785, lng: 55.138509 },
    { lat: 25.231506, lng: 55.13941 },
    { lat: 25.231467, lng: 55.139625 },
    { lat: 25.23104, lng: 55.139324 },
    { lat: 25.231001, lng: 55.138767 },
    { lat: 25.231195, lng: 55.13838 },
    { lat: 25.230535, lng: 55.138724 },
    { lat: 25.230302, lng: 55.138981 },
    { lat: 25.229487, lng: 55.138423 },
    { lat: 25.229332, lng: 55.13808 },
    { lat: 25.229448, lng: 55.137565 },
    { lat: 25.230186, lng: 55.137222 },
    { lat: 25.230613, lng: 55.137265 },
    { lat: 25.231273, lng: 55.137994 },
    { lat: 25.231816, lng: 55.138595 },
];

export const the_world_pol_142 = [
    { lat: 25.233105, lng: 55.143573 },
    { lat: 25.233027, lng: 55.144002 },
    { lat: 25.232406, lng: 55.144217 },
    { lat: 25.23194, lng: 55.144131 },
    { lat: 25.231591, lng: 55.143573 },
    { lat: 25.231475, lng: 55.14323 },
    { lat: 25.231436, lng: 55.142543 },
    { lat: 25.23194, lng: 55.142286 },
    { lat: 25.232523, lng: 55.1422 },
    { lat: 25.233144, lng: 55.143316 },
    { lat: 25.233183, lng: 55.143659 },
];

export const the_world_pol_143 = [
    { lat: 25.231048, lng: 55.141985 },
    { lat: 25.231009, lng: 55.142286 },
    { lat: 25.230504, lng: 55.142586 },
    { lat: 25.229961, lng: 55.142801 },
    { lat: 25.229766, lng: 55.142243 },
    { lat: 25.229689, lng: 55.141942 },
    { lat: 25.229689, lng: 55.141256 },
    { lat: 25.230232, lng: 55.140569 },
    { lat: 25.230582, lng: 55.140827 },
    { lat: 25.231086, lng: 55.141814 },
];

export const the_world_pol_144 = [
    { lat: 25.230116, lng: 55.145418 },
    { lat: 25.229495, lng: 55.146105 },
    { lat: 25.229262, lng: 55.146105 },
    { lat: 25.228506, lng: 55.145237 },
    { lat: 25.227962, lng: 55.145022 },
    { lat: 25.228661, lng: 55.143348 },
    { lat: 25.228739, lng: 55.14279 },
    { lat: 25.228195, lng: 55.141975 },
    { lat: 25.227807, lng: 55.141417 },
    { lat: 25.228506, lng: 55.140301 },
    { lat: 25.229282, lng: 55.140719 },
    { lat: 25.229399, lng: 55.141191 },
    { lat: 25.229399, lng: 55.142393 },
    { lat: 25.229399, lng: 55.142736 },
    { lat: 25.229748, lng: 55.142993 },
    { lat: 25.230447, lng: 55.143551 },
    { lat: 25.230447, lng: 55.144152 },
    { lat: 25.230136, lng: 55.144882 },
    { lat: 25.230136, lng: 55.145268 },
];

export const the_world_pol_145 = [
    { lat: 25.228545, lng: 55.138702 },
    { lat: 25.228389, lng: 55.13956 },
    { lat: 25.227962, lng: 55.139517 },
    { lat: 25.226836, lng: 55.139174 },
    { lat: 25.226526, lng: 55.138917 },
    { lat: 25.226565, lng: 55.138359 },
    { lat: 25.227147, lng: 55.137929 },
    { lat: 25.227885, lng: 55.138316 },
    { lat: 25.228506, lng: 55.138659 },
];

export const the_world_pol_146 = [
    { lat: 25.227962, lng: 55.140247 },
    { lat: 25.22804, lng: 55.140461 },
    { lat: 25.227652, lng: 55.140934 },
    { lat: 25.227225, lng: 55.140848 },
    { lat: 25.226409, lng: 55.140504 },
    { lat: 25.226099, lng: 55.139689 },
    { lat: 25.226759, lng: 55.139431 },
    { lat: 25.227885, lng: 55.140032 },
];

export const the_world_pol_147 = [
    { lat: 25.226992, lng: 55.14132 },
    { lat: 25.226681, lng: 55.142221 },
    { lat: 25.22606, lng: 55.142436 },
    { lat: 25.225633, lng: 55.142178 },
    { lat: 25.225633, lng: 55.141706 },
    { lat: 25.226409, lng: 55.140719 },
    { lat: 25.226759, lng: 55.140719 },
    { lat: 25.226914, lng: 55.141062 },
    { lat: 25.226992, lng: 55.141191 },
];

export const the_world_pol_148 = [
    { lat: 25.228195, lng: 55.143294 },
    { lat: 25.228234, lng: 55.143766 },
    { lat: 25.227496, lng: 55.144195 },
    { lat: 25.226254, lng: 55.143423 },
    { lat: 25.226448, lng: 55.142822 },
    { lat: 25.226953, lng: 55.142564 },
    { lat: 25.227535, lng: 55.142564 },
    { lat: 25.228428, lng: 55.143079 },
];

export const the_world_pol_149 = [
    { lat: 25.22863, lng: 55.146384 },
    { lat: 25.228513, lng: 55.146684 },
    { lat: 25.22731, lng: 55.149731 },
    { lat: 25.226922, lng: 55.149817 },
    { lat: 25.226495, lng: 55.149002 },
    { lat: 25.226145, lng: 55.147972 },
    { lat: 25.226844, lng: 55.146598 },
    { lat: 25.227582, lng: 55.145611 },
    { lat: 25.227737, lng: 55.145568 },
    { lat: 25.228475, lng: 55.145869 },
    { lat: 25.228669, lng: 55.146298 },
];

export const the_world_pol_150 = [
    { lat: 25.226759, lng: 55.14544 },
    { lat: 25.226759, lng: 55.146169 },
    { lat: 25.226021, lng: 55.146255 },
    { lat: 25.22571, lng: 55.14574 },
    { lat: 25.226099, lng: 55.144882 },
    { lat: 25.226642, lng: 55.144839 },
    { lat: 25.226914, lng: 55.145225 },
];

export const the_world_pol_151 = [
    { lat: 25.225516, lng: 55.144839 },
    { lat: 25.225283, lng: 55.145139 },
    { lat: 25.224701, lng: 55.145568 },
    { lat: 25.224274, lng: 55.14544 },
    { lat: 25.224235, lng: 55.144667 },
    { lat: 25.224507, lng: 55.143938 },
    { lat: 25.22505, lng: 55.143938 },
    { lat: 25.225439, lng: 55.14471 },
];

export const the_world_pol_152 = [
    { lat: 25.225633, lng: 55.147542 },
    { lat: 25.225167, lng: 55.148015 },
    { lat: 25.225089, lng: 55.148186 },
    { lat: 25.223886, lng: 55.147371 },
    { lat: 25.22373, lng: 55.146813 },
    { lat: 25.22408, lng: 55.146513 },
    { lat: 25.224856, lng: 55.14647 },
    { lat: 25.225245, lng: 55.146727 },
    { lat: 25.225322, lng: 55.147585 },
];

export const the_world_pol_153 = [
    { lat: 25.226021, lng: 55.150547 },
    { lat: 25.225167, lng: 55.151534 },
    { lat: 25.22408, lng: 55.151577 },
    { lat: 25.223808, lng: 55.151362 },
    { lat: 25.223769, lng: 55.150117 },
    { lat: 25.223925, lng: 55.149989 },
    { lat: 25.22408, lng: 55.149989 },
    { lat: 25.224662, lng: 55.149388 },
    { lat: 25.225012, lng: 55.149431 },
    { lat: 25.225943, lng: 55.150332 },
];

export const the_world_pol_154 = [
    { lat: 25.223148, lng: 55.150246 },
    { lat: 25.222799, lng: 55.150847 },
    { lat: 25.221479, lng: 55.151362 },
    { lat: 25.221129, lng: 55.150504 },
    { lat: 25.222255, lng: 55.149774 },
    { lat: 25.222488, lng: 55.149688 },
    { lat: 25.223187, lng: 55.150332 },
];

export const the_world_pol_155 = [
    { lat: 25.220663, lng: 55.151276 },
    { lat: 25.219965, lng: 55.151963 },
    { lat: 25.218994, lng: 55.152435 },
    { lat: 25.218489, lng: 55.151577 },
    { lat: 25.218877, lng: 55.150718 },
    { lat: 25.219848, lng: 55.150589 },
    { lat: 25.220314, lng: 55.150933 },
    { lat: 25.220741, lng: 55.15119 },
];

export const the_world_pol_156 = [
    { lat: 25.220819, lng: 55.148272 },
    { lat: 25.22078, lng: 55.148787 },
    { lat: 25.220547, lng: 55.149173 },
    { lat: 25.219382, lng: 55.149688 },
    { lat: 25.219072, lng: 55.149688 },
    { lat: 25.218761, lng: 55.149302 },
    { lat: 25.219382, lng: 55.148272 },
    { lat: 25.219926, lng: 55.147972 },
    { lat: 25.220896, lng: 55.148229 },
];

export const the_world_pol_157 = [
    { lat: 25.221712, lng: 55.145525 },
    { lat: 25.221518, lng: 55.146083 },
    { lat: 25.221323, lng: 55.146384 },
    { lat: 25.220625, lng: 55.146341 },
    { lat: 25.22043, lng: 55.145826 },
    { lat: 25.221246, lng: 55.14501 },
    { lat: 25.221595, lng: 55.145053 },
    { lat: 25.221789, lng: 55.145354 },
];

export const the_world_pol_158 = [
    { lat: 25.223109, lng: 55.146126 },
    { lat: 25.22307, lng: 55.146513 },
    { lat: 25.222643, lng: 55.146598 },
    { lat: 25.222488, lng: 55.147113 },
    { lat: 25.221828, lng: 55.147886 },
    { lat: 25.221479, lng: 55.147757 },
    { lat: 25.221362, lng: 55.147242 },
    { lat: 25.22175, lng: 55.146727 },
    { lat: 25.222178, lng: 55.146513 },
    { lat: 25.222488, lng: 55.145869 },
    { lat: 25.22307, lng: 55.145998 },
];

export const the_world_pol_159 = [
    { lat: 25.224546, lng: 55.140633 },
    { lat: 25.224507, lng: 55.141191 },
    { lat: 25.224352, lng: 55.142135 },
    { lat: 25.223187, lng: 55.141577 },
    { lat: 25.222876, lng: 55.140891 },
    { lat: 25.223109, lng: 55.140247 },
    { lat: 25.223963, lng: 55.140161 },
    { lat: 25.224235, lng: 55.140161 },
    { lat: 25.224585, lng: 55.140504 },
];

export const the_world_pol_160 = [
    { lat: 25.222449, lng: 55.139474 },
    { lat: 25.222333, lng: 55.139989 },
    { lat: 25.222022, lng: 55.140376 },
    { lat: 25.220858, lng: 55.139989 },
    { lat: 25.220469, lng: 55.139174 },
    { lat: 25.220159, lng: 55.139217 },
    { lat: 25.220159, lng: 55.138959 },
    { lat: 25.220469, lng: 55.138959 },
    { lat: 25.221323, lng: 55.138616 },
    { lat: 25.222139, lng: 55.138573 },
    { lat: 25.222449, lng: 55.139389 },
];

export const the_world_pol_161 = [
    { lat: 25.221657, lng: 55.141706 },
    { lat: 25.221502, lng: 55.142006 },
    { lat: 25.220337, lng: 55.141406 },
    { lat: 25.220143, lng: 55.141105 },
    { lat: 25.220298, lng: 55.140547 },
    { lat: 25.220764, lng: 55.140376 },
    { lat: 25.221153, lng: 55.140633 },
    { lat: 25.221618, lng: 55.141062 },
    { lat: 25.221774, lng: 55.141663 },
];

export const the_world_pol_162 = [
    { lat: 25.22512, lng: 55.136642 },
    { lat: 25.22512, lng: 55.136299 },
    { lat: 25.225276, lng: 55.134882 },
    { lat: 25.225547, lng: 55.13441 },
    { lat: 25.225353, lng: 55.13381 },
    { lat: 25.224926, lng: 55.133509 },
    { lat: 25.224732, lng: 55.133638 },
    { lat: 25.222554, lng: 55.133204 },
    { lat: 25.221661, lng: 55.132518 },
    { lat: 25.220277, lng: 55.132389 },
    { lat: 25.21853, lng: 55.133333 },
    { lat: 25.217753, lng: 55.133119 },
    { lat: 25.216239, lng: 55.132475 },
    { lat: 25.215113, lng: 55.132561 },
    { lat: 25.214259, lng: 55.13329 },
    { lat: 25.213249, lng: 55.133419 },
    { lat: 25.210725, lng: 55.132947 },
    { lat: 25.208629, lng: 55.133076 },
    { lat: 25.207386, lng: 55.132432 },
    { lat: 25.206571, lng: 55.133161 },
    { lat: 25.206765, lng: 55.133762 },
    { lat: 25.207231, lng: 55.134106 },
    { lat: 25.207347, lng: 55.134749 },
    { lat: 25.207153, lng: 55.135307 },
    { lat: 25.207347, lng: 55.135608 },
    { lat: 25.207891, lng: 55.135178 },
    { lat: 25.208279, lng: 55.134964 },
    { lat: 25.208629, lng: 55.135221 },
    { lat: 25.208629, lng: 55.135994 },
    { lat: 25.207542, lng: 55.136852 },
    { lat: 25.206881, lng: 55.137153 },
    { lat: 25.205717, lng: 55.13608 },
    { lat: 25.205678, lng: 55.135178 },
    { lat: 25.20595, lng: 55.134578 },
    { lat: 25.206144, lng: 55.134277 },
    { lat: 25.205398, lng: 55.133805 },
    { lat: 25.204777, lng: 55.13402 },
    { lat: 25.204466, lng: 55.134578 },
    { lat: 25.204311, lng: 55.135007 },
    { lat: 25.203379, lng: 55.13505 },
    { lat: 25.202602, lng: 55.13432 },
    { lat: 25.202331, lng: 55.13402 },
    { lat: 25.202525, lng: 55.133762 },
    { lat: 25.202564, lng: 55.133419 },
    { lat: 25.202214, lng: 55.133247 },
    { lat: 25.202098, lng: 55.133505 },
    { lat: 25.201671, lng: 55.133591 },
    { lat: 25.201243, lng: 55.132775 },
    { lat: 25.200933, lng: 55.132389 },
    { lat: 25.200467, lng: 55.132475 },
    { lat: 25.200117, lng: 55.133033 },
    { lat: 25.200273, lng: 55.133676 },
    { lat: 25.200428, lng: 55.13402 },
    { lat: 25.200428, lng: 55.134277 },
    { lat: 25.199613, lng: 55.134706 },
    { lat: 25.199108, lng: 55.134535 },
    { lat: 25.198253, lng: 55.134878 },
    { lat: 25.197943, lng: 55.13505 },
    { lat: 25.197632, lng: 55.135565 },
    { lat: 25.198098, lng: 55.136208 },
    { lat: 25.19802, lng: 55.136766 },
    { lat: 25.197554, lng: 55.137324 },
    { lat: 25.197554, lng: 55.137839 },
    { lat: 25.198409, lng: 55.138483 },
    { lat: 25.198642, lng: 55.139127 },
    { lat: 25.198448, lng: 55.139513 },
    { lat: 25.198875, lng: 55.1405 },
    { lat: 25.199613, lng: 55.140543 },
    { lat: 25.200273, lng: 55.140371 },
    { lat: 25.200583, lng: 55.139985 },
    { lat: 25.200428, lng: 55.139298 },
    { lat: 25.20004, lng: 55.138354 },
    { lat: 25.19969, lng: 55.137839 },
    { lat: 25.19969, lng: 55.136809 },
    { lat: 25.199807, lng: 55.136466 },
    { lat: 25.200933, lng: 55.135865 },
    { lat: 25.201671, lng: 55.136165 },
    { lat: 25.202059, lng: 55.136723 },
    { lat: 25.202059, lng: 55.137367 },
    { lat: 25.202214, lng: 55.137796 },
    { lat: 25.202564, lng: 55.137968 },
    { lat: 25.203457, lng: 55.137625 },
    { lat: 25.204, lng: 55.13741 },
    { lat: 25.204505, lng: 55.13771 },
    { lat: 25.204932, lng: 55.13814 },
    { lat: 25.205398, lng: 55.138698 },
    { lat: 25.206369, lng: 55.138998 },
    { lat: 25.206951, lng: 55.139513 },
    { lat: 25.207262, lng: 55.140028 },
    { lat: 25.20852, lng: 55.138998 },
    { lat: 25.208869, lng: 55.138826 },
    { lat: 25.209568, lng: 55.137453 },
    { lat: 25.211083, lng: 55.136294 },
    { lat: 25.211976, lng: 55.13608 },
    { lat: 25.213102, lng: 55.136509 },
    { lat: 25.213412, lng: 55.13668 },
    { lat: 25.214499, lng: 55.13711 },
    { lat: 25.215354, lng: 55.136208 },
    { lat: 25.216208, lng: 55.135951 },
    { lat: 25.216984, lng: 55.135951 },
    { lat: 25.217489, lng: 55.13711 },
    { lat: 25.217295, lng: 55.137753 },
    { lat: 25.218304, lng: 55.138311 },
    { lat: 25.218693, lng: 55.137753 },
    { lat: 25.218809, lng: 55.137367 },
    { lat: 25.218809, lng: 55.136552 },
    { lat: 25.21912, lng: 55.135736 },
    { lat: 25.219935, lng: 55.134878 },
    { lat: 25.2211, lng: 55.13505 },
    { lat: 25.221799, lng: 55.135951 },
    { lat: 25.221799, lng: 55.136208 },
    { lat: 25.222264, lng: 55.135736 },
    { lat: 25.223119, lng: 55.135608 },
    { lat: 25.223468, lng: 55.135608 },
    { lat: 25.224283, lng: 55.136809 },
    { lat: 25.225254, lng: 55.136595 },
];

export const the_world_pol_163 = [
    { lat: 25.217411, lng: 55.141075 },
    { lat: 25.217178, lng: 55.141375 },
    { lat: 25.216907, lng: 55.14159 },
    { lat: 25.216285, lng: 55.141075 },
    { lat: 25.21648, lng: 55.140474 },
    { lat: 25.216984, lng: 55.14026 },
    { lat: 25.217373, lng: 55.140732 },
];

export const the_world_pol_164 = [
    { lat: 25.217217, lng: 55.143693 },
    { lat: 25.217295, lng: 55.144208 },
    { lat: 25.217217, lng: 55.144723 },
    { lat: 25.217062, lng: 55.14498 },
    { lat: 25.216868, lng: 55.144852 },
    { lat: 25.216441, lng: 55.144508 },
    { lat: 25.215975, lng: 55.144379 },
    { lat: 25.215509, lng: 55.143993 },
    { lat: 25.215664, lng: 55.143607 },
    { lat: 25.21582, lng: 55.143135 },
    { lat: 25.215703, lng: 55.142963 },
    { lat: 25.21582, lng: 55.142706 },
    { lat: 25.21613, lng: 55.142706 },
    { lat: 25.21648, lng: 55.142491 },
    { lat: 25.216829, lng: 55.142448 },
    { lat: 25.21714, lng: 55.142835 },
    { lat: 25.217373, lng: 55.143564 },
];

export const the_world_pol_165 = [
    { lat: 25.215742, lng: 55.140002 },
    { lat: 25.215664, lng: 55.14056 },
    { lat: 25.215004, lng: 55.140903 },
    { lat: 25.214383, lng: 55.141247 },
    { lat: 25.213956, lng: 55.140989 },
    { lat: 25.214228, lng: 55.14026 },
    { lat: 25.215198, lng: 55.139444 },
    { lat: 25.215625, lng: 55.139487 },
    { lat: 25.21582, lng: 55.139873 },
];

export const the_world_pol_166 = [
    { lat: 25.213335, lng: 55.140303 },
    { lat: 25.213296, lng: 55.141118 },
    { lat: 25.212869, lng: 55.142448 },
    { lat: 25.212247, lng: 55.142792 },
    { lat: 25.211703, lng: 55.142232 },
    { lat: 25.212169, lng: 55.140516 },
    { lat: 25.21279, lng: 55.140087 },
    { lat: 25.213178, lng: 55.140258 },
];

export const the_world_pol_167 = [
    { lat: 25.213722, lng: 55.143863 },
    { lat: 25.213605, lng: 55.144292 },
    { lat: 25.213139, lng: 55.144722 },
    { lat: 25.212945, lng: 55.145408 },
    { lat: 25.21279, lng: 55.145752 },
    { lat: 25.212052, lng: 55.145408 },
    { lat: 25.211703, lng: 55.144979 },
    { lat: 25.212635, lng: 55.143348 },
    { lat: 25.213295, lng: 55.143005 },
    { lat: 25.213722, lng: 55.143262 },
    { lat: 25.213761, lng: 55.143649 },
];

export const the_world_pol_168 = [
    { lat: 25.213528, lng: 55.148713 },
    { lat: 25.213101, lng: 55.147811 },
    { lat: 25.213062, lng: 55.147211 },
    { lat: 25.212751, lng: 55.146953 },
    { lat: 25.212013, lng: 55.147468 },
    { lat: 25.211858, lng: 55.147854 },
    { lat: 25.21178, lng: 55.148112 },
    { lat: 25.212052, lng: 55.148927 },
    { lat: 25.212518, lng: 55.149056 },
    { lat: 25.213217, lng: 55.149056 },
    { lat: 25.213411, lng: 55.148756 },
];

export const the_world_pol_169 = [
    { lat: 25.214809, lng: 55.149356 },
    { lat: 25.214809, lng: 55.151073 },
    { lat: 25.214149, lng: 55.151931 },
    { lat: 25.213722, lng: 55.152189 },
    { lat: 25.21345, lng: 55.151502 },
    { lat: 25.213877, lng: 55.150944 },
    { lat: 25.214071, lng: 55.150129 },
    { lat: 25.214421, lng: 55.149313 },
    { lat: 25.214615, lng: 55.149313 },
];

export const the_world_pol_170 = [
    { lat: 25.214071, lng: 55.153476 },
    { lat: 25.213916, lng: 55.154592 },
    { lat: 25.213761, lng: 55.154764 },
    { lat: 25.213605, lng: 55.154764 },
    { lat: 25.213101, lng: 55.153562 },
    { lat: 25.213683, lng: 55.153004 },
    { lat: 25.214032, lng: 55.153348 },
];

export const the_world_pol_171 = [
    { lat: 25.212246, lng: 55.153133 },
    { lat: 25.212013, lng: 55.153519 },
    { lat: 25.211159, lng: 55.154077 },
    { lat: 25.210771, lng: 55.153305 },
    { lat: 25.210849, lng: 55.152918 },
    { lat: 25.211043, lng: 55.152232 },
    { lat: 25.21147, lng: 55.15206 },
    { lat: 25.211664, lng: 55.15206 },
    { lat: 25.212169, lng: 55.153133 },
];

export const the_world_pol_172 = [
    { lat: 25.210305, lng: 55.153305 },
    { lat: 25.2098, lng: 55.153691 },
    { lat: 25.209024, lng: 55.153519 },
    { lat: 25.208247, lng: 55.153777 },
    { lat: 25.208053, lng: 55.153863 },
    { lat: 25.207354, lng: 55.153176 },
    { lat: 25.207548, lng: 55.152318 },
    { lat: 25.208053, lng: 55.152275 },
    { lat: 25.208364, lng: 55.152532 },
    { lat: 25.208674, lng: 55.152747 },
    { lat: 25.208907, lng: 55.152833 },
    { lat: 25.209606, lng: 55.152446 },
    { lat: 25.209917, lng: 55.152575 },
    { lat: 25.210227, lng: 55.15309 },
    { lat: 25.210383, lng: 55.153176 },
];

export const the_world_pol_173 = [
    { lat: 25.208053, lng: 55.150386 },
    { lat: 25.207898, lng: 55.150816 },
    { lat: 25.207626, lng: 55.151245 },
    { lat: 25.207082, lng: 55.151803 },
    { lat: 25.207199, lng: 55.152189 },
    { lat: 25.206616, lng: 55.152103 },
    { lat: 25.206383, lng: 55.151931 },
    { lat: 25.206306, lng: 55.151288 },
    { lat: 25.206616, lng: 55.150773 },
    { lat: 25.206772, lng: 55.150687 },
    { lat: 25.206888, lng: 55.149399 },
    { lat: 25.20782, lng: 55.149056 },
    { lat: 25.208014, lng: 55.149485 },
    { lat: 25.208131, lng: 55.150172 },
];

export const the_world_pol_174 = [
    { lat: 25.205296, lng: 55.150773 },
    { lat: 25.20483, lng: 55.151588 },
    { lat: 25.204442, lng: 55.151888 },
    { lat: 25.204054, lng: 55.151803 },
    { lat: 25.203549, lng: 55.151373 },
    { lat: 25.204015, lng: 55.149828 },
    { lat: 25.204753, lng: 55.149485 },
    { lat: 25.205141, lng: 55.150172 },
    { lat: 25.205296, lng: 55.150601 },
];

export const the_world_pol_175 = [
    { lat: 25.203549, lng: 55.148284 },
    { lat: 25.203549, lng: 55.148756 },
    { lat: 25.203393, lng: 55.149099 },
    { lat: 25.203355, lng: 55.149399 },
    { lat: 25.203316, lng: 55.1497 },
    { lat: 25.202772, lng: 55.149313 },
    { lat: 25.202772, lng: 55.148455 },
    { lat: 25.203316, lng: 55.14867 },
    { lat: 25.203432, lng: 55.148369 },
];

export const the_world_pol_176 = [
    { lat: 25.204675, lng: 55.147168 },
    { lat: 25.20452, lng: 55.147854 },
    { lat: 25.204325, lng: 55.148069 },
    { lat: 25.203937, lng: 55.148241 },
    { lat: 25.203782, lng: 55.148112 },
    { lat: 25.203549, lng: 55.147597 },
    { lat: 25.20417, lng: 55.146996 },
    { lat: 25.204558, lng: 55.147039 },
];

export const the_world_pol_177 = [
    { lat: 25.207354, lng: 55.146781 },
    { lat: 25.207199, lng: 55.148198 },
    { lat: 25.206888, lng: 55.148412 },
    { lat: 25.2065, lng: 55.148584 },
    { lat: 25.205762, lng: 55.147597 },
    { lat: 25.205995, lng: 55.147039 },
    { lat: 25.206267, lng: 55.146095 },
    { lat: 25.207121, lng: 55.145966 },
    { lat: 25.207354, lng: 55.146352 },
    { lat: 25.207432, lng: 55.146824 },
];

export const the_world_pol_178 = [
    { lat: 25.205032, lng: 55.145451 },
    { lat: 25.205071, lng: 55.145451 },
    { lat: 25.20476, lng: 55.145837 },
    { lat: 25.204255, lng: 55.145966 },
    { lat: 25.203984, lng: 55.145451 },
    { lat: 25.20476, lng: 55.144936 },
    { lat: 25.205032, lng: 55.145322 },
];

export const the_world_pol_179 = [
    { lat: 25.206702, lng: 55.144593 },
    { lat: 25.206702, lng: 55.14485 },
    { lat: 25.206197, lng: 55.145108 },
    { lat: 25.205149, lng: 55.144507 },
    { lat: 25.204954, lng: 55.144593 },
    { lat: 25.204527, lng: 55.144078 },
    { lat: 25.204838, lng: 55.143649 },
    { lat: 25.205537, lng: 55.143048 },
    { lat: 25.205847, lng: 55.143563 },
    { lat: 25.206469, lng: 55.144207 },
    { lat: 25.206779, lng: 55.144421 },
];

export const the_world_pol_180 = [
    { lat: 25.206352, lng: 55.141546 },
    { lat: 25.206469, lng: 55.141932 },
    { lat: 25.205964, lng: 55.142705 },
    { lat: 25.205653, lng: 55.142747 },
    { lat: 25.205265, lng: 55.142833 },
    { lat: 25.205071, lng: 55.14219 },
    { lat: 25.204916, lng: 55.141589 },
    { lat: 25.205304, lng: 55.140773 },
    { lat: 25.20608, lng: 55.140516 },
    { lat: 25.206275, lng: 55.141245 },
    { lat: 25.206275, lng: 55.14146 },
];

export const the_world_pol_181 = [
    { lat: 25.208177, lng: 55.142705 },
    { lat: 25.208138, lng: 55.143262 },
    { lat: 25.207905, lng: 55.14352 },
    { lat: 25.207634, lng: 55.143606 },
    { lat: 25.206702, lng: 55.143348 },
    { lat: 25.206391, lng: 55.142705 },
    { lat: 25.207012, lng: 55.14219 },
    { lat: 25.207905, lng: 55.142232 },
    { lat: 25.208061, lng: 55.142533 },
];

export const the_world_pol_182 = [
    { lat: 25.210973, lng: 55.141589 },
    { lat: 25.210585, lng: 55.142447 },
    { lat: 25.209924, lng: 55.142447 },
    { lat: 25.208876, lng: 55.141717 },
    { lat: 25.208876, lng: 55.140602 },
    { lat: 25.209342, lng: 55.14043 },
    { lat: 25.210274, lng: 55.140516 },
    { lat: 25.210779, lng: 55.141074 },
    { lat: 25.211012, lng: 55.141288 },
    { lat: 25.211012, lng: 55.14146 },
];

export const the_world_pol_183 = [
    { lat: 25.203984, lng: 55.142833 },
    { lat: 25.203595, lng: 55.143348 },
    { lat: 25.203285, lng: 55.143863 },
    { lat: 25.203285, lng: 55.144464 },
    { lat: 25.203091, lng: 55.145065 },
    { lat: 25.202702, lng: 55.145108 },
    { lat: 25.202819, lng: 55.145709 },
    { lat: 25.202896, lng: 55.146224 },
    { lat: 25.202896, lng: 55.146781 },
    { lat: 25.202663, lng: 55.147296 },
    { lat: 25.202314, lng: 55.147726 },
    { lat: 25.202322, lng: 55.148224 },
    { lat: 25.201545, lng: 55.148868 },
    { lat: 25.201234, lng: 55.148868 },
    { lat: 25.200924, lng: 55.148267 },
    { lat: 25.201351, lng: 55.147409 },
    { lat: 25.202089, lng: 55.145821 },
    { lat: 25.202166, lng: 55.144533 },
    { lat: 25.202322, lng: 55.143289 },
    { lat: 25.203098, lng: 55.142559 },
    { lat: 25.203991, lng: 55.142731 },
];

export const the_world_pol_184 = [
    { lat: 25.202166, lng: 55.151614 },
    { lat: 25.20205, lng: 55.151915 },
    { lat: 25.202127, lng: 55.152301 },
    { lat: 25.2017, lng: 55.15273 },
    { lat: 25.200768, lng: 55.152387 },
    { lat: 25.200613, lng: 55.151872 },
    { lat: 25.200962, lng: 55.150971 },
    { lat: 25.201584, lng: 55.150541 },
    { lat: 25.201778, lng: 55.150541 },
    { lat: 25.201623, lng: 55.150971 },
    { lat: 25.201545, lng: 55.151099 },
    { lat: 25.201856, lng: 55.151314 },
    { lat: 25.202127, lng: 55.151571 },
];

export const the_world_pol_185 = [
    { lat: 25.202244, lng: 55.153846 },
    { lat: 25.202205, lng: 55.154146 },
    { lat: 25.202011, lng: 55.155219 },
    { lat: 25.201856, lng: 55.155391 },
    { lat: 25.201584, lng: 55.155348 },
    { lat: 25.20139, lng: 55.155005 },
    { lat: 25.201234, lng: 55.154575 },
    { lat: 25.201778, lng: 55.15346 },
    { lat: 25.202127, lng: 55.153846 },
];

export const the_world_pol_186 = [
    { lat: 25.211175, lng: 55.144533 },
    { lat: 25.211097, lng: 55.144705 },
    { lat: 25.210476, lng: 55.144919 },
    { lat: 25.209311, lng: 55.144362 },
    { lat: 25.208922, lng: 55.144018 },
    { lat: 25.209078, lng: 55.143246 },
    { lat: 25.209621, lng: 55.143203 },
    { lat: 25.21032, lng: 55.143589 },
    { lat: 25.210786, lng: 55.143932 },
    { lat: 25.211058, lng: 55.144533 },
];

export const the_world_pol_187 = [
    { lat: 25.209621, lng: 55.14552 },
    { lat: 25.209388, lng: 55.146078 },
    { lat: 25.208961, lng: 55.145864 },
    { lat: 25.208068, lng: 55.145692 },
    { lat: 25.207874, lng: 55.145606 },
    { lat: 25.207874, lng: 55.144962 },
    { lat: 25.208185, lng: 55.144619 },
    { lat: 25.208922, lng: 55.144576 },
    { lat: 25.209388, lng: 55.144791 },
    { lat: 25.209699, lng: 55.145392 },
];

export const the_world_pol_188 = [
    { lat: 25.209816, lng: 55.147323 },
    { lat: 25.20966, lng: 55.147924 },
    { lat: 25.20935, lng: 55.14831 },
    { lat: 25.208845, lng: 55.148052 },
    { lat: 25.208379, lng: 55.147795 },
    { lat: 25.208728, lng: 55.147237 },
    { lat: 25.209155, lng: 55.146894 },
    { lat: 25.209699, lng: 55.146894 },
    { lat: 25.209738, lng: 55.147323 },
];

export const the_world_pol_189 = [
    { lat: 25.210165, lng: 55.148953 },
    { lat: 25.210281, lng: 55.14934 },
    { lat: 25.209854, lng: 55.149898 },
    { lat: 25.209544, lng: 55.150413 },
    { lat: 25.209388, lng: 55.150413 },
    { lat: 25.209311, lng: 55.150026 },
    { lat: 25.20935, lng: 55.149468 },
    { lat: 25.209117, lng: 55.149125 },
    { lat: 25.209777, lng: 55.148481 },
    { lat: 25.210359, lng: 55.148825 },
];

export const the_world_pol_190 = [
    { lat: 25.212106, lng: 55.150885 },
    { lat: 25.211796, lng: 55.150885 },
    { lat: 25.211019, lng: 55.151228 },
    { lat: 25.210553, lng: 55.151314 },
    { lat: 25.210281, lng: 55.151056 },
    { lat: 25.210204, lng: 55.150456 },
    { lat: 25.210553, lng: 55.150069 },
    { lat: 25.211136, lng: 55.149769 },
    { lat: 25.211796, lng: 55.150069 },
    { lat: 25.212223, lng: 55.150327 },
    { lat: 25.212106, lng: 55.150756 },
];

export const the_world_pol_191 = [
    { lat: 25.223299, lng: 55.14428 },
    { lat: 25.223222, lng: 55.144709 },
    { lat: 25.223028, lng: 55.144881 },
    { lat: 25.222083, lng: 55.144495 },
    { lat: 25.221229, lng: 55.143465 },
    { lat: 25.221423, lng: 55.142907 },
    { lat: 25.22185, lng: 55.14252 },
    { lat: 25.22251, lng: 55.142735 },
    { lat: 25.222976, lng: 55.143207 },
    { lat: 25.223403, lng: 55.144108 },
];

export const the_world_pol_192 = [
    { lat: 25.217191, lng: 55.146812 },
    { lat: 25.216725, lng: 55.147327 },
    { lat: 25.216375, lng: 55.147327 },
    { lat: 25.215948, lng: 55.147198 },
    { lat: 25.215793, lng: 55.147069 },
    { lat: 25.215948, lng: 55.146769 },
    { lat: 25.215871, lng: 55.146554 },
    { lat: 25.215948, lng: 55.146211 },
    { lat: 25.216608, lng: 55.145997 },
    { lat: 25.217191, lng: 55.14664 },
];

export const the_world_pol_193 = [
    { lat: 25.215482, lng: 55.147069 },
    { lat: 25.215366, lng: 55.14767 },
    { lat: 25.215055, lng: 55.147799 },
    { lat: 25.214434, lng: 55.14737 },
    { lat: 25.214473, lng: 55.146898 },
    { lat: 25.214628, lng: 55.14664 },
    { lat: 25.214356, lng: 55.146211 },
    { lat: 25.213813, lng: 55.145825 },
    { lat: 25.213774, lng: 55.145524 },
    { lat: 25.21424, lng: 55.145353 },
    { lat: 25.214939, lng: 55.145825 },
    { lat: 25.215094, lng: 55.146554 },
    { lat: 25.21556, lng: 55.147027 },
];

export const the_world_pol_194 = [
    { lat: 25.238509, lng: 55.16723 },
    { lat: 25.238237, lng: 55.167659 },
    { lat: 25.238276, lng: 55.168732 },
    { lat: 25.238004, lng: 55.17032 },
    { lat: 25.237344, lng: 55.170492 },
    { lat: 25.236956, lng: 55.170148 },
    { lat: 25.23715, lng: 55.169333 },
    { lat: 25.237499, lng: 55.168947 },
    { lat: 25.237538, lng: 55.167831 },
    { lat: 25.237927, lng: 55.167702 },
    { lat: 25.238121, lng: 55.167144 },
    { lat: 25.238392, lng: 55.167059 },
];

export const the_world_pol_195 = [
    { lat: 25.236141, lng: 55.16826 },
    { lat: 25.236218, lng: 55.168604 },
    { lat: 25.236102, lng: 55.168904 },
    { lat: 25.235675, lng: 55.168904 },
    { lat: 25.234821, lng: 55.168604 },
    { lat: 25.233889, lng: 55.16826 },
    { lat: 25.234316, lng: 55.16723 },
    { lat: 25.234394, lng: 55.166887 },
    { lat: 25.23486, lng: 55.166715 },
    { lat: 25.235015, lng: 55.16693 },
    { lat: 25.23517, lng: 55.16723 },
    { lat: 25.235869, lng: 55.167574 },
    { lat: 25.236141, lng: 55.168003 },
    { lat: 25.23618, lng: 55.168174 },
];

export const the_world_pol_196 = [
    { lat: 25.23184, lng: 55.175202 },
    { lat: 25.231684, lng: 55.175846 },
    { lat: 25.231335, lng: 55.176489 },
    { lat: 25.23083, lng: 55.176618 },
    { lat: 25.23017, lng: 55.176618 },
    { lat: 25.229743, lng: 55.176146 },
    { lat: 25.230131, lng: 55.175417 },
    { lat: 25.231257, lng: 55.174987 },
    { lat: 25.231917, lng: 55.175116 },
];

export const the_world_pol_197 = [
    { lat: 25.231063, lng: 55.172455 },
    { lat: 25.231024, lng: 55.173142 },
    { lat: 25.231024, lng: 55.173485 },
    { lat: 25.230675, lng: 55.174172 },
    { lat: 25.230131, lng: 55.174 },
    { lat: 25.229782, lng: 55.173829 },
    { lat: 25.229588, lng: 55.172627 },
    { lat: 25.229471, lng: 55.172284 },
    { lat: 25.229976, lng: 55.171254 },
    { lat: 25.230326, lng: 55.171211 },
    { lat: 25.230597, lng: 55.17164 },
    { lat: 25.230908, lng: 55.17237 },
    { lat: 25.230947, lng: 55.172541 },
];

export const the_world_pol_198 = [
    { lat: 25.231801, lng: 55.17031 },
    { lat: 25.231413, lng: 55.170996 },
    { lat: 25.230908, lng: 55.171211 },
    { lat: 25.230209, lng: 55.169709 },
    { lat: 25.230597, lng: 55.169366 },
    { lat: 25.231257, lng: 55.169451 },
    { lat: 25.231684, lng: 55.170267 },
];

export const the_world_pol_199 = [
    { lat: 25.223391, lng: 55.17594 },
    { lat: 25.22329, lng: 55.176712 },
    { lat: 25.223018, lng: 55.177184 },
    { lat: 25.222319, lng: 55.17727 },
    { lat: 25.221892, lng: 55.176798 },
    { lat: 25.221931, lng: 55.176283 },
    { lat: 25.221814, lng: 55.175596 },
    { lat: 25.22197, lng: 55.175253 },
    { lat: 25.222669, lng: 55.175167 },
    { lat: 25.223173, lng: 55.175382 },
    { lat: 25.223367, lng: 55.175768 },
    { lat: 25.223445, lng: 55.175854 },
];

export const the_world_pol_200 = [
    { lat: 25.221659, lng: 55.177699 },
    { lat: 25.221465, lng: 55.1783 },
    { lat: 25.220805, lng: 55.178472 },
    { lat: 25.220378, lng: 55.178 },
    { lat: 25.220533, lng: 55.177527 },
    { lat: 25.220999, lng: 55.177055 },
    { lat: 25.221581, lng: 55.177399 },
    { lat: 25.22162, lng: 55.177527 },
];

export const the_world_pol_201 = [
    { lat: 25.223626, lng: 55.193664 },
    { lat: 25.223277, lng: 55.193106 },
    { lat: 25.2225, lng: 55.192805 },
    { lat: 25.221646, lng: 55.192977 },
    { lat: 25.221529, lng: 55.193578 },
    { lat: 25.22184, lng: 55.193707 },
    { lat: 25.221995, lng: 55.194007 },
    { lat: 25.222811, lng: 55.194307 },
    { lat: 25.223548, lng: 55.194093 },
    { lat: 25.223626, lng: 55.193749 },
];

export const the_world_pol_202 = [
    { lat: 25.199636, lng: 55.17789 },
    { lat: 25.199636, lng: 55.178448 },
    { lat: 25.199286, lng: 55.179306 },
    { lat: 25.19851, lng: 55.18098 },
    { lat: 25.197888, lng: 55.181238 },
    { lat: 25.197306, lng: 55.18098 },
    { lat: 25.197306, lng: 55.180637 },
    { lat: 25.197461, lng: 55.179478 },
    { lat: 25.197228, lng: 55.178834 },
    { lat: 25.19618, lng: 55.177976 },
    { lat: 25.195364, lng: 55.177504 },
    { lat: 25.194122, lng: 55.177633 },
    { lat: 25.193733, lng: 55.17759 },
    { lat: 25.193578, lng: 55.17686 },
    { lat: 25.19385, lng: 55.176431 },
    { lat: 25.194937, lng: 55.176259 },
    { lat: 25.19618, lng: 55.176603 },
    { lat: 25.196956, lng: 55.177118 },
    { lat: 25.197539, lng: 55.177375 },
    { lat: 25.198704, lng: 55.177375 },
    { lat: 25.19917, lng: 55.177461 },
    { lat: 25.199442, lng: 55.17789 },
    { lat: 25.19948, lng: 55.177976 },
];

export const the_world_pol_203 = [
    { lat: 25.196646, lng: 55.180508 },
    { lat: 25.196024, lng: 55.181581 },
    { lat: 25.195364, lng: 55.181538 },
    { lat: 25.195209, lng: 55.181023 },
    { lat: 25.195054, lng: 55.180723 },
    { lat: 25.194898, lng: 55.180036 },
    { lat: 25.194898, lng: 55.179349 },
    { lat: 25.195325, lng: 55.178749 },
    { lat: 25.195908, lng: 55.179349 },
    { lat: 25.196141, lng: 55.180122 },
    { lat: 25.196452, lng: 55.180422 },
    { lat: 25.196568, lng: 55.180422 },
];

export const the_world_pol_204 = [
    { lat: 25.223836, lng: 55.147721 },
    { lat: 25.223952, lng: 55.148021 },
    { lat: 25.223641, lng: 55.148751 },
    { lat: 25.222943, lng: 55.148794 },
    { lat: 25.222671, lng: 55.148279 },
    { lat: 25.222632, lng: 55.147764 },
    { lat: 25.223292, lng: 55.147592 },
    { lat: 25.223797, lng: 55.147721 },
];

export const bus_road_1_stops = [
    {
        name: "Jumeirah Golf Estates",
        coords: [25.017772955820742, 55.163710995850835],
    },
    {
        name: "Dubai Investment Park",
        coords: [25.005692940092054, 55.15562540748922],
    },
    {
        name: "EXPO 2020",
        coords: [24.963794660843785, 55.145743253521125],
    },
    {
        name: "The Gardens",
        coords: [25.043822100415827, 55.13440958050732],
    },
    {
        name: "Discovery Gardens",
        coords: [25.03483681181547, 55.14495236886811],
    },
    {
        name: "Al Furjan",
        coords: [25.030650944415378, 55.152206809342985],
    },
    {
        name: "UAE Exchange",
        coords: [24.977649633464985, 55.09102075352159],
    },
    {
        name: "Danube Metro Station",
        coords: [25.001348589696118, 55.0957278502079],
    },
    {
        name: "Energy",
        coords: [25.026330934737505, 55.10134582654013],
    },
    {
        name: "Ibn Battuta",
        coords: [25.044733360058594, 55.11657326701567],
    },
    {
        name: "Jabal Ali",
        coords: [25.057845496973602, 55.126971895852265],
    },
    {
        name: "DMCC",
        coords: [25.071048555908966, 55.13865082283596],
    },
    {
        name: "Sobha Realty",
        coords: [25.0800957754182, 55.147451411197466],
    },
    {
        name: "Al Khail",
        coords: [25.089000059626148, 55.15813554003375],
    },
    {
        name: "Dubai Internet City",
        coords: [25.102287812558263, 55.17369264003425],
    },
    {
        name: "Mashreq ",
        coords: [25.114975292273478, 55.19091421200822],
    },
    {
        name: "Mall of the Emirates",
        coords: [25.121481143747527, 55.20037220934596],
    },
    {
        name: "Equiti ",
        coords: [25.126602722408403, 55.20745065352676],
    },
    {
        name: "Onpassive Metro Station",
        coords: [25.156029981600174, 55.22848955999912],
    },
    {
        name: "Business Bay",
        coords: [25.19125511282233, 55.26032592469287],
    },
    {
        name: "Burj Khalifa/Dubai Mall",
        coords: [25.20161164536886, 55.269464895857105],
    },
    {
        name: "Financial Centre",
        coords: [25.21173765260232, 55.274728195857286],
    },
    {
        name: "Emirates Towers",
        coords: [25.21747267299612, 55.27992019585751],
    },
    {
        name: "World Trade Centre",
        coords: [25.225066121472754, 55.28506278236618],
    },
    {
        name: "Max",
        coords: [25.23386144638582, 55.29210791421783],
    },
    {
        name: "ADCB (Abu Dhabi Commercial Bank)",
        coords: [25.244757998920846, 55.29814015167797],
    },
    {
        name: "Al Rigga",
        coords: [25.263901698901385, 55.323860969621656],
    },
    {
        name: "Deira City Centre",
        coords: [25.255273147765102, 55.33030979585883],
    },
    {
        name: "GGICO",
        coords: [25.24991917156743, 55.33931664469571],
    },
    {
        name: "Airport Terminal 1",
        coords: [25.248430184923514, 55.352369759510516],
    },
    {
        name: "Airport Terminal 3",
        coords: [25.245316076900806, 55.35965114003925],
    },
    {
        name: "Emirates",
        coords: [25.241243166840587, 55.36565485353067],
    },
    {
        name: "Centrepoint",
        coords: [25.22986285623351, 55.39190106366855],
    },
];

export const bus_road_1 = [
    { lat: 25.22986285623351, lng: 55.39190106366855 },
    { lat: 25.230582, lng: 55.391541 },
    { lat: 25.235357, lng: 55.38013 },
    { lat: 25.23524, lng: 55.378757 },
    { lat: 25.235395, lng: 55.377384 },
    { lat: 25.236172, lng: 55.375667 },
    { lat: 25.240054, lng: 55.367556 },
    { lat: 25.242033, lng: 55.363651 },
    { lat: 25.243353, lng: 55.362706 },
    { lat: 25.246448, lng: 55.356983 },
    { lat: 25.246836, lng: 55.356125 },
    { lat: 25.247185, lng: 55.354838 },
    { lat: 25.247923, lng: 55.353207 },
    { lat: 25.249801, lng: 55.349656 },
    { lat: 25.249801, lng: 55.348883 },
    { lat: 25.249917, lng: 55.347767 },
    { lat: 25.25015, lng: 55.346995 },
    { lat: 25.250344, lng: 55.346394 },
    { lat: 25.250344, lng: 55.345235 },
    { lat: 25.249956, lng: 55.343862 },
    { lat: 25.249723, lng: 55.341806 },
    { lat: 25.249412, lng: 55.337557 },
    { lat: 25.249166, lng: 55.335971 },
    { lat: 25.249205, lng: 55.334598 },
    { lat: 25.24971, lng: 55.333139 },
    { lat: 25.25002, lng: 55.332195 },
    { lat: 25.251058, lng: 55.329851 },
    { lat: 25.251388, lng: 55.329765 },
    { lat: 25.25197, lng: 55.329765 },
    { lat: 25.252591, lng: 55.329894 },
    { lat: 25.25327, lng: 55.330087 },
    { lat: 25.254812, lng: 55.330375 },
    { lat: 25.256597, lng: 55.330525 },
    { lat: 25.25751, lng: 55.330332 },
    { lat: 25.258587, lng: 55.330161 },
    { lat: 25.259364, lng: 55.329967 },
    { lat: 25.26012, lng: 55.329302 },
    { lat: 25.260703, lng: 55.328701 },
    { lat: 25.26144, lng: 55.327736 },
    { lat: 25.262158, lng: 55.326384 },
    { lat: 25.265591, lng: 55.319375 },
    { lat: 25.266895, lng: 55.316714 },
    { lat: 25.26705, lng: 55.316306 },
    { lat: 25.26705, lng: 55.315856 },
    { lat: 25.266856, lng: 55.315469 },
    { lat: 25.266643, lng: 55.315019 },
    { lat: 25.266197, lng: 55.313238 },
    { lat: 25.265995, lng: 55.313001 },
    { lat: 25.2652, lng: 55.312679 },
    { lat: 25.264637, lng: 55.312486 },
    { lat: 25.263725, lng: 55.311628 },
    { lat: 25.26324, lng: 55.311199 },
    { lat: 25.259876, lng: 55.308473 },
    { lat: 25.258357, lng: 55.306651 },
    { lat: 25.254174, lng: 55.304072 },
    { lat: 25.249732, lng: 55.301422 },
    { lat: 25.241429, lng: 55.296599 },
    { lat: 25.240287, lng: 55.29596 },
    { lat: 25.23689, lng: 55.29405 },
    { lat: 25.231645, lng: 55.291064 },
    { lat: 25.229075, lng: 55.289423 },
    { lat: 25.228726, lng: 55.288844 },
    { lat: 25.228357, lng: 55.288243 },
    { lat: 25.227664, lng: 55.287058 },
    { lat: 25.225994, lng: 55.285771 },
    { lat: 25.223344, lng: 55.284094 },
    { lat: 25.220894, lng: 55.282392 },
    { lat: 25.219224, lng: 55.281319 },
    { lat: 25.216785, lng: 55.279579 },
    { lat: 25.209364, lng: 55.274538 },
    { lat: 25.206069, lng: 55.272382 },
    { lat: 25.204807, lng: 55.272039 },
    { lat: 25.2037, lng: 55.271546 },
    { lat: 25.202962, lng: 55.270966 },
    { lat: 25.200727, lng: 55.268944 },
    { lat: 25.19798, lng: 55.26687 },
    { lat: 25.196815, lng: 55.265582 },
    { lat: 25.195941, lng: 55.264702 },
    { lat: 25.193531, lng: 55.262527 },
    { lat: 25.191781, lng: 55.260897 },
    { lat: 25.187894, lng: 55.257328 },
    { lat: 25.183093, lng: 55.252783 },
    { lat: 25.178237, lng: 55.248473 },
    { lat: 25.174999, lng: 55.245402 },
    { lat: 25.17245, lng: 55.243047 },
    { lat: 25.169399, lng: 55.240202 },
    { lat: 25.165686, lng: 55.236722 },
    { lat: 25.163641, lng: 55.234887 },
    { lat: 25.149696, lng: 55.22449 },
    { lat: 25.14247, lng: 55.220113 },
    { lat: 25.130353, lng: 55.212303 },
    { lat: 25.125458, lng: 55.205952 },
    { lat: 25.11589, lng: 55.192295 },
    { lat: 25.099846, lng: 55.170892 },
    { lat: 25.088031, lng: 55.157073 },
    { lat: 25.077442, lng: 55.144375 },
    { lat: 25.070507, lng: 55.138293 },
    { lat: 25.062651, lng: 55.131286 },
    { lat: 25.056173, lng: 55.12544 },
    { lat: 25.054035, lng: 55.125355 },
    { lat: 25.052635, lng: 55.125741 },
    { lat: 25.049214, lng: 55.128144 },
    { lat: 25.047783, lng: 55.129185 },
    { lat: 25.046539, lng: 55.130902 },
    { lat: 25.045373, lng: 55.132661 },
    { lat: 25.044206, lng: 55.134335 },
    { lat: 25.04217, lng: 55.137254 },
    { lat: 25.041101, lng: 55.138627 },
    { lat: 25.039851, lng: 55.140012 },
    { lat: 25.036462, lng: 55.142913 },
    { lat: 25.034612, lng: 55.146093 },
    { lat: 25.033406, lng: 55.148496 },
    { lat: 25.028662, lng: 55.154547 },
    { lat: 25.025824, lng: 55.158881 },
    { lat: 25.023566, lng: 55.161735 },
    { lat: 25.022735, lng: 55.162893 },
    { lat: 25.02153, lng: 55.16358 },
    { lat: 25.020519, lng: 55.163365 },
    { lat: 25.019041, lng: 55.163365 },
    { lat: 25.018263, lng: 55.163322 },
    { lat: 25.015036, lng: 55.162593 },
    { lat: 25.01383, lng: 55.161692 },
    { lat: 25.012974, lng: 55.160619 },
    { lat: 25.012002, lng: 55.159417 },
    { lat: 25.010602, lng: 55.158216 },
    { lat: 25.007306, lng: 55.156448 },
    { lat: 25.003922, lng: 55.155161 },
    { lat: 25.000718, lng: 55.154796 },
    { lat: 24.997378, lng: 55.154383 },
    { lat: 24.99516, lng: 55.153868 },
    { lat: 24.99038, lng: 55.151446 },
    { lat: 24.987405, lng: 55.149969 },
    { lat: 24.984176, lng: 55.149239 },
    { lat: 24.98237, lng: 55.148473 },
    { lat: 24.979679, lng: 55.14671 },
    { lat: 24.978056, lng: 55.146031 },
    { lat: 24.976734, lng: 55.145859 },
    { lat: 24.975333, lng: 55.145645 },
    { lat: 24.973894, lng: 55.146074 },
    { lat: 24.97191, lng: 55.146718 },
    { lat: 24.968007, lng: 55.147018 },
    { lat: 24.965324, lng: 55.147224 },
    { lat: 24.963612, lng: 55.146108 },
    { lat: 24.963794660843785, lng: 55.145743253521125 },
];

export const bus_road_1_2 = [
    { lat: 25.062651, lng: 55.131286 },
    { lat: 25.056173, lng: 55.12544 },
    { lat: 25.031917, lng: 55.103867 },
    { lat: 25.026706, lng: 55.100606 },
    { lat: 25.01924, lng: 55.097945 },
    { lat: 25.012551, lng: 55.096743 },
    { lat: 25.001025, lng: 55.0949 },
    { lat: 24.977649633464985, lng: 55.09102075352159 },
];

export const bus_road_2_stops = [
    {
        name: "Creek",
        coords: [25.21911154590219, 55.339003764669876],
    },
    {
        name: "Al Jadaf",
        coords: [25.22515089255185, 55.33417663691971],
    },
    {
        name: "Union",
        coords: [25.266530126430993, 55.314945277398536],
    },
    {
        name: "Salah Al Din",
        coords: [25.270334820346424, 55.321551767608135],
    },
    {
        name: "Abu Baker Al Siddique",
        coords: [25.271002236794967, 55.333178920431905],
    },
    {
        name: "Abu Hail",
        coords: [25.275327905397, 55.34693135411595],
    },
    {
        name: "Al Qiyadah",
        coords: [25.277820508372365, 55.353175935070524],
    },
    {
        name: "Stadium",
        coords: [25.27790202973908, 55.362054872113475],
    },
    {
        name: "Al Nahda",
        coords: [25.2734901152996, 55.37033565966881],
    },
    {
        name: "Dubai Airport Free Zone",
        coords: [25.270235042038657, 55.375758255966645],
    },
    {
        name: "Al Qusais",
        coords: [25.262859518615688, 55.38787005041344],
    },
    {
        name: "Etisalat",
        coords: [25.25499004488136, 55.40154403877181],
    },
    {
        name: "Baniyas Square",
        coords: [25.26947482393235, 55.3084392866535],
    },
    {
        name: "Gold Souq",
        coords: [25.27632651741819, 55.30216073692151],
    },
    {
        name: "Al Ras",
        coords: [25.268689727524773, 55.293581123428886],
    },
    {
        name: "Al Gubaiba",
        coords: [25.26519723111498, 55.28977359644381],
    },
    {
        name: "Sharaf DG",
        coords: [25.25835861473298, 55.29780418718855],
    },
    {
        name: "Burjuman",
        coords: [25.25488751950197, 55.304156762589955],
    },
    {
        name: "Oud Metha",
        coords: [25.243839684002328, 55.31634990623347],
    },
    {
        name: "Dubai Healthcare City",
        coords: [25.231084483076582, 55.323201190889534],
    },
];

export const bus_road_2 = [
    { lat: 25.254658, lng: 55.401077 },
    { lat: 25.25995, lng: 55.391793 },
    { lat: 25.264907, lng: 55.383409 },
    { lat: 25.267535, lng: 55.378672 },
    { lat: 25.27896, lng: 55.359575 },
    { lat: 25.280111, lng: 55.357317 },
    { lat: 25.280538, lng: 55.356674 },
    { lat: 25.280926, lng: 55.355901 },
    { lat: 25.280732, lng: 55.355172 },
    { lat: 25.280305, lng: 55.354614 },
    { lat: 25.275881, lng: 55.349721 },
    { lat: 25.275532, lng: 55.349206 },
    { lat: 25.275765, lng: 55.348305 },
    { lat: 25.275765, lng: 55.347619 },
    { lat: 25.272181, lng: 55.337988 },
    { lat: 25.271431, lng: 55.334987 },
    { lat: 25.270894, lng: 55.332813 },
    { lat: 25.270705, lng: 55.330683 },
    { lat: 25.270453, lng: 55.323346 },
    { lat: 25.27041, lng: 55.32041 },
    { lat: 25.270483, lng: 55.319525 },
    { lat: 25.270434, lng: 55.318173 },
    { lat: 25.270356, lng: 55.317712 },
    { lat: 25.270259, lng: 55.317379 },
    { lat: 25.270269, lng: 55.317079 },
    { lat: 25.270424, lng: 55.316553 },
    { lat: 25.270424, lng: 55.316178 },
    { lat: 25.270124, lng: 55.315952 },
    { lat: 25.269797, lng: 55.31605 },
    { lat: 25.269603, lng: 55.316329 },
    { lat: 25.268788, lng: 55.316072 },
    { lat: 25.26774, lng: 55.316029 },
    { lat: 25.266614, lng: 55.315471 },
    { lat: 25.266595, lng: 55.314827 },
    { lat: 25.265822, lng: 55.312365 },
    { lat: 25.265677, lng: 55.311882 },
    { lat: 25.265774, lng: 55.311292 },
    { lat: 25.265929, lng: 55.310938 },
    { lat: 25.26675, lng: 55.309402 },
    { lat: 25.267565, lng: 55.308501 },
    { lat: 25.268303, lng: 55.307857 },
    { lat: 25.269894, lng: 55.307578 },
    { lat: 25.271194, lng: 55.307536 },
    { lat: 25.271961, lng: 55.307321 },
    { lat: 25.273383, lng: 55.306471 },
    { lat: 25.276161, lng: 55.305165 },
    { lat: 25.276588, lng: 55.30465 },
    { lat: 25.276704, lng: 55.304243 },
    { lat: 25.275986, lng: 55.302934 },
    { lat: 25.274732, lng: 55.300158 },
    { lat: 25.273859, lng: 55.29797 },
    { lat: 25.273097, lng: 55.295718 },
    { lat: 25.271853, lng: 55.292403 },
    { lat: 25.271038, lng: 55.290837 },
    { lat: 25.26994, lng: 55.288902 },
    { lat: 25.268446, lng: 55.288151 },
    { lat: 25.262965, lng: 55.284956 },
    { lat: 25.261277, lng: 55.28429 },
    { lat: 25.260481, lng: 55.284312 },
    { lat: 25.260248, lng: 55.284891 },
    { lat: 25.259724, lng: 55.286715 },
    { lat: 25.25924, lng: 55.290936 },
    { lat: 25.259551, lng: 55.292739 },
    { lat: 25.259609, lng: 55.294048 },
    { lat: 25.259279, lng: 55.295314 },
    { lat: 25.258541, lng: 55.296623 },
    { lat: 25.257972, lng: 55.297882 },
    { lat: 25.25512, lng: 55.303541 },
    { lat: 25.253878, lng: 55.306073 },
    { lat: 25.253063, lng: 55.308348 },
    { lat: 25.251285, lng: 55.311092 },
    { lat: 25.247588, lng: 55.31416 },
    { lat: 25.244677, lng: 55.315405 },
    { lat: 25.241028, lng: 55.316435 },
    { lat: 25.236306, lng: 55.317064 },
    { lat: 25.233376, lng: 55.317121 },
    { lat: 25.232289, lng: 55.316949 },
    { lat: 25.231745, lng: 55.31725 },
    { lat: 25.23127, lng: 55.319392 },
    { lat: 25.23076, lng: 55.323572 },
    { lat: 25.230411, lng: 55.325263 },
    { lat: 25.230489, lng: 55.326036 },
    { lat: 25.231071, lng: 55.326765 },
    { lat: 25.231479, lng: 55.32743 },
    { lat: 25.231479, lng: 55.327795 },
    { lat: 25.23111, lng: 55.328095 },
    { lat: 25.230217, lng: 55.328653 },
    { lat: 25.225889, lng: 55.332708 },
    { lat: 25.222805, lng: 55.33553 },
    { lat: 25.219606, lng: 55.338295 },
    { lat: 25.219074, lng: 55.338907 },
];

export const bus_road_3_stops = [
    {
        name: "Jumeirah Beach Residence 1",
        coords: [25.080091169751416, 55.13871578242958],
    },
    {
        name: "Jumeirah Beach Residence 2",
        coords: [25.073957672591433, 55.13306615265066],
    },
    {
        name: "Jumeirah Lakes Towers",
        coords: [25.071649901021395, 55.13817214855567],
    },
    {
        name: "Dubai Marina Mall",
        coords: [25.076347571191214, 55.14240193506357],
    },
    {
        name: "Dubai Marina",
        coords: [25.081354584089837, 55.14778370128216],
    },
    {
        name: "Marina Towers",
        coords: [25.086905878882742, 55.15011149342247],
    },
    {
        name: "Mina Seyahi",
        coords: [25.091212855120702, 55.148631481629785],
    },
    {
        name: "Media City",
        coords: [25.094717002735855, 55.151895592200376],
    },
    {
        name: "Palm Jumeirah",
        coords: [25.09849244181908, 55.15615761972086],
    },
    {
        name: "Knowledge Village",
        coords: [25.10442724941909, 55.161490328758106],
    },
    {
        name: "Al Sufouh",
        coords: [25.108268581656745, 55.16511613825428],
    },
];

export const bus_road_3 = [
    { lat: 25.108489, lng: 55.165148 },
    { lat: 25.101261, lng: 55.158196 },
    { lat: 25.096254, lng: 55.15352 },
    { lat: 25.090474, lng: 55.147593 },
    { lat: 25.08933, lng: 55.146736 },
    { lat: 25.088086, lng: 55.148023 },
    { lat: 25.086959, lng: 55.149482 },
    { lat: 25.086104, lng: 55.15004 },
    { lat: 25.085171, lng: 55.150255 },
    { lat: 25.084238, lng: 55.150169 },
    { lat: 25.083344, lng: 55.149611 },
    { lat: 25.081899, lng: 55.148006 },
    { lat: 25.080539, lng: 55.146247 },
    { lat: 25.078532, lng: 55.143941 },
    { lat: 25.077889, lng: 55.143212 },
    { lat: 25.080546, lng: 55.140338 },
    { lat: 25.079143, lng: 55.137213 },
    { lat: 25.077238, lng: 55.136312 },
    { lat: 25.076344, lng: 55.135883 },
    { lat: 25.075411, lng: 55.13305 },
    { lat: 25.072963, lng: 55.131891 },
    { lat: 25.072107, lng: 55.131419 },
    { lat: 25.069231, lng: 55.13541 },
    { lat: 25.068842, lng: 55.135754 },
    { lat: 25.077889, lng: 55.143212 },
    // { lat: 25.061285, lng: 55.129177 },
    // { lat: 25.054191, lng: 55.122982 },
    // { lat: 25.049881, lng: 55.119383 },
    // { lat: 25.036075, lng: 55.107158 },
    // { lat: 25.031917, lng: 55.103867 },
    // { lat: 25.026706, lng: 55.100606 },
    // { lat: 25.019240, lng: 55.097945 },
    // { lat: 25.012551, lng: 55.096743 },
    // { lat: 25.001025, lng: 55.094900 },
    // { lat: 24.977649633464985, lng: 55.09102075352159 },
];

export const canal_front_residences = [
    { lat: 25.198069, lng: 55.247255 },
    { lat: 25.1957, lng: 55.245452 },
    { lat: 25.192439, lng: 55.243221 },
    { lat: 25.189138, lng: 55.240818 },
    { lat: 25.186963, lng: 55.23953 },
    { lat: 25.180477, lng: 55.249186 },
    { lat: 25.190303, lng: 55.25807 },
    { lat: 25.192011, lng: 55.257211 },
    { lat: 25.194031, lng: 55.254422 },
    { lat: 25.198108, lng: 55.247427 },
];
