export const dropdownsData = (
    typeDropdowns,
    setTypeDropdowns,

    directionSwitchChecked,
    setDirectionSwitchChecked,
    radiusSwitchChecked,
    setRadiusSwitchChecked,
    mapTypeChecked,
    setMapTypeChecked
) => {
    return [
        {
            type: "checkbox",
            title: "PROJECT TYPE",
            id: "project_type",
            elements: [
                {
                    text: "Residential developments",
                    id: "residential_developments",
                },
                {
                    text: "Commercial developments",
                    id: "commercial_developments",
                },
            ],
            stateObject: {
                state: typeDropdowns,
                setState: setTypeDropdowns,
            },
        },
        {
            type: "checkbox",
            title: "Infrastructure",
            id: "infrastructure",
            elements: [
                {
                    text: "Landmarks",
                    id: "landmarks",
                },
                {
                    text: "Retail or Shopping",
                    id: "retail_or_shopping",
                },
                {
                    text: "Education",
                    id: "education",
                },
                {
                    text: "Health",
                    id: "health",
                },
                // {
                //     text: "Transport (Metro)",
                //     id: "transport",
                // },
                // {
                //     text: "Roads", //Need to configure Vasyl Narbut
                //     id: "roads",
                // },
            ],
            stateObject: {
                state: typeDropdowns,
                setState: setTypeDropdowns,
            },
        },
        {
            type: "checkbox",
            title: "Show",
            id: "show",
            elements: [
                {
                    text: "Show Distances",
                    id: "show_distances",
                    stateObject: {
                        state: directionSwitchChecked,
                        setState: setDirectionSwitchChecked,
                        indicator: "directions",
                    },
                },
                {
                    text: "Show Circles",
                    id: "show_circles",
                    stateObject: {
                        state: radiusSwitchChecked,
                        setState: setRadiusSwitchChecked,
                        indicator: "radius",
                    },
                },
                // {
                //     text: 'Satellite View',
                //     id: 'satellite_view',
                //     stateObject: {
                //         state: mapTypeChecked,
                //         setState: setMapTypeChecked
                //     }
                // }
            ],
        },
    ];
};
